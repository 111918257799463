import { Phone } from './phone.model';


export class Profissional {
  company: string;
  cnpj: string;
  email: string;
  country: string;
  phone: Phone;
  cep: string;
  companySize: string;
  constructor() {
    this.company = "";
    this.cnpj = "";
    this.email = "";
    this.country = "";
    this.phone = new Phone();
    this.cep = "";
    this.companySize = "";
  }
}