import { Estilo } from './styles.model';

export const LISTA_ESTILOS: Estilo[] = [
  {
    externo: {
      corPrincipal: '#1176ae',
      borderRadius: '8px',
      borderStyle: 'solid',
      borderColor: '#e5e5e5',
      borderWidth: '1px',
    },
    interno: {
      background: '#ffffff',
      borderRadius: '2px',
      borderStyle: 'none',
      borderColor: '#e5e5e5',
      borderWidth: '0',
    },
  },
  {
    externo: {
      corPrincipal: '#ccb27a',
      borderRadius: '8px',
      borderStyle: 'solid',
      borderColor: '#e5e5e5',
      borderWidth: '1px',
    },
    interno: {
      background: '#a18953',
      borderRadius: '16px',
      borderStyle: 'none',
      borderColor: '#e5e5e5',
      borderWidth: '0',
    },
  },
  {
    externo: {
      corPrincipal: '#da5743',
      borderRadius: '8px',
      borderStyle: 'solid',
      borderColor: '#e5e5e5',
      borderWidth: '1px',
    },
    interno: {
      background: '#733b32',
      borderRadius: '0',
      borderStyle: 'none',
      borderColor: '#e5e5e5',
      borderWidth: '0',
    },
  },
  {
    externo: {
      corPrincipal: '#1176ae',
      corSecundaria: '#df17ff',
      direcaoDegrade: '194.04',
      borderRadius: '8px',
      borderStyle: 'solid',
      borderColor: '#e5e5e5',
      borderWidth: '1px',
    },
    interno: {
      background: 'none',
      borderRadius: '2px',
      borderStyle: 'solid',
      borderColor: '#fff',
      borderWidth: '1px',
    },
  },
  {
    externo: {
      corPrincipal: '#353535',
      corSecundaria: '#a5a5a5',
      direcaoDegrade: '192.53',
      borderRadius: '8px',
      borderStyle: 'solid',
      borderColor: '#e5e5e5',
      borderWidth: '1px',
    },
    interno: {
      background: '#dbe938',
      borderRadius: '16px',
      borderStyle: 'none',
      borderColor: '#fff',
      borderWidth: '0',
    },
  },
  {
    externo: {
      corPrincipal: '#3B8562',
      corSecundaria: '#30c17c',
      direcaoDegrade: '193.85',
      borderRadius: '8px',
      borderStyle: 'solid',
      borderColor: '#e5e5e5',
      borderWidth: '1px',
    },
    interno: {
      background: '#353535',
      borderRadius: '0',
      borderStyle: 'none',
      borderColor: '#fff',
      borderWidth: '0',
    },
  },
];
