import { Input } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { NgbAlert, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime, filter, map } from 'rxjs';
import { IResponse } from 'src/app/model/response.model';
import { ILinkLicenseRequest, LinkLicenseRequest } from 'src/app/model/link-license-request.model';
import { LicencasService } from 'src/app/licencas/licencas.service';
import { ILicense } from 'src/app/model/license.model';

@Component({
  selector: 'app-vincula-licenca-business-modal',
  templateUrl: './vincula-licenca-business-modal.component.html',
  styleUrls: ['./vincula-licenca-business-modal.component.css']
})
export class VinculaLicencaBusinessModalComponent {

  numberOfLicenses: number = 0;
  emailUsuario: string | null = null;
  license: string | null = null;
  @Input() licenses!: ILicense[];

  private _alert = new Subject<string>();
  alertMessage: string = '';
  alertType: string = '';
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  constructor(
    private activeModal: NgbActiveModal,
    private licencasService: LicencasService
  ) { }

  ngOnInit(): void {
    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  onCloseClick() {
    this.activeModal.dismiss();
  }

  vincular() {
    if (this.emailUsuario == '' || this.emailUsuario == null || this.emailUsuario == undefined) {
      this.alertType = 'danger';
      this._alert.next('Informe um e-mail válido.');
      return;
    }

    if (this.license == '' || this.license == null || this.license == undefined) {
      this.alertType = 'danger';
      this._alert.next('Selecione uma licença.');
      return;
    }

    let linkLicenseRequest: ILinkLicenseRequest = new LinkLicenseRequest(this.license, this.emailUsuario);

    this.licencasService.linkLicense(linkLicenseRequest).
      pipe(
        filter((mayBeOk: HttpResponse<IResponse>) => mayBeOk.ok),
        map((response: HttpResponse<IResponse>) => response.body))
      .subscribe({
        next: (res) => this.setResponse(res),
        error: (res) => this.onError(res)
      });
  }

  setResponse(res: IResponse | null): void {
    if (res != null && res.status == "SUCCESS")
      this.activeModal.close(true);
  }

  onError(res: any) {
    if (res.error.status == "ERROR") {
      this.alertType = 'danger';
      this._alert.next(res.error.message);
    }
  }
}
