<app-sluper-background></app-sluper-background>
<app-header-inicial [layoutColorido]="false"></app-header-inicial>
<div class="container">
    <div class="shadow-box">
        <div class="box-delimitadora text-center">
            <span class="text-normal-32-700 text-white" translate>Para prosseguir com a criação da conta acesse seu email, clique no link de ativação e informe a chave de acesso!</span>
        </div>
        <span class="text-normal-16-700 text-white">Problemas? <span class="text-roxo-ajuda"
                (click)="pedirAjuda()">Pedir Ajuda</span></span>

    </div>
</div>
