<div class="modal-body d-flex flex-column align-items-center justify-content-center gap-5">
    <img src="../../../../../assets/gif/concluido.gif" />
    <div class="d-flex flex-column gap-3">
        <span class="text-normal-32-700 titulo-modal">Parabéns! Seu cadastro foi concluído.</span>
        <span class="text-normal-20-500">
            Você está a um passo de criar conexões emocionantes! <br>
            Agora, configure sua Bio Sluper, para começar a se conectar.
        </span>
    </div>
    <button type="button" class="btn button-roxo-form" (click)="close()">
        <span class="text-normal-16-700 text-white">
            Prosseguir
        </span>
    </button>
</div>