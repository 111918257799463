
<div class="background-sluper" style="background: var(--background-1, linear-gradient(242deg, #01B3D5 -1.95%, #2F69CD 47.39%, #793BE7 90.77%));">
  <div class="div-logo-sluper">
      <img class="logo-sluper" [src]="urlBackgroundImageLogin" alt="Simbolo Sluper" />
  </div>
</div>

<div class="header-inicio">
  <div class="logo">
      <img class="logo-sluper"
          [src]="urlImagemLogin" alt="logo Sluper" />

  </div>
  <div class="buttons">
      <span class="button-header" [ngClass]="[classColorido, borderRight]">Sua Bio
          gratuita</span>
      <span class="button-header" [ngClass]="[classColorido, borderRight]" >Sluper
          Card</span>
      <span class="button-header" [ngClass]="classColorido">Login</span>
  </div>
</div>

<div class="container">
    <div class="row ">
        <div class="col-12 col-md-5 col-sm-12 d-flex flex-column justify-content-center mb-4 mb-md-0 ">
            <div class="shadow-box">
                <span class="text-normal-32-700 text-white" translate>Login</span>
                <div class="form-div mt-4">
                    <label for="user" class="label-text-inicio" translate>Email</label>
                    <input type="email" name="user" id="user" class="form-input" />
                </div>
                <div class="form-div mt-4" *ngIf="false">
                    <label for="verification" class="label-text-inicio" translate>Código de Verificação</label>
                    <span class="text-reenviar-codigo text-white mt-2 d-inline-block"
                        translate>Reenviar Código</span>
                </div>
                <button type="button" class="btn button-form w-100 mt-4">
                    <span class="text-normal-16-700 text-white">Isso é apenas um preview!</span>
                </button>
            </div>
        </div>

       <!-- <div class="col-12 col-md-7 col-sm-12 d-flex flex-column justify-content-center align-items-end">
            <div class="image-box position-relative">
                <div class="image-content-box">
                    <div class="text-image-box">
                        <span class="text-normal-40-700 text-white">Ainda não tem Sluper?</span>
                        <span class="text-line-height text-white">Não deixe de se conectar com o que importa.</span>
                    </div>
                    <button type="button" class="btn button-roxo-form w-100 mt-4">
                        <span class="text-normal-16-700 text-white" translate>Cadastre-se</span>
                    </button>
                </div>
                <img class="image-cadastro" src="../../../../assets/images/homem-cadastro.png" alt="Anuncio Sluper" />
            </div>
        </div> -->
    </div>
</div>
