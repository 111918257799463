import { DocumentUserCSS, IDocumentUserCSS } from "./document-user-css.model";
import { DocumentUserProfile } from "./document-user-profile.model";
import { DocumentUser } from "./document-user.model";


export interface IUserProfileWithCSS {
  documentAdmin: object;
  documentUserProfile: DocumentUserProfile;
  documentUserCSS: IDocumentUserCSS;
  documentUser: DocumentUser;
}

export class UserProfileWithCSS implements IUserProfileWithCSS {
  documentAdmin: object;
  documentUserProfile: DocumentUserProfile;
  documentUserCSS: DocumentUserCSS;
  documentUser: DocumentUser;
  constructor() {
    this.documentAdmin = new Object();
    this.documentUserProfile = new DocumentUserProfile();
    this.documentUserCSS = new DocumentUserCSS();
    this.documentUser = new DocumentUser();
  }
}
