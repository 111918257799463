<div class="container">
  <div class="information-container">
    <div class="title-container">
      <h1 class="title">Ops...</h1>
    </div>

    <div class="message-container">
      <p class="message-paragraph">Para acessar esta página, é necessário ser um afiliado. Entre em contato com nossa equipe para mais informações.</p>

      <a class="link" href="mailto:comercial@sluper.digital">comercial@sluper.digital</a>
    </div>
  </div>

  <img src="/assets/img/undraw_safe_re_kiil.svg" alt="Shopping Image" class="ilustration">
</div>
