import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authenticated = false;

  constructor(private router: Router) {
    this.authenticated = !!localStorage.getItem('authToken');
  }

  login(token:string): void {
    this.authenticated = true;
    localStorage.setItem('authToken', token);
  }

  logout(): void {
    this.authenticated = false;
    localStorage.removeItem('authToken');
    this.router.navigate(['/login']);
  }

  isAuthenticated(): boolean {
    return this.authenticated;
  }
}
