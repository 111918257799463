export interface IHeader {
  text: string;
  visible: boolean;
}

export class Header implements IHeader {
  text: string;
  visible: boolean;
  constructor(

  ) {
    this.text = "";
    this.visible = true;
  }
}
