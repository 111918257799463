<div class="body-div">
  <header class="title-container">
    <h1 class="title" translate>dashboard.title</h1>
  </header>

  <div class="content-container">
    <section class="dashboard-items-container"
      [style]="!userProfilePlan.profilePlanLimits.dashViews ? 'filter: blur(6px); -webkit-filter: blur(8px); z-index: 1;' : ''">
      <div class="dashboard-content">
      <app-dashboard-card-new class="card-container" [dashboardCardData]="{
          title: 'Visitas ao perfil',
          icon: 'bi-person-check',
          color: '#81bd86',
          value: !userProfilePlan.profilePlanLimits.dashViews
            ? '1234'
            : dashboardData?.profileAccessCount
              ? dashboardData?.profileAccessCount?.toString()
              : '0',
          percentage: 9.2,
          difference: 10
        }">
      </app-dashboard-card-new>

      <app-dashboard-card-new class="card-container" [dashboardCardData]="{
        title: 'Links acessados',
        icon: 'bi-hdd-rack',
        color: '#81bd86',
        value: !userProfilePlan.profilePlanLimits.dashViews
          ? '1234'
          : dashboardData?.acessedLinksCount
            ? dashboardData?.acessedLinksCount?.toString()
            : '0',
        percentage: 22.2,
        difference: 1302
      }">
    </app-dashboard-card-new>

    <app-dashboard-card-new class="card-container" [dashboardCardData]="{
      title: 'Redes acessadas',
      icon: 'bi-bullseye',
      color: '#81bd86',
      value: !userProfilePlan.profilePlanLimits.dashViews
        ? '1234'
        : dashboardData?.socialNetworkAcessedCount
          ? dashboardData?.socialNetworkAcessedCount?.toString()
          : '0',
      percentage: -2.2,
      difference: -10
      }">
    </app-dashboard-card-new>

    <app-dashboard-card-new class="card-container" [dashboardCardData]="{
      title: 'Conexões',
      icon: 'bi-people',
      color: '#81bd86',
      value: !userProfilePlan.profilePlanLimits.dashViews
        ? '1234'
        : dashboardUser?.connections
          ? dashboardUser?.connections?.toString()
          : '0',
      percentage: -2.2,
      difference: -10
      }">
    </app-dashboard-card-new>

    <app-dashboard-card-new class="card-container" [dashboardCardData]="{
      title: 'Indicações Realizadas',
      icon: 'bi-person-exclamation',
      color: '#81bd86',
      value: !userProfilePlan.profilePlanLimits.dashViews
        ? '1234'
        : dashboardUser?.indications
          ? dashboardUser?.indications?.toString()
          : '0',
      percentage: -2.2,
      difference: -10
      }">
    </app-dashboard-card-new>

    <app-dashboard-card-new class="card-container" [dashboardCardData]="{
      title: 'Eventos Participados',
      icon: 'bi-calendar-check',
      color: '#81bd86',
      value: !userProfilePlan.profilePlanLimits.dashViews
        ? '1234'
        : dashboardUser?.eventsParticipated
          ? dashboardUser?.eventsParticipated?.toString()
          : '0',
      percentage: -2.2,
      difference: -10
      }">
    </app-dashboard-card-new>

    <app-dashboard-card-new class="card-container" [dashboardCardData]="{
      title: 'Eventos Organizados',
      icon: 'bi-calendar-range',
      color: '#81bd86',
      value: !userProfilePlan.profilePlanLimits.dashViews
        ? '1234'
        : dashboardUser?.listEventsOrganized
          ? dashboardUser?.listEventsOrganized?.length?.toString()
          : '0',
      percentage: 50,
      difference: 1
      }">
    </app-dashboard-card-new>

    <app-dashboard-card-new class="card-container" [dashboardCardData]="{
      title: 'Comunidades Participando',
      icon: 'bi-send-check',
      color: '#81bd86',
      value: !userProfilePlan.profilePlanLimits.dashViews
        ? '1234'
        : dashboardUser?.communityParticipating
          ? dashboardUser?.communityParticipating?.toString()
          : '0',
      percentage: 8.2,
      difference: 18
      }">
    </app-dashboard-card-new>

    <app-dashboard-card-new class="card-container" [dashboardCardData]="{
      title: 'Comunidades Organizadas',
      icon: 'bi-send-exclamation',
      color: '#81bd86',
      value: !userProfilePlan.profilePlanLimits.dashViews
        ? '1234'
        : dashboardUser?.listCommunityOrganized
          ? dashboardUser?.listCommunityOrganized?.length?.toString()
          : '0',
      percentage: 5.2,
      difference: 20
      }">
    </app-dashboard-card-new>
        <!--<app-dashboard-card class="card-container" [dashboardCardData]="{
            title: 'Visitas ao perfil',
            icon: 'bi-person-check',
            color: '#81bd86',
            value: !userProfilePlan.profilePlanLimits.dashViews
              ? '1234'
              : dashboardData?.profileAccessCount
                ? dashboardData?.profileAccessCount?.toString()
                : '0'
          }">
        </app-dashboard-card>
        <app-dashboard-card class="card-container" [canOpenList]="userProfilePlan.profilePlanLimits.dashAccessedLinks"
          [dashboardCardData]="{
            icon: 'bi-hdd-rack',
            title: 'Links acessados',
            color: '#e7c465',
            value: !userProfilePlan.profilePlanLimits.dashViews
              ? '2345'
              : dashboardData?.acessedLinksCount
                ? dashboardData?.acessedLinksCount?.toString()
                : '0',
            links: dashboardData?.topAccessedLinks
          }" variant="list-links">
        </app-dashboard-card>
        <app-dashboard-card class="card-container" [canOpenList]="
            userProfilePlan.profilePlanLimits.dashAccessedLinks !== undefined && userProfilePlan.profilePlanLimits.dashAccessedLinks
          " [dashboardCardData]="{
            icon: 'bi-bullseye',
            title: 'Redes acessadas',
            color: '#8c65e7',
            value: !userProfilePlan.profilePlanLimits.dashViews
              ? '34567'
              : dashboardData?.socialNetworkAcessedCount
                ? dashboardData?.socialNetworkAcessedCount?.toString()
                : '0',
            links: dashboardData?.topAccessedSocialNetworks
          }" [canOpenList]="
            userProfilePlan.profilePlanLimits.dashAccessedLinks === undefined || userProfilePlan.profilePlanLimits.dashAccessedLinks
          " variant="list-social-networks">
        </app-dashboard-card>

        <app-dashboard-card class="card-container" [dashboardCardData]="{
            title: 'Conexões',
            icon: 'bi-people',
            color: '#81bd86',
            value: !userProfilePlan.profilePlanLimits.dashViews
              ? '1234'
              : dashboardUser?.connections
                ? dashboardUser?.connections?.toString()
                : '0'
          }">
        </app-dashboard-card>

        <app-dashboard-card class="card-container" [dashboardCardData]="{
            title: 'Indicações Realizadas',
            icon: 'bi-person-exclamation',
            color: '#81bd86',
            value: !userProfilePlan.profilePlanLimits.dashViews
              ? '1234'
              : dashboardUser?.indications
                ? dashboardUser?.indications?.toString()
                : '0'
          }">
        </app-dashboard-card>

        <app-dashboard-card class="card-container" [dashboardCardData]="{
            title: 'Eventos Participados',
            icon: 'bi-calendar-check',
            color: '#81bd86',
            value: !userProfilePlan.profilePlanLimits.dashViews
              ? '1234'
              : dashboardUser?.eventsParticipated
                ? dashboardUser?.eventsParticipated?.toString()
                : '0'
          }">
        </app-dashboard-card>

        <app-dashboard-card class="card-container" [canOpenList]="userProfilePlan.profilePlanLimits.dashAccessedLinks"
          [dashboardCardData]="{
            icon: 'bi-calendar-range',
            title: 'Eventos Organizados',
            color: '#e7c465',
            value: !userProfilePlan.profilePlanLimits.dashViews
              ? '2345'
              : dashboardUser?.listEventsOrganized
                ? dashboardUser?.listEventsOrganized?.length?.toString()
                : '0',
            names: transformEventsListToLinks()
          }" variant="list-name">
        </app-dashboard-card>

        <app-dashboard-card class="card-container" [dashboardCardData]="{
            title: 'Comunidades Participando',
            icon: 'bi-send-check',
            color: '#81bd86',
            value: !userProfilePlan.profilePlanLimits.dashViews
              ? '1234'
              : dashboardUser?.communityParticipating
                ? dashboardUser?.communityParticipating?.toString()
                : '0'
          }">
        </app-dashboard-card>

        <app-dashboard-card class="card-container" [canOpenList]="userProfilePlan.profilePlanLimits.dashAccessedLinks"
          [dashboardCardData]="{
            icon: 'bi-send-exclamation',
            title: 'Comunidades Organizadas',
            color: '#e7c465',
            value: !userProfilePlan.profilePlanLimits.dashViews
              ? '2345'
              : dashboardUser?.listCommunityOrganized
                ? dashboardUser?.listCommunityOrganized?.length?.toString()
                : '0',
            names: transformCommunityListToLinks()
          }" variant="list-name">
        </app-dashboard-card>-->
      </div>

      <div class="graphs-container">
        <div class="access-graphs-box">
          <span class="access-graph-title">Métricas de acesso por semana</span>

          <a target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
            class="no-dash-graph-access-button"
            *ngIf="userProfilePlan.profilePlanLimits.dashAccessGraph !== undefined && !userProfilePlan.profilePlanLimits.dashAccessGraph">
            <i class="bi bi-lock-fill"></i>

            <div class="no-dash-graph-messages">
              Para visualizar o gráfico de acesso,
              <a target="_blank"
                href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal">faça upgrade do
                seu plano</a>
            </div>
          </a>

          <div id="chart" class="chart-container" [ngClass]="!userProfilePlan.profilePlanLimits.dashAccessGraph ? 'hidden' : ''">
            <apx-chart style="width: 100%" [series]="chartOptions.series" [chart]="chartOptions.chart"
              [xaxis]="chartOptions.xaxis" [stroke]="chartOptions.stroke" [tooltip]="chartOptions.tooltip"
              [dataLabels]="chartOptions.dataLabels"></apx-chart>
          </div>
        </div>
      </div>

      <div *ngIf="dashboardData?.updateDate" class="last-update-container">
        <span class="last-update-label">última atualização: {{ dashboardData?.updateDate | date: 'dd/MM/yyyy - HH:mm'
          }}</span>
      </div>

      <div class="fictitious-data-container" *ngIf="!userProfilePlan.profilePlanLimits.dashViews">
        <span class="fictitious-data-label">DADOS FICTÍCIOS</span>
      </div>
    </section>

    <div class="no-access-container" *ngIf="!userProfilePlan.profilePlanLimits.dashViews">
      <div class="no-access-content">
        <span class="no-access-text" translate>Seu plano atual não permite acesso ao dashboard.</span>
        <img src="/assets/img/undraw_access_denied_re_awnf.svg" style="width: 200px" alt="No access"
          class="no-access-img" />

        <span class="cta-label">Para acessar,
          <a target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal">faça
            upgrade do seu plano</a>.</span>
      </div>
    </div>
  </div>
</div>
