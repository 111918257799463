export class NetworkingControl {
  used: number;
  max: number;
  constructor(

  ) {
    this.used = 0;
    this.max = 15;
  }
}
