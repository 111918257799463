<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<div class="modal-header">
  <span class="title">Sluper Coins</span>
  <button type="button" class="btn-close" aria-label="Close" (click)="onCloseClick()"></button>
</div>

<div class="modal-body content">
  <div class="no-promos-container" *ngIf="promosByUser.length < 1">
    <span class="no-promos-message">Esse usuário ainda nāo participa de promoçōes</span>
  </div>

  <div class="promo-container" *ngFor="let promo of promosByUser">
    <div class="promo-name-container">
      <span class="promo-name">{{promo.promoName}}</span>

      <span class="promo-description">{{promo.promoDescription}}</span>
    </div>

    <div class="coins-counter-container">
      <button class="update-coins-button" (click)="changePromoCreditsAmount(promo.promoId, 'DECREASE')"
        [disabled]="promo.quantityCredit < 1">
        <i class="bi bi-dash"></i>
      </button>
      <span class="coins-counter">
        <i class="bi bi-coin promo-coin"></i>
        {{promo.quantityCredit}}
      </span>
      <button class="update-coins-button" (click)="changePromoCreditsAmount(promo.promoId, 'INCREASE')">
        <i class="bi bi-plus"></i>
      </button>
    </div>
  </div>
</div>

<div class="footer">
</div>