<div class="body-div">
  <app-join-reseller-program *ngIf="!userProfile?.documentUser?.isReseller"></app-join-reseller-program>

  <div class="title-container" *ngIf="userProfile?.documentUser?.isReseller">
    <h1 class="title" translate>salesDashboard.title</h1>

    <div class="filter-inputs-container">
      <input type="month" class="filter-month-input" (change)="fetchSalesDashboardData($event)" [value]="monthFilter" />
    </div>
  </div>

  <div class="dashboard-content" *ngIf="userProfile?.documentUser?.isReseller">
    <div class="cards-container">
      <app-dashboard-card
        class="card-container"
        [dashboardCardData]="{
          title: 'Quantidade de vendas',
          icon: 'bi-receipt',
          color: '#8187bd',
          value: dashboardData ? dashboardData.salesCount.toString() : '0'
        }">
      </app-dashboard-card>

      <app-dashboard-card
        class="card-container"
        [dashboardCardData]="{
          title: 'Valor total de vendas',
          icon: 'bi-cash-coin',
          color: '#81bd86',
          value: dashboardData ? dashboardData.formattedSalesTotalValue : '0'
        }">
      </app-dashboard-card>

      <app-dashboard-card
        class="card-container"
        [dashboardCardData]="{
          title: 'Valor total de comissão',
          icon: 'bi-cash-stack',
          color: '#81bd86',
          value: dashboardData ? dashboardData.formattedTotalSalesCommission : '0'
        }">
      </app-dashboard-card>
    </div>

    <div class="sales-grouped-by-day" *ngIf="dashboardData">
      <h2 class="sales-by-day-title">Vendas agrupadas por dia</h2>

      <div class="sales-by-day-header">
        <div class="day-container">
          <span>Dia</span>
        </div>

        <div class="day-sales-quantity-container-title">
          <span>Quantidade</span>
        </div>

        <div class="day-total-value-container-title">
          <span>Valor total</span>
        </div>

        <div class="day-total-value-container-title" style="border: none">
          <span>Comissão</span>
        </div>
      </div>

      <div class="day-items-container">
        <div class="day-item-row" *ngFor="let day of dashboardData.revenuesByDay">
          <div class="day-container">
            <span class="day-value">{{ day.day }}</span>
          </div>

          <div class="day-sales-quantity-container">
            <span class="day-sales-quantity-value">{{ day.salesCount }}</span>
          </div>

          <div class="day-total-value-container">
            <span class="day-total-value-value">{{ day.formattedTotalValue }}</span>
          </div>

          <div class="day-total-value-container" style="border: none">
            <span class="day-total-value-value">{{ day.formattedComissionValue }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
