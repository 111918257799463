<div class="alert-div">
    <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
        {{ alertMessage }}
    </ngb-alert>
</div>
<div class="modal-header">
    <span class="title">Vincular Licença</span>
    <button type="button" class="btn-close" aria-label="Close" (click)="onCloseClick()"></button>
</div>
<div class="modal-body content">
    <div class="input-div">
        <label for="owner" class="labels-text">Licença</label>
        <div class="input-group inputs">
            <div class="input-group-prepend">
                <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-qr-code"></i></span>
            </div>
            <input type="text" class="form-control input-text" id="owner" name="owner" [(ngModel)]="license" readonly>
        </div>
    </div>
    <div class="input-div">
        <label for="owner" class="labels-text">E-mail de Usuário</label>
        <div class="input-group inputs">
            <div class="input-group-prepend">
                <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-envelope"></i></span>
            </div>
            <input type="text" class="form-control input-text" id="owner" name="owner" [(ngModel)]="emailUsuario">
        </div>
    </div>
</div>
<div class="footer">
    <button type="button" class="gerar-button" (click)="vincular()">Vincular</button>
    <button type="button" class="cancel-button" (click)="onCloseClick()">Cancelar</button>
</div>