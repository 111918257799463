<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<div class="modal-body content">
  <div class="corp-container" [style.background]="!userAsCorp ? '#f3f3f3' : 'transparent'">
    <div class="user-corp-container">
      <label class="user-corp-label" for="isUserCorpToggle">Usuário corporativo</label>

      <div class="form-check form-switch">
        <input type="checkbox" role="switch" id="isUserCorpToggle" class="form-check-input" name="active"
          [checked]="userAsCorp" (change)="handleToggleCorp()">
      </div>
    </div>

    <div class="corp-config-container">
      <div class="form-check form-switch sluper-coin-toggle-container">
        <input type="checkbox" role="switch" id="sluperCoinAccess" class="form-check-input" name="active"
          [checked]="isSluperCoinEnabled" (change)="handleToggleSluperCoin()" [disabled]="!userAsCorp">

        <label class="user-corp-label labels-text" for="sluperCoinAccess">Sluper Coin</label>
      </div>
    </div>
  </div>
</div>

<div class="footer">
  <div class="buttons-container">
    <button type="button" class="cancel-button" (click)="onCloseClick()">Cancelar</button>
    <button type="button" class="save-button" (click)="handleSave()">Salvar</button>
  </div>
</div>