<div class="modal-container">
  <header class="modal-header">
    <h4 class="modal-title" *ngIf="!updateMode" translate>events.createEvent.title</h4>
    <h4 class="modal-title" *ngIf="updateMode" translate>events.updateEvent.title</h4>
    <button type="button" class="close" aria-label="Fechar" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </header>

  <section class="modal-body">
    <section class="event-fields-section">
      <div class="file-input-container">
        <label for="image-upload" class="input-label"> Imagem do evento </label>
        <image-cropper *ngIf="cropMode" [imageChangedEvent]="changedImageEvent" [maintainAspectRatio]="true"
          [aspectRatio]="16 / 9" format="jpeg" (imageCropped)="handleImageCropped($event)" style="max-height: 400px"
          [cropperMaxWidth]="1200" [cropperMinWidth]="1200" [cropperMaxHeight]="720"
          [cropperMinHeight]="720"></image-cropper>

        <label class="image-upload-label" for="image-upload" *ngIf="!cropMode"
          [style]="!formErrors.image.valid && formErrors.image.message ? 'border-color: red' : ''">
          <input id="image-upload" class="image-upload" accept="image/jpeg, image/png, image/jpg" type="file"
            (change)="handleFileChange($event)" />

          <i class="bi bi-card-image"></i>

          <span>Clique aqui para selecionar e enviar a sua foto</span>
          <span *ngIf="updateMode">*ou deixe em branco caso não queira alterar</span>
        </label>
        <div style="display: flex; flex-direction: row; justify-content: center; margin-top: 1%;">
          <button class="close" (click)="removeSelectedImage()" *ngIf="cropMode"><i
              class="bi bi-x-circle-fill"></i></button>
        </div>

        <span class="error-message" *ngIf="!formErrors.image.valid && formErrors.image.message">
          {{ formErrors.image.message }}
        </span>
      </div>

      <div class="event-fields-container">
        <div class="input-container">
          <label for="event-name" class="input-label"> Nome do evento </label>

          <div class="input-group event-input-container">
            <div [style]="!formErrors.name.valid && formErrors.name.message ? 'border-color: red' : ''"
              class="input-group-prepend event-input-prepend">
              <i class="bi bi-type-h1"></i>
            </div>

            <input [style]="!formErrors.name.valid && formErrors.name.message ? 'border-color: red' : ''"
              [(ngModel)]="modalData.name" id="event-name" placeholder="Nome do evento" type="text"
              class="form-control event-input" />
          </div>

          <span class="error-message" *ngIf="!formErrors.name.valid && formErrors.name.message">
            {{ formErrors.name.message }}
          </span>
        </div>

        <div class="input-container">
          <label for="event-site" class="input-label"> Site do evento </label>

          <div class="input-group event-input-container">
            <div [style]="!formErrors.site.valid && formErrors.site.message ? 'border-color: red' : ''"
              class="input-group-prepend event-input-prepend">
              <i class="bi bi-link"></i>
            </div>

            <input [style]="!formErrors.site.valid && formErrors.site.message ? 'border-color: red' : ''"
              [(ngModel)]="modalData.site" id="event-site" placeholder="Site do evento" type="text"
              class="form-control event-input" />
          </div>
        </div>

        <div class="input-container">
          <label for="event-type" class="input-label"> Tipo do evento </label>

          <div class="input-group event-input-container">
            <div class="input-group-prepend event-input-prepend">
              <i class="bi bi-ethernet"></i>
            </div>

            <select [(ngModel)]="modalData.type" id="event-type" class="form-select event-input">
              <option value="PRESENCIAL">Presencial</option>
              <option value="REMOTO">Online</option>
            </select>
          </div>

          <span class="error-message" *ngIf="!formErrors.type.valid && formErrors.type.message">
            {{ formErrors.type.message }}
          </span>
        </div>

        <div class="input-container">
          <label for="maximum-participants" class="input-label"> Máximo de participantes </label>

          <div class="input-group event-input-container">
            <div [style]="!formErrors.maxAttendees.valid && formErrors.maxAttendees.message ? 'border-color: red' : ''"
              class="input-group-prepend event-input-prepend">
              <i class="bi bi-people-fill"></i>
            </div>

            <input
              [style]="!formErrors.maxAttendees.valid && formErrors.maxAttendees.message ? 'border-color: red' : ''"
              id="maximum-participants" placeholder="Quantidade máxima de participantes" type="number"
              [(ngModel)]="modalData.maxAttendees" class="form-control event-input" (ngModelChange)="this.checkMaxAttendees()" />
          </div>

          <span class="error-message" *ngIf="!formErrors.maxAttendees.valid && formErrors.maxAttendees.message">
            {{ formErrors.maxAttendees.message }}
          </span>
        </div>

        <div class="input-container">
          <label for="event-end-time" class="input-label"> Data e Horário de inicio do evento </label>

          <div class="input-group event-input-container">
            <div [style]="!formErrors.startDate.valid && formErrors.startDate ? 'border-color: red' : ''"
              class="input-group-prepend event-input-prepend"
              [style]="!formErrors.startDate && formErrors.startDate ? 'border-color: red' : ''">
              <i class="bi bi-clock"></i>
            </div>

            <input type="datetime-local" [(ngModel)]="modalData.startDate" id="event-end-time"
              placeholder="Início do evento" [min]="minDate" class="form-control event-input" />
          </div>

          <span class="error-message" *ngIf="!formErrors.startDate.valid && formErrors.startDate.message">
            {{ formErrors.startDate.message }}
          </span>
        </div>

        <div class="input-container">
          <label for="event-end-time" class="input-label"> Data e Horário de fim do evento </label>

          <div class="input-group event-input-container">
            <div [style]="!formErrors.endDate.valid && formErrors.endDate ? 'border-color: red' : ''"
              class="input-group-prepend event-input-prepend"
              [style]="!formErrors.endDate && formErrors.endDate ? 'border-color: red' : ''">
              <i class="bi bi-clock"></i>
            </div>

            <input type="datetime-local" [(ngModel)]="modalData.endDate" id="event-end-time" placeholder="Fim do evento"
              [min]="minDate" class="form-control event-input" />
          </div>

          <span class="error-message" *ngIf="!formErrors.endTime.valid && formErrors.endTime.message">
            {{ formErrors.endTime.message }}
          </span>
        </div>

        <div class="input-container" *ngIf="modalData.type === 'PRESENCIAL'">
          <label for="event-address" class="input-label"> Endereço do evento </label>

          <div class="input-group event-input-container">
            <div class="input-group-prepend event-input-prepend"
              [style]="!formErrors.location.valid && formErrors.location.message ? 'border-color: red' : ''">
              <i class="bi bi-geo-fill"></i>
            </div>

            <input [style]="!formErrors.location.valid && formErrors.location.message ? 'border-color: red' : ''"
              class="form-control" [(ngModel)]="modalData.address" />
          </div>

          <span class="error-message" *ngIf="!formErrors.location.valid && formErrors.location.message">
            {{ formErrors.location.message }}
          </span>
        </div>

        <div class="input-container" *ngIf="modalData.type === 'PRESENCIAL'">
          <label for="event-address" class="input-label"> Cidade do evento </label>

          <div class="input-group event-input-container">
            <div class="input-group-prepend event-input-prepend"
              [style]="!formErrors.location.valid && formErrors.location.message ? 'border-color: red' : ''">
              <i class="bi bi-geo-fill"></i>
            </div>

            <input [style]="!formErrors.location.valid && formErrors.location.message ? 'border-color: red' : ''"
              class="form-control" [(ngModel)]="modalData.city" />
          </div>

          <span class="error-message" *ngIf="!formErrors.location.valid && formErrors.location.message">
            {{ formErrors.location.message }}
          </span>
        </div>

        <div class="input-container" *ngIf="modalData.type === 'PRESENCIAL'">
          <label for="event-address" class="input-label"> Estado do evento </label>

          <div class="input-group event-input-container">
            <div class="input-group-prepend event-input-prepend"
              [style]="!formErrors.location.valid && formErrors.location.message ? 'border-color: red' : ''">
              <i class="bi bi-geo-fill"></i>
            </div>

            <input [style]="!formErrors.location.valid && formErrors.location.message ? 'border-color: red' : ''"
              class="form-control" [(ngModel)]="modalData.state" />
          </div>

          <span class="error-message" *ngIf="!formErrors.location.valid && formErrors.location.message">
            {{ formErrors.location.message }}
          </span>
        </div>

        <div class="input-container" *ngIf="modalData.type === 'PRESENCIAL'">
          <label for="event-address" class="input-label"> País do evento </label>

          <div class="input-group event-input-container">
            <div class="input-group-prepend event-input-prepend"
              [style]="!formErrors.location.valid && formErrors.location.message ? 'border-color: red' : ''">
              <i class="bi bi-geo-fill"></i>
            </div>

            <select class="form-select event-input" name="paisTelefoneCorporativo" [(ngModel)]="modalData.country"
              [style]="!formErrors.location.valid && formErrors.location.message ? 'border-color: red' : ''">
              <option value=""></option>
              <option [ngValue]="pais.nomePais" *ngFor="let pais of paises">{{ pais.nomePais }}</option>
            </select>
          </div>

          <span class="error-message" *ngIf="!formErrors.location.valid && formErrors.location.message">
            {{ formErrors.location.message }}
          </span>
        </div>
      </div>

      <div class="toggles">
        <div class="toggle-container">
          <label class="input-label" for="public-event">Evento Público</label>

          <div class="form-check form-switch">
            <input [(ngModel)]="modalData.public" id="public-event" type="checkbox" role="switch"
              class="form-check-input" name="active" />
          </div>
        </div>

        <div class="toggle-container">
          <label class="input-label" for="public-event">Requer Aprovação</label>

          <div class="form-check form-switch">
            <input [(ngModel)]="modalData.requiresConfirmation" id="public-event" type="checkbox" role="switch"
              class="form-check-input" name="active" />
          </div>
        </div>

        <div class="toggle-container">
          <label class="input-label" for="participant-details-visibility">Exibir detalhes dos participantes somente após
            o
            check-in</label>

          <div class="form-check form-switch">
            <input [(ngModel)]="modalData.participantDetailsAfterCheckin" id="participant-details-visibility"
              type="checkbox" role="switch" class="form-check-input" name="active" />
          </div>
        </div>
      </div>

      <div class="description-textarea-container">
        <label for="event-description" class="input-label"> Descriçāo do evento </label>
        <st-editor style="width: 100%" [(ngModel)]="modalData.description" [config]="config"></st-editor>
        <span class="error-message" *ngIf="!formErrors.description.valid && formErrors.description.message">
          {{ formErrors.description.message }}
        </span>
      </div>

      <div class="description-textarea-container">
        <label for="event-description" class="input-label"> Sobre o evento </label>
        <st-editor style="width: 100%" [(ngModel)]="modalData.about" [config]="config"></st-editor>
        <span class="error-message" *ngIf="!formErrors.description.valid && formErrors.description.message">
          {{ formErrors.description.message }}
        </span>
      </div>

      <div style="display: flex; width: 100%"></div>
    </section>

    <section class="guests-section">
      <div class="add-guest-container">
        <div class="input-container">
          <label for="guest-email" class="input-label"> Convide pessoas ao seu evento </label>

          <div class="guest-input-container">
            <div class="input-group event-input-container">
              <div class="input-group-prepend event-input-prepend"
                [style]="!guestsFormErrors.guestEmail.valid && guestsFormErrors.guestEmail.message ? 'border-color: red; color: red;' : ''">
                <i class="bi bi-envelope"></i>
              </div>

              <input [(ngModel)]="guestEmailToInvite" id="guest-email" placeholder="E-mail do convidado " type="text"
                class="form-control event-input"
                [style]="!guestsFormErrors.guestEmail.valid && guestsFormErrors.guestEmail.message ? 'border-color: red' : ''" />
            </div>

            <button class="add-guest-button" (click)="handleAddGuest()">
              <i class="bi bi-plus"></i>
            </button>
          </div>

          <span class="error-message" *ngIf="!guestsFormErrors.guestEmail.valid && guestsFormErrors.guestEmail.message">
            {{ guestsFormErrors.guestEmail.message }}
          </span>
        </div>
      </div>

      <div class="guests-list">
        <div class="guest-box" *ngFor="let guest of modalData.guests; let i = index">
          {{ guest }}

          <button class="remove-guests-button" (click)="removeGuest(i)"><i class="bi bi-x"></i></button>
        </div>
      </div>

      <div class="add-guest-container">
        <div class="input-container">
          <label for="guest-email" class="input-label"> Convide suas comunidades ao seu evento </label>
            <ng-select class="select-communities" [items]="this.communities" [multiple]="true" [closeOnSelect]="false"
              [searchable]="false" bindLabel="event.name" name="communities" bindValue="event.id"
              [(ngModel)]="selectedCommunities" [hideSelected]="true" (ngModelChange)="onSelectionChange($event)"></ng-select>
            </div>
      </div>
      <p *ngIf="exceedsMaxAttendees">Os eventos selecionados ultrapassam a quantidade máxima de participantes do evento!</p>
    </section>
  </section>

  <footer class="modal-footer">
    <button type="button" class="btn btn-secondary" translate (click)="closeModal()">events.createEvent.cancel</button>

    <button type="button" class="btn btn-primary save-event-button" (click)="handleSaveEvent()" translate>
      <div class="button-load-spinner" *ngIf="loading"></div>

      events.createEvent.save
    </button>
  </footer>
</div>
