import { HttpResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { NgbAlert, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime, filter, map } from 'rxjs';
import { IPromosByUser, LicencasService } from 'src/app/licencas/licencas.service';
import { IResponse } from 'src/app/model/response.model';

@Component({
  selector: 'app-sluper-coin-modal',
  templateUrl: './sluper-coin-modal.component.html',
  styleUrls: ['./sluper-coin-modal.component.css']
})
export class SluperCoinModalComponent {
  @Input() userId!: string;

  private _alert = new Subject<string>();
  alertMessage: string = '';
  alertType: string = '';
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  promosByUser: IPromosByUser[] = [];

  constructor(
    private activeModal: NgbActiveModal,
    private licencasService: LicencasService
  ) { }

  ngOnInit(): void {
    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
    this.fetchPromosByUser()
  }

  fetchPromosByUser() {
    this.licencasService.getPromosByUser(this.userId)
    .pipe(
      filter((mayBeOk: HttpResponse<IPromosByUser[]>) => mayBeOk.ok),
      map((response: HttpResponse<IPromosByUser[]>) => response.body)
    ).subscribe({
      next: (response) => {
        if (!response) {
          this.alertType = 'danger';
          this._alert.next('Erro ao carregar promoções!');
          return;
        }

        this.promosByUser = response;
      },
      error: (_error: HttpResponse<any>) => {
        this.alertType = 'danger';
        this._alert.next('Erro ao carregar promoções!');
      }
    });
  }

  changePromoCreditsAmount(promoId: string, operation: 'INCREASE' | 'DECREASE') {
    const payload = {userId: this.userId, promoId, operation}

    this.licencasService.changePromoCreditsAmount(payload)
    .pipe(
      filter((mayBeOk: HttpResponse<IResponse>) => mayBeOk.ok),
      map((response: HttpResponse<IResponse>) => response.body)
    ).subscribe({
      next: (response) => {
        this.alertType = 'success';
        this._alert.next('Quantidade de créditos alterada com sucesso!');
        this.fetchPromosByUser();
      },
      error: (_error: HttpResponse<any>) => {
        this.alertType = 'danger';
        this._alert.next('Erro ao alterar quantidade de créditos!');
      }
    });
  }

  onCloseClick() {
    this.activeModal.dismiss();
  }
}
