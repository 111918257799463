<div class="modal-container">
    <div class="title-container" *ngIf="interests && interests.length > 0">
        <h1 class="title">Interesses de {{ connectionName }}:</h1>
    </div>

    <ul *ngIf="interests && interests.length > 0">
        <li class="option" *ngFor="let interest of interests">{{ interest.description }}</li>
    </ul>

    <div class="title-container" *ngIf="occupations && occupations.length > 0 && (!interests || interests.length == 0)">
        <h1 class="title">Área de atuação de {{ connectionName }}:</h1>
    </div>

    <div class="title-container" *ngIf="occupations && occupations.length > 0 && interests && interests.length > 0">
        <h1 class="title">Área de atuação:</h1>
    </div>

    <ul *ngIf="occupations && occupations.length > 0">
        <li class="option" *ngFor="let occupation of occupations">{{ occupation.description }}</li>
    </ul>

    <div class="title-container"
        *ngIf="(!interests || interests.length == 0) && (!occupations || occupations.length == 0)">
        <h1 class="title">{{ connectionName }} <br> não informou seus interesses e sua área de atuação.</h1>
    </div>

    <div class="buttons-container">
        <button (click)="closeModal(false)" class="skip-button">Fechar</button>
    </div>
</div>