import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getHours, isAfter, isSameDay } from 'date-fns';
import { Subject, debounceTime } from 'rxjs';
import { Connection } from '../model/connection.model';
import { DocumentUserProfile } from '../model/document-user-profile.model';
import { IEventWithParticipationStatus, SluperEvent } from '../model/event.model';
import { IDocumentPreferences } from '../model/preferences.model';
import { IUserProfilePlan } from '../model/user-plan.model';
import { DocumentUser } from './../model/document-user.model';
import { CreateCommunityModalComponent } from './create-community-modal/create-community-modal.component';
import { CreateEventModalComponent } from './create-event-modal/create-event-modal.component';
import { EventsService } from './events.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css'],
})
export class EventsComponent implements OnInit {
  @Output() changeMenu = new EventEmitter<string>();
  @Input() userProfile!: DocumentUserProfile | undefined;
  @Input() documentUser!: DocumentUser | undefined;
  @Input() userProfilePlan!: IUserProfilePlan;
  @Input() preferencesList!: IDocumentPreferences[];
  @Input() occupationsList!: IDocumentPreferences[];

  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;
  private _alert = new Subject<string>();
  alertMessage = '';
  alertType = '';

  content: 'event-details' | 'event-list' = 'event-list';
  loadingEvents = true;
  filteredCommunityEvents: {
    date: string;
    events: SluperEvent[];
  }[] = [];
  allEvents: SluperEvent[] = [];
  allCommunitys: SluperEvent[] = [];
  userConnections: Connection[] = [];
  nextEvents: IEventWithParticipationStatus[] = [];
  nextCommunitys: IEventWithParticipationStatus[] = [];
  ownerUserEvents: SluperEvent[] = [];
  ownerUserCommunitys: SluperEvent[] = [];
  openedEvent: SluperEvent | null = null;
  filterFields = {
    date: '',
    eventType: 'void',
  }

  defaultImageProfile = '/assets/img/imagem_perfil.png';

  selectedTab = 0;

  constructor(
    private modalService: NgbModal,
    private eventsService: EventsService,
  ) { }

  ngOnInit(): void {

    // this.userProfilePlan.profilePlanLimits.connectContacts = false;
    // this.userProfilePlan.profilePlanLimits.connectContactsView = -1;
    // this.userProfilePlan.profilePlanLimits.includeEvents = false;
    // this.userProfilePlan.profilePlanLimits.eventsIncludeQuantity = 8;
    // this.userProfilePlan.profilePlanLimits.eventsParticipantsView = true;

    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });

    // this.fetchCommunityEvents();
    this.fetchFutureUserEvents();
    this.fetchUserConnections();
  }

  fetchCommunityEvents() {
    this.loadingEvents = true;

    this.eventsService.getEvents().subscribe({
      next: (response) => {
        const communityEvents = response.body as SluperEvent[];
        this.allEvents = communityEvents.filter(ev => ev.type !== 'COMUNIDADE');
        this.allCommunitys = communityEvents.filter(ev => ev.type === 'COMUNIDADE');
        this.fillCommunityEvents(this.allEvents);

        this.loadingEvents = false;
      },
      error: (_error) => {
        this.loadingEvents = false;
      }
    });
  }

  fillCommunityEvents(events: SluperEvent[]) {
    this.filteredCommunityEvents = [];

    const formattedFieldDate = this.filterFields.date ? new Date(Number(this.filterFields.date.split('-').reverse()[0]), Number(this.filterFields.date.split('-').reverse()[1]) - 1, Number(this.filterFields.date.split('-').reverse()[2])) : null;

    let filteredEvents = events;

    if (formattedFieldDate) {
      filteredEvents = events.filter(e => isSameDay(new Date(e.startDate), new Date(formattedFieldDate)));
    }

    if (this.filterFields.eventType !== 'void') {
      filteredEvents = filteredEvents.filter(e => e.type === this.filterFields.eventType);
    }

    filteredEvents.forEach(event => {
      const eventDate = event.startDate.split('T')[0] as string;

      const eventDateIndex = this.filteredCommunityEvents.findIndex((event) => event.date === eventDate);

      if (eventDateIndex !== -1) {
        this.filteredCommunityEvents[eventDateIndex].events.push(event);

        return;
      }

      this.filteredCommunityEvents.push({
        date: eventDate,
        events: [event]
      });
    });

    this.filteredCommunityEvents.sort((a, b) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });
  }

  fetchFutureUserEvents() {
    this.loadingEvents = true;

    if (!this.userProfile) {
      return;
    }

    this.eventsService.getFutureEvents(this.userProfile.idUser).subscribe({
      next: (response) => {
        if (!response.body) {
          return;
        }

        const allEvents = response.body.allEvents as SluperEvent[];
        const nextEvents = response.body.eventsParticipanting as IEventWithParticipationStatus[];
        const userOwnEvents = response.body.eventsOrganizing as SluperEvent[];

        this.allEvents = allEvents.filter(ev => ev.type !== 'COMUNIDADE');
        this.allCommunitys = allEvents.filter(ev => ev.type === 'COMUNIDADE');
        this.fillCommunityEvents(this.allEvents);

        this.nextEvents = nextEvents.filter(ev => ev.event.type !== 'COMUNIDADE' && isAfter(new Date(ev.event.startDate), new Date()));
        this.nextCommunitys = nextEvents.filter(ev => ev.event.type === 'COMUNIDADE');
        this.ownerUserEvents = userOwnEvents.filter(ev => ev.type !== 'COMUNIDADE');
        this.ownerUserCommunitys = userOwnEvents.filter(ev => ev.type === 'COMUNIDADE');

        this.loadingEvents = false;
      },
      error: (_error) => {
        this.loadingEvents = false;
      }
    });
  }

  fetchUserConnections() {
    this.loadingEvents = true;

    if (!this.userProfile) {
      return;
    }

    this.eventsService.getUserConnections(this.userProfile.idUser).subscribe({
      next: (response) => {
        const userConnections = response && response.body ? response.body.filter(connection => !connection.indication) : null;

        this.loadingEvents = false;

        if (!userConnections) {
          return;
        }

        this.userConnections = userConnections;
      },
      error: (_error) => {
        this.loadingEvents = false;
      }
    });
  }

  handleEventClick(event: SluperEvent) {
    this.openedEvent = event;
    this.content = 'event-details';
  }

  handleBackToList() {
    this.openedEvent = null;
    this.content = 'event-list';
    // this.fetchCommunityEvents();
    this.fetchFutureUserEvents();
    this.fetchUserConnections();
  }

  openCreateEventModal() {
    const modalRef = this.modalService.open(CreateEventModalComponent, { centered: true, size: "xl" });

    modalRef.componentInstance.userProfile = this.userProfile;
    modalRef.componentInstance.triggerfeedbackMessage.subscribe((alert: { message: string; type: 'success' | 'danger' }) => {
      this._alert.next(alert.message);
      this.alertType = alert.type;

      if (alert.type === 'success') {
        this.filterFields = {
          date: '',
          eventType: 'void',
        }

        // this.fetchCommunityEvents();
        this.fetchFutureUserEvents();
      }
    });
  }

  openCreateCommunityModal() {
    const modalRef = this.modalService.open(CreateCommunityModalComponent, { centered: true, size: "xl" });

    modalRef.componentInstance.userProfile = this.userProfile;
    modalRef.componentInstance.triggerfeedbackMessage.subscribe((alert: { message: string; type: 'success' | 'danger' }) => {
      this._alert.next(alert.message);
      this.alertType = alert.type;

      if (alert.type === 'success') {
        this.filterFields = {
          date: '',
          eventType: 'void',
        }

        // this.fetchCommunityEvents();
        this.fetchFutureUserEvents();
      }
    });
  }

  handleFilterDateChange(_date: object) {
    this.fillCommunityEvents(this.allEvents);
  }

  handleFilterEventTypeChange(event: any) {
    this.filterFields.eventType = event.target.value;
    this.fillCommunityEvents(this.allEvents);
  }

  formatDate(event: any) {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, '');

    if (value.length > 8) {
      value = value.slice(0, 8);
    }

    if (value.length >= 2) {
      value = value.substring(0, 2) + '/' + value.substring(2);
    }
    if (value.length >= 5) {
      value = value.substring(0, 5) + '/' + value.substring(5);
    }

    input.value = value;
    this.fillCommunityEvents(this.allEvents);
  }

  handleShareEvent(e: any, event: SluperEvent) {
    e.stopPropagation();

    const shareData = {
      title: event.name,
      text: `Venha participar do ${event.name} em ${new Date(event.startDate).toLocaleString('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })} às ${getHours(new Date(event.startDate))}! Será em ${event.address}. Confira mais detalhes e confirme sua presença. Te esperamos lá!`,
      url: `https://perfil.sluper.bio/public-event/${event.id}`
    };

    if (!navigator.share) {
      return;
    }

    navigator.share(shareData);
  }

  changeSelectedTab(tab: number) {
    this.selectedTab = tab;
  }

  async openConnectionProfile(connection: Connection) {
    window.open(`https://sluper.bio/${connection.connection.idUser}`);
  }
}
