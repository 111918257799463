import { Component, ViewChild, OnInit } from '@angular/core';
import { NgbActiveModal, NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { LicencasService } from '../licencas.service';
import { Subject, debounceTime, filter, map } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { IResponse } from 'src/app/model/response.model';

@Component({
  selector: 'app-gerar-licencas-modal',
  templateUrl: './gerar-licencas-modal.component.html',
  styleUrls: ['./gerar-licencas-modal.component.css']
})
export class GerarLicencasModalComponent implements OnInit {

  numberOfLicenses: number = 0;
  emailOwner: string | null = null;
  emailUser: string | null = null;
  emailDestinatarioSemVinculo: string | null = null;
  tag: string | null = null;

  private _alert = new Subject<string>();
  alertMessage: string = '';
  alertType: string = '';
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;


  constructor(
    private activeModal: NgbActiveModal,
    private licencasService: LicencasService
  ) { }

  ngOnInit(): void {
    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  onCloseClick() {
    this.activeModal.dismiss();
  }

  generateLicenses() {
    if (this.numberOfLicenses <= 0 || this.numberOfLicenses == null || this.numberOfLicenses == undefined) {
      this.alertType = 'danger';
      this._alert.next('Informe um número de licença acima de 0.');
      return;
    }

    if (this.numberOfLicenses > 100) {
      this.alertType = 'danger';
      this._alert.next('Número de licenças acima do limite permitido.');
      return;
    }

    if ((this.emailUser == null || this.emailUser == '') && (this.emailOwner == null || this.emailOwner == '') && (this.emailDestinatarioSemVinculo == null || this.emailDestinatarioSemVinculo == '')) {
      this.alertType = 'danger';
      this._alert.next('Necessário informar um e-mail de recebimento.');
      return;
    }

    if (this.emailUser != null && this.emailUser != '' && this.numberOfLicenses > 1) {
      this.alertType = 'danger';
      this._alert.next('Número de licenças a serem geradas acima do permitido.');
      return;
    }

    this.licencasService.generateLicenses(this.numberOfLicenses, this.emailOwner, this.emailUser, this.emailDestinatarioSemVinculo, this.tag).
      pipe(
        filter((mayBeOk: HttpResponse<IResponse>) => mayBeOk.ok),
        map((response: HttpResponse<IResponse>) => response.body))
      .subscribe({
        next: (res) => this.setResponse(res),
        error: (res) => this.onError(res)
      });
  }

  setResponse(res: IResponse | null): void {
    if (res != null && res.status == "SUCCESS")
      this.activeModal.close(true)
  }

  onError(res: any) {
    if (res.error.status == "ERROR") {
      this.alertType = 'danger';
      this._alert.next(res.error.message);
    }
  }
}
