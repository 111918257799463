import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class Interceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // Verifique se a URL da solicitação é para o endpoint que não requer autenticação
    let isUnauthenticatedEndpoint =
      request.url.includes('/api/bizConnect/security/login') ||
      request.url.includes('/api/bizConnect/security/emailKey') ||
      request.url.includes('/api/bizConnect/security/validateToken') ||
      request.url.includes('/api/bizConnect/admin/license') ||
      request.url.includes('/api/bizConnect/admin/verificationKey') ||
      request.url.includes('/api/bizConnect/admin/firstAccess') ||
      request.url.includes('/api/bizConnect/events/eventsById') ||
      request.url.includes('/api/bizConnect/events/participantsByEventId');


      if(request.url.includes('/api/bizConnect/admin/licenses'))
        {
          isUnauthenticatedEndpoint = false;
        }
    if (isUnauthenticatedEndpoint) {
      // Se for um endpoint não autenticado, não adicione o cabeçalho "Authorization"
      return next.handle(request);
    } else {
      // Caso contrário, adicione o cabeçalho "Authorization"
      const token = localStorage.getItem('authToken');
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });

      return next.handle(request);
    }
  }
}
