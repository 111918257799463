export const LISTA_FONTES = [
  'Arial',
  'Brush Script MT',
  'Comic Sans MS',
  'Constantia',
  'Courier New',
  'Georgia',
  'Helvetica',
  'Impact',
  'Inter',
  'Lucida Sans',
  'Roboto',
  'SF Pro Display',
  'Trebuchet MS',
  'Verdana',
  'Wix Madefor Text',
];
