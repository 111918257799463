import { HttpResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { NgbAlert, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime, filter, map } from 'rxjs';
import { LicencasService } from 'src/app/licencas/licencas.service';
import { IResponse } from 'src/app/model/response.model';

@Component({
  selector: 'app-confirma-desvinculo-business-modal',
  templateUrl: './confirma-desvinculo-business-modal.component.html',
  styleUrls: ['./confirma-desvinculo-business-modal.component.css']
})
export class ConfirmaDesvinculoBusinessModalComponent {

  @Input() license!: string;
  @Input() user!: string;

  private _alert = new Subject<string>();
  alertMessage: string = '';
  alertType: string = '';
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  constructor(
    private activeModal: NgbActiveModal,
    private licencasService: LicencasService
  ) { }

  ngOnInit(): void {
    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  onCloseClick() {
    this.activeModal.dismiss();
  }

  desvincular() {
    this.licencasService.unlinkLicense(this.license).
      pipe(
        filter((mayBeOk: HttpResponse<IResponse>) => mayBeOk.ok),
        map((response: HttpResponse<IResponse>) => response.body))
      .subscribe({
        next: (res) => this.setResponse(res),
        error: (res) => console.log(res)
      });
  }

  setResponse(res: IResponse | null): void {
    if (res) {
      if (res.status == "SUCCESS") {
        this.activeModal.close(true);
      } else {
        this.alertType = 'danger';
        this._alert.next(res.message);
      }
    }
  }
}
