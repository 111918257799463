<div class="modal-container">
  <div class="title-container">
    <h1 class="title">Indique o contato {{nameUserIndication}} para:</h1>
  </div>

  <!-- <select [formControl]="connectionForm" multiple class="form-select">
    <option class="option" *ngFor="let connection of connections" [value]="connection">
      {{ connection.connection.name | titlecase }}
    </option>
  </select> -->

  <ng-select style="width: 100%;" [items]="connectionsProfiles" [multiple]="true" bindLabel="name" bindValue="idUser"
    [closeOnSelect]="false" [(ngModel)]="selectedConnections">
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"> {{ item.name | titlecase }}
    </ng-template>
  </ng-select>

  <div class="buttons-container">
    <button (click)="sendConnection()" class="update-plan-button"
      [disabled]="execute || (this.selectedConnections.length === 0)">
      Enviar
    </button>

    <button (click)="closeModal(false)" class="skip-button">Mais tarde</button>
  </div>
</div>