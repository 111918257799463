<div class="preview-background" [style]="
    userCss.cardBackgroundImageUrl
      ? 'background-image: url(' + userCss.cardBackgroundImageUrl + ')'
      : 'background:' + getBackgroundPreview()
  " [style.border-radius]="mobilePreview ? '0px' : '8px'" [style.padding]="mobilePreview ? '36px' : '36px'">
  <div class="preview-perfil-container" [style.background-image]="getBackgroundImageUrl()"
    [style.border-radius]="!header.visible && header.text && header.text.startsWith('\<br\>') ? '8px 8px 0 0' : '8px'">
    <img *ngIf="showImageProfile" [src]="profileImageUrl" alt="Imagem de Perfil" class="preview-image-profile" />

    <div class="bio-container">
      <div class="basic-infos-container">
        <span class="preview-nome-perfil-text" [style.color]="userCss.corFontePerfil"
          [style.font-family]="userCss.fontePerfil" [style.font-weight]="userCss.negritoPerfil ? 'bold' : undefined"
          [style.font-style]="userCss.italicoPerfil ? 'italic' : undefined">{{ name }}</span>
        <span class="preview-biografia-text" [style.color]="userCss.corFontePerfil"
          [style.font-family]="userCss.fontePerfil" [style.font-weight]="userCss.negritoPerfil ? 'bold' : undefined"
          [style.font-style]="userCss.italicoPerfil ? 'italic' : undefined">{{ bio }}</span>
      </div>

      <div class="principal-contacts-container">
        <div class="email-contact-container">
          <i class="bi bi-envelope contact-icon" [style.color]="userCss.corFontePerfil"></i>
        </div>

        <div class="phone-contact-container">
          <i class="bi bi-telephone contact-icon" [style.color]="userCss.corFontePerfil"></i>
        </div>

        <div class="email-contact-container">
          <i class="bi bi-geo-alt contact-icon" [style.color]="userCss.corFontePerfil"></i>
        </div>
      </div>
    </div>

    <div class="preview-bt-perfil-div">
      <button class="menu-button" [style.border-color]="userCss.corFontePerfil">
        <i class="bi bi-list" style="font-size: 14px" [style.color]="userCss.corFontePerfil"></i>
      </button>
    </div>
  </div>

  <div class="preview-descricao-perfil-container header-container" [style.background-color]="userCss.preenchimento"
    [style.box-shadow]="userCss.sombra" *ngIf="header.visible && header.text && !header.text.startsWith('\<br\>')">
    <span class="preview-descricao-perfil" [style.font-family]="userCss.fontePerfil" [innerHTML]="header.text">
      {{ header.text }}</span>
  </div>

  <div class="preview-icons">
    <button type="button" class="preview-icon-button" *ngIf="containsSocialNetwork('instagram')" disabled>
      <i class="bi bi-instagram" [style.color]="userCss.preenchimento"></i>
    </button>
    <button type="button" class="preview-icon-button" *ngIf="containsSocialNetwork('whatsapp')" disabled>
      <i class="bi bi-whatsapp" [style.color]="userCss.preenchimento"></i>
    </button>
    <button type="button" class="preview-icon-button" *ngIf="containsSocialNetwork('telegram')" disabled>
      <i class="bi bi-telegram" [style.color]="userCss.preenchimento"></i>
    </button>
    <button type="button" class="preview-icon-button" *ngIf="containsSocialNetwork('linkedin')" disabled>
      <i class="bi bi-linkedin" [style.color]="userCss.preenchimento"></i>
    </button>
    <button type="button" class="preview-icon-button" *ngIf="containsSocialNetwork('facebook')" disabled>
      <i class="bi bi-facebook" [style.color]="userCss.preenchimento"></i>
    </button>
    <button type="button" class="preview-icon-button" *ngIf="containsSocialNetwork('twitter')" disabled>
      <i class="bi bi-twitter-x" [style.color]="userCss.preenchimento"></i>
    </button>
    <button type="button" class="preview-icon-button" *ngIf="containsSocialNetwork('youtube')" disabled>
      <i class="bi bi-youtube" [style.color]="userCss.preenchimento"></i>
    </button>
    <button type="button" class="preview-icon-button" *ngIf="containsSocialNetwork('tik tok')" disabled>
      <i class="bi bi-tiktok" [style.color]="userCss.preenchimento"></i>
    </button>
    <button type="button" class="preview-icon-button" *ngIf="containsSocialNetwork('twitch')" disabled>
      <i class="bi bi-twitch" [style.color]="userCss.preenchimento"></i>
    </button>
    <button type="button" class="preview-icon-button" *ngIf="containsSocialNetwork('pinterest')" disabled>
      <i class="bi bi-pinterest" [style.color]="userCss.preenchimento"></i>
    </button>
  </div>

  <div class="button-links-container">
    <div *ngFor="let button of linkButtons" [style.display]="button.visible ? 'block' : 'none'">
      <iframe [style.border-radius]="userCss.curvaBorda + 'px '" [style.box-shadow]="userCss.sombra"
        [style.border-width]="userCss.espessura" [style.border-color]="userCss.contorno"
        [style.border]="userCss.espessura + 'px solid ' + userCss.contorno" class="video-link-iframe"
        *ngIf="button.visible && button.type === 'VIDEO' && !button.suspended" allowfullscreen="true"
        [src]="transformUrl(button.link)"></iframe>

      <a *ngIf="(!button.type || button.type === 'DEFAULT') && button.visible && !button.suspended"
        class="link-button-preview" [style.background-color]="userCss.preenchimento" [style.box-shadow]="userCss.sombra"
        style="border-style: solid" [style.border-width]="userCss.espessura" [style.border-color]="userCss.contorno"
        [style.background-image]="button.image && button.layout === 'HIGHLIGHTED' ? 'url(' + button.image + ')' : ''"
        [style.border]="userCss.espessura + 'px solid ' + userCss.contorno" [style.border-radius]="
          button.layout === 'HIGHLIGHTED' ? userCss.curvaBorda + 'px ' + userCss.curvaBorda + 'px 0px 0px' : userCss.curvaBorda + 'px '
        " [ngClass]="button.layout === 'HIGHLIGHTED' ? 'higlighted-button' : ''">
        <img *ngIf="button.image && button.layout === 'CLASSIC'" [src]="button.image" alt="preview image"
          class="classic-preview-image" />

        <div class="button-title" *ngIf="!button.layout || button.layout === 'CLASSIC'">
          <span [style.color]="userCss.corFonte" [style.font-family]="userCss.fonte" style="word-break: break-all"
            [style.font-weight]="userCss.negrito ? 'bold' : undefined"
            [style.font-style]="userCss.italico ? 'italic' : undefined">{{ button.title }}</span>
        </div>

        <div class="button-icon-container" *ngIf="!button.layout || button.layout === 'CLASSIC'">
          <i class="bi bi-chevron-right preview-arrow" [style.color]="userCss.corFonte"></i>
        </div>
      </a>

      <div class="highlighted-button-title-container"
        *ngIf="button.layout === 'HIGHLIGHTED' && (button.type === 'DEFAULT' || !button.type) && button.visible && !button.suspended"
        style="border-style: solid; border-top: 0px" [style.background-color]="userCss.preenchimento"
        [style.box-shadow]="userCss.sombra" [style.border-width]="userCss.espessura"
        [style.border-color]="userCss.contorno"
        [style.border-radius]="'0px 0px ' + userCss.curvaBorda + 'px ' + userCss.curvaBorda + 'px'"
        [style.border]="userCss.espessura + 'px solid ' + userCss.contorno">
        <span class="highlighted-button-title" [style.color]="userCss.corFonte" [style.font-family]="userCss.fonte"
          [style.font-weight]="userCss.negrito ? 'bold' : undefined"
          [style.font-style]="userCss.italico ? 'italic' : undefined">
          {{ button.title }}
        </span>

        <div class="button-icon-container">
          <i class="bi bi-chevron-right preview-arrow"></i>
        </div>
      </div>

      <div class="product-link-item" *ngIf="button.type === 'PRODUCT'" [style.background-color]="userCss.preenchimento"
        [style.box-shadow]="userCss.sombra" style="border-style: solid" [style.border-width]="userCss.espessura"
        [style.border-radius]="userCss.curvaBorda + 'px'" [style.border-color]="userCss.contorno"
        [style.border]="userCss.espessura + 'px solid ' + userCss.contorno">
        <img *ngIf="!!button.image" [src]="button.image" alt="product-image"
          [style.border-radius]="userCss.curvaBorda + 'px'" class="product-image" />

        <section class="product-heading-section">
          <h1 class="product-title" [style.color]="userCss.corFonte" [style.font-family]="userCss.fonte">{{ button.title
            }}</h1>
          <div class="product-description" [innerHTML]="button.description">{{ button.description }}</div>
        </section>

        <section class="product-price-section">
          <span [style.color]="getColorContrast(userCss.corFonte || '#fff', 0.1)" class="product-price">{{
            button.productValue
            ? button.productValue.toLocaleString(undefined, {
            style: 'currency',
            currency: button.currency,
            minimumFractionDigits: 2
            })
            : ''
            }}</span>
        </section>
      </div>
    </div>

    <div class="button-container" *ngIf="documentUser?.isReseller">
      <a class="link-button-preview" [href]="getUserSaleLink()" target="_blank"
        [style.background-color]="userCss.preenchimento" [style.box-shadow]="userCss.sombra"
        [style.border-width]="userCss.espessura" [style.border-color]="userCss.contorno"
        [style.border-radius]="userCss.curvaBorda + 'px'">
        <div class="button-title">
          <span [style.color]="userCss.corFonte" [style.font-family]="userCss.fonte"
            [style.font-weight]="userCss.negrito ? 'bold' : undefined"
            [style.font-style]="userCss.italico ? 'italic' : undefined">
            Compre seu Sluper comigo!
          </span>
        </div>

        <div class="button-icon-container">
          <i class="bi bi-chevron-right arrow"></i>
        </div>
      </a>
    </div>
  </div>
</div>