import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TenantInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tenantId = window.location.hostname;
    const modifiedReq = req.clone({
      setHeaders: {
        'X-Tenant-Id': tenantId,
      }
    });

    return next.handle(modifiedReq);
  }
}
