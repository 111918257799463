<div class="modal-container">
    <div class="title-container">
        <h1 class="title">Tem certeza que deseja remover a conexão com <br>{{ nameConnection }}?</h1>
    </div>
    <div class="buttons-container">
        <button (click)="removeConnection()" class="update-plan-button" [disabled]="execute">
            Sim
        </button>

        <button (click)="closeModal(false)" class="skip-button">Fechar</button>
    </div>
</div>