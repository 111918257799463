<div class="info-box">
    <div class="d-flex flex-column align-items-center gap-3">
        <span class="text-normal-16-500 text-grey">Etapa 3</span>
        <div class="etapas-box">
            <div class="etapa-roxo text-white">1</div>
            <div class="linha-roxa"></div>
            <div class="etapa-roxo text-white">2</div>
            <div class="linha-roxa"></div>
            <div class="etapa-roxo text-white">3</div>
        </div>
    </div>
    <span class="text-normal-32-700 text-dark-blue text-center text-center">Links de contato</span>
    <div class="scrollable-links">
        <form [formGroup]="linksForm">
            <div formArrayName="links" *ngFor="let link of links.controls; let i = index">
                <div [formGroupName]="i" class="mb-3">
                    <label for="link-{{ i }}" class="text-normal-16-700">Link {{ i + 1 }}</label>
                    <div class="input-group input-group-md">
                        <div class="input-group-prepend">
                            <button class="btn btn-outline-secondary dropdown-toggle" type="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                {{ link.get('type')?.value || 'Selecionar' }}
                            </button>
                            <ul class="dropdown-menu" (scroll)="onScroll($event)">
                                <li *ngFor="let rede of redesVisiveis">
                                    <a class="dropdown-item" href="#" (click)="selectLabel(i, rede, $event)">
                                        {{ rede.name }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <input type="text" class="form-control" id="link-{{ i }}" formControlName="link"
                            [placeholder]="link.get('placeholder')?.value" [readonly]="link.get('readOnly')?.value"
                            (input)="handleKeyDownSocialNetwork($event, i)">
                    </div>
                </div>
            </div>
        </form>
    </div>
    <button type="button" class="btn btn-adicionar-links" (click)="addLink()">
        <span class="text-normal-16-700 text-grey">+ Adicionar mais links</span>
    </button>
    <div class="button-box">
        <button type="button" class="btn button-transparente-roxo-form" (click)="voltarPagina()">
            <span class="text-normal-16-700 text-purple">&lt; Voltar</span>
        </button>
        <button type="button" class="btn button-roxo-form" (click)="proximaPagina()">
            <span class="text-normal-16-700 text-white">Finalizar</span>
        </button>
    </div>
</div>