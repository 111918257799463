<div class="alert-div">
    <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
        {{ alertMessage }}
    </ngb-alert>
</div>
<div class="modal-header">
    <span class="title">Desvincular Licença</span>
    <button type="button" class="btn-close" aria-label="Close" (click)="onCloseClick()"></button>
</div>
<div class="modal-body content">
    <div class="input-div">
        <span class="labels-text">Tem certeza que deseja desvincular a licença <b>{{ license }}</b> do usuário
            <b>{{ user }}</b>?</span>
    </div>
</div>
<div class="footer">
    <button type="button" class="gerar-button" (click)="desvincular()">Desvincular</button>
    <button type="button" class="cancel-button" (click)="onCloseClick()">Cancelar</button>
</div>