<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<div class="public-event-container">
  <app-loading-spinner *ngIf="loading"></app-loading-spinner>

  <app-event-details style="width: 100%" [authorizedPage]="false" *ngIf="!loading" [eventId]="eventId"></app-event-details>
</div>
