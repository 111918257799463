import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppNavigateService {

  constructor(private router: Router) { }

  navegarLogin() {
    this.router.navigate(['/login'])
  }

  navegarCadastro() {
    this.router.navigate(['/cadastro'])
  }

  navegarChaveAtivacao(license?: string) {
    if (license) {
      this.router.navigate([`/chave-ativacao/${license}`]);
    } else {
      this.router.navigate(['/chave-ativacao']);
    }
  }

  navegarEnvioEmailCriacaoConta(){
    this.router.navigate(['/confirmacao-criacao-conta']);
  }

  navegarConfiguracaoCadastro() {
    this.router.navigate(['/configuracao-cadastro'])
  }

  navegarUserProfile() {
    this.router.navigate(['/user-profile'])
  }

  navegarCadastroPerfilUsuario() {
    this.router.navigate(['/cadastro-perfil-usuario'])
  }
}
