import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom, forkJoin } from 'rxjs';
import { AppNavigateService } from 'src/app/app-navigate.service';
import { AuthService } from 'src/app/auth/auth.service';
import { CadastroConcluidoModalComponent } from 'src/app/new-sluper/core/components/cadastro-concluido-modal/cadastro-concluido-modal.component';
import { Contact } from 'src/app/new-sluper/core/model/contact.model';
import { DocumentUserCSS } from 'src/app/new-sluper/core/model/document-user-css.model';
import { DocumentUserProfile } from 'src/app/new-sluper/core/model/document-user-profile.model';
import { Personal } from 'src/app/new-sluper/core/model/personal.model';
import { Profissional } from 'src/app/new-sluper/core/model/professional.model';
import { SocialNetwork } from 'src/app/new-sluper/core/model/social-network.model';
import { UserProfileWithCSS } from 'src/app/new-sluper/core/model/user-profile-css.model';
import { LoadingService } from 'src/app/new-sluper/core/service/loading.service';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { UserProfileService } from 'src/app/new-sluper/core/service/user-profile.service';
import { LoginService } from '../../login/login.service';
import { CadastroStorageService } from '../cadastro-storage.service';
import { ConfiguracaoCadastroStorageService } from './configuracao-cadastro-storage.service';

@Component({
  selector: 'app-configuracao-cadastro',
  templateUrl: './configuracao-cadastro.component.html',
  styleUrls: ['./configuracao-cadastro.component.css']
})
export class ConfiguracaoCadastroComponent implements OnInit {
  currentScreen: number = 1;

  personalData: Personal = new Personal();
  professionalData: Profissional = new Profissional();
  socialNetworkData: SocialNetwork[] = [];
  userProfile: UserProfileWithCSS = new UserProfileWithCSS();
  accessToken: any;

  constructor(
    private configCadastroStorage: ConfiguracaoCadastroStorageService,
    private cadastroStorage: CadastroStorageService,
    private userProfileService: UserProfileService,
    private loginService: LoginService,
    private authService: AuthService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private appNavigateService: AppNavigateService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() { }

  nextScreen() {
    if (this.currentScreen < 4) {
      this.currentScreen++;
    }
  }

  previousScreen() {
    if (this.currentScreen > 1) {
      this.currentScreen--;
    }
  }

  storeData() {
    this.personalData = this.configCadastroStorage.getPersonalData() as Personal;
    this.professionalData = this.configCadastroStorage.getProfessionalData() as Profissional;
    this.socialNetworkData = this.configCadastroStorage.getSocialNetworkData() as SocialNetwork[];
  }

  async salvarDados() {
    try {
      this.loadingService.show();
      this.storeData();

      // Verifica se todos os dados necessários estão preenchidos
      this.validateData();

      // Armazena os dados e autentica o usuário
      await this.authenticate();

      // Executa todas as operações assíncronas simultaneamente
      await this.updateAll();

      console.log('Dados salvos com sucesso:');
      this.toastService.showSuccess('Dados salvos com sucesso.');

      this.modalService.open(CadastroConcluidoModalComponent, {
        centered: true,
        size: 'lg'
      });

      this.appNavigateService.navegarCadastroPerfilUsuario();

    } catch (error) {
      console.error('Erro ao salvar dados:', error);
      this.toastService.showError('Erro ao salvar dados. Tente novamente.');
    } finally {
      this.loadingService.hide();
    }
  }

  private validateData() {
    if (!this.personalData || !this.professionalData || !this.socialNetworkData.length) {
      throw new Error('Por favor, preencha todos os campos obrigatórios.');
    }
  }

  private async authenticate() {
    try {
      const codigoVerificacao = this.cadastroStorage.codigoVerificacao;
      const email = this.cadastroStorage.email;

      const { body: responseBody } = await firstValueFrom(this.loginService.validateEmailKey(codigoVerificacao, email));

      if (!responseBody || responseBody.status !== 'SUCCESS') {
        throw new Error('Falha na validação do código de verificação.');
      }

      this.accessToken = responseBody.message;
      this.checkAccessToken();

      await this.authService.login(this.accessToken);
    } catch (error) {
      console.error('Erro na autenticação:', error);
      throw error;
    }
  }

  private checkAccessToken() {
    if (!this.accessToken) {
      throw new Error('Token de acesso não obtido.');
    }
  }

  private async updateAll() {
    try {
      this.checkAccessToken();

      // Obtém o perfil do usuário e realiza atualizações simultâneas
      await this.getUserProfile();
      const documentUser = this.prepareDocumentUser();
      const documentUserProfile = this.prepareDocumentUserProfile();

      // Executa as operações em paralelo
      await firstValueFrom(
        forkJoin([
          this.userProfileService.saveDocumentUser(documentUser),
          this.userProfileService.saveDocumentUserProfile(documentUserProfile),
          this.userProfileService.saveUserPublicSearch(true)
        ])
      );

      console.log('Todas as atualizações foram concluídas com sucesso.');
    } catch (error) {
      console.error('Erro ao atualizar os dados do usuário:', error);
      throw error;
    }
  }

  private async getUserProfile() {
    try {
      const { body: responseBody } = await firstValueFrom(this.userProfileService.userById(null));

      if (!responseBody) {
        throw new Error('Perfil do usuário não encontrado.');
      }

      this.userProfile = responseBody;

      // Inicializa as propriedades se necessário
      if (!this.userProfile.documentUserProfile) {
        const documentUserProfile = new DocumentUserProfile();
        this.userProfile.documentUserProfile = { ...documentUserProfile, idUser: this.userProfile.documentUser.idUser };
      }

      if (!this.userProfile.documentUserCSS) {
        const documentUserCSS = new DocumentUserCSS();
        this.userProfile.documentUserCSS = documentUserCSS;
      }

      return responseBody;
    } catch (err) {
      console.error("Erro ao buscar perfil do usuário: ", err);
      throw err;
    }
  }

  private prepareDocumentUser() {
    const documentUser = {
      ...this.userProfile.documentUser,
      personal: {
        ...this.userProfile.documentUser?.personal,
        ...(this.personalData?.name && { name: this.personalData.name }),
        ...(this.personalData?.phone && { phone: this.personalData.phone }),
        ...(this.personalData?.lastName && { lastName: this.personalData.lastName }),
        ...(this.personalData?.birthdate && { birthdate: this.personalData.birthdate }),
        ...(this.personalData?.cep && { cep: this.personalData.cep }),
        country: 'BR',
      },
      professional: this.professionalData,
      email: this.cadastroStorage.email,
      idUser: this.cadastroStorage.license
    };
    return documentUser;
  }

  private prepareDocumentUserProfile() {
    const documentUserProfile = new DocumentUserProfile();
    documentUserProfile.listSocialNetwork = this.socialNetworkData;
    documentUserProfile.name = this.personalData.name;
    documentUserProfile.publish = true;
    documentUserProfile.listButtons = [];
    documentUserProfile.publicSearch = true;
    const contact: Contact = new Contact();
    contact.type = 'email';
    contact.type = this.cadastroStorage.email;
    documentUserProfile.listContacts = [contact];
    documentUserProfile.idUser = this.cadastroStorage.license;

    return documentUserProfile;
  }
}
