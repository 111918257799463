import { Component } from "@angular/core";

@Component({
  selector: 'app-join-reseller-program',
  templateUrl: './join-reseller-program.component.html',
  styleUrls: ['./join-reseller-program.component.css'],
})
export class JoinResellerProgramComponent {


}
