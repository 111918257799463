import { HttpResponse } from '@angular/common/http';
import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Subject,
  filter,
  map
} from 'rxjs';
import { ISluperPromotion, UserProfileService } from '../user-profile/user-profile.service';
import { SluperCoinsHistoryModalComponent } from './coins-history-modal/coins-history-modal.component';

@Component({
  selector: 'app-sluper-coins',
  templateUrl: './sluper-coins.component.html',
  styleUrls: ['./sluper-coins.component.css'],
})
export class SLuperCoinsComponent implements OnInit {
  private _alert = new Subject<string>();
  alertMessage = '';
  alertType = '';
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert:
    | NgbAlert
    | undefined;

  sluperPromoCredits: ISluperPromotion[] = [];

  constructor(
    private userProfileService: UserProfileService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) {  }

  ngOnInit(): void {
    this.fetchSluperPromoCredits();
  }

  fetchSluperPromoCredits() {
    this.userProfileService.getSluperCoinsByUser().pipe(
      filter((mayBeOk: HttpResponse<ISluperPromotion[]>) => mayBeOk.ok),
      map((response: HttpResponse<ISluperPromotion[]>) => response.body),
    ).subscribe({
      next: (res) => {
        if (!res) return

        this.sluperPromoCredits = res.map((promo) => {
          return promo;
        });
      },
      error: (res) => console.log(res),
    }
    )
  }

  openCoinsHistoryModal(promo: ISluperPromotion) {
    const modalRef = this.modalService.open(SluperCoinsHistoryModalComponent, { size: 'lg' });
    modalRef.componentInstance.promo = promo;
  }
}
