export interface IPhone {
  siglaPais?: string;
  ddi?: string;
  phoneNumber?: string;
}

export class Phone {
  constructor(
    public siglaPais?: string,
    public ddi?: string,
    public phoneNumber?: string,
  ) {}
}
