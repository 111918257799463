<div class="networks-selector-container">
  <button class="network-selector" (click)="handleToggleList()">
    <i *ngIf="formattedNetworksList[selectedNetwork] !== undefined && formattedNetworksList[selectedNetwork].icon"
      [class]="'bi ' + formattedNetworksList[selectedNetwork].icon"></i>

    <i class="bi bi-chevron-down"></i>
  </button>

  <ul class="networks-list" *ngIf="openedList">
    <li class="network-item" *ngFor="let network of networksList" (click)="handleSelectNetwork(network.value)"
      [ngClass]="network.value === selectedNetwork ? 'selected-network' : ''">
      <i [class]="'bi ' + network.icon"></i>
      <span class="network-title">{{network.name}}</span>
    </li>
  </ul>
</div>

<div class="overlay" (click)="handleToggleList()" *ngIf="openedList"></div>