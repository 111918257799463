import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subject, debounceTime } from 'rxjs';

interface ICropImageInfos {
  aspectRatio: number;
  maxWidth: number;
  maxHeight: number;
  minWidth?: number;
  minHeight?: number;
  rounded?: number;
}

@Component({
  selector: 'app-crop-link-images-modal',
  templateUrl: './crop-link-images-modal.component.html',
  styleUrls: ['./crop-link-images-modal.component.css'],
})
export class CropLinkImageModalComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert:
    | NgbAlert
    | undefined;
  @Input() cropImageInfos!: ICropImageInfos;
  @Input() confirmImageCrop!: (croppedImage: string, fileName: string) => void;

  private _alert = new Subject<string>();
  alertMessage = '';
  alertType = '';

  screenHeight: any;
  screenWidth: any;
  cropMode = false;
  imageChangedEvent: any
  maxSizeInBytes = 2 * 1024 * 1024;
  croppedImage = '';

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });

    return;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  closeModal() {
    this.activeModal.close();
  }

  fileChangeEvent(event: any, minWidth: number, minHeight: number): void {
    const file: File = event.target.files[0];

    if (file.size > this.maxSizeInBytes) {
      this.alertType = 'danger';
      this.alertMessage = 'Tamanho da arquivo é maior do que o permitido. Favor selecionar uma imagem de até 2MB.';
      this._alert.next('Tamanho da arquivo é maior do que o permitido. Favor selecionar uma imagem de até 2MB.');
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const image = new Image();

      image.onload = () => {
        if (image.width < minWidth || image.height < minHeight) {
          this.alertType = 'danger';
          this.alertMessage = `A imagem precisa ter no mínimo ${minWidth}x${minHeight} pixels.`;
          this._alert.next(`A imagem precisa ter no mínimo ${minWidth}x${minHeight} pixels.`);

          return;
        }

        image.src = e.target.result as string;
        this.cropMode = true;
      }
    };

    reader.readAsDataURL(file);

    this.imageChangedEvent = event;
    this.cropMode = true;
  }

  handleImageCropped(event: ImageCroppedEvent) {
    const blob = event.blob;
    if (blob && blob.size) {
      const reader = new FileReader();

      reader.onload = () => {
        this.croppedImage = reader.result as string;
      };

      reader.readAsDataURL(blob);
    }
  }

  handleRemoveSelectedImage() {
    this.croppedImage = '';
    this.imageChangedEvent = null;
    this.cropMode = false;
  }
}
