import { Component, HostListener, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IDisplayedLink } from '../model/button.model';
import { IFormattedProfileData } from '../model/profile.model';
import { IDisplayedSocialNetwork } from '../model/social-network.model';
import { IUserCss } from '../model/user-css.model';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';

@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.css'],
})
export class PreviewModalComponent {
  @Input() userCss!: IUserCss;
  @Input() showImageProfile = true;
  @Input() profile!: IFormattedProfileData;
  @Input() userProfile!: IUserProfileWithCSS;
  @Input() buttons: IDisplayedLink[] = [];
  @Input() socialNetworks: IDisplayedSocialNetwork[] = [];

  screenHeight: any;
  screenWidth: any;

  constructor(private activeModal: NgbActiveModal) {}

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }


  onCloseClick() {
    this.activeModal.close();
  }
}
