import { Phone } from './phone.model';


export class Personal {
  name: string;
  lastName: string;
  birthdate: string;
  country: string;
  phone: Phone;
  cep: string;
  constructor(

  ) {
    this.name = '';
    this.lastName = '';
    this.birthdate = '';
    this.country = '';
    this.phone = new Phone();
    this.cep = '';
  }
}
