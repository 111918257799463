<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
        <!-- Logo à esquerda -->
        <div class="navbar-brand">
            <img src="../../../../assets/img-sluper/logo-sluper-colorido.png" alt="Logo" class="img-fluid"
                style="height: 40px;">
        </div>

        <!-- <div class="d-flex align-items-center ms-auto">
            <button class="btn btn-link me-3">
                <i class="bi bi-bell" style="font-size: 1.5rem;"></i>
            </button>

            <div class="me-3">
                <img src="https://via.placeholder.com/40" alt="Foto de Perfil" class="rounded-circle"
                    style="height: 40px; width: 40px;">
            </div>

            <button class="btn btn-link">
                <i class="bi bi-list" style="font-size: 1.5rem;"></i>
            </button>
        </div> -->
    </div>
</nav>