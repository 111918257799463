import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TenantInterceptor } from './tenant-interceptor.service';

@NgModule({
  providers: [
    TenantInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantInterceptor,
      multi: true,
    },
  ],
})
export class TenantInterceptorModule {}
