import { firstValueFrom } from 'rxjs';
import { EventsService } from 'src/app/events/events.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BOLD_BUTTON, EditorConfig, FONT_SIZE_SELECT, FORE_COLOR, ITALIC_BUTTON, SEPARATOR, UNDO_BUTTON } from 'ngx-simple-text-editor';
import { SluperEvent } from 'src/app/model/event.model';

@Component({
  selector: 'app-event-nps',
  templateUrl: './event-nps.component.html',
  styleUrls: ['./event-nps.component.css']
})
export class EventNpsComponent implements OnInit {

  @Input() idUser = '';
  @Input() event! : SluperEvent;
  @Output() triggerfeedbackMessage = new EventEmitter<{
    message: string;
    type: 'success' | 'danger';
  }>();
  config: EditorConfig = {
    placeholder: '',
    // buttons: [UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, FORE_COLOR, FONT_SIZE_SELECT, SEPARATOR, JUSTIFY_LEFT_BUTTON, JUSTIFY_CENTER_BUTTON, JUSTIFY_RIGHT_BUTTON, JUSTIFY_FULL_BUTTON],
    buttons: [UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, FORE_COLOR, FONT_SIZE_SELECT]
  };

  commentTouched: boolean = false;

  modelData: {
    eventId: string,
    userId: string,
    review: number,
    comment: string
  } = {
    eventId: '',
    userId: '',
    review: 6,
    comment: ''
  }

  constructor(private activeModal: NgbActiveModal,
              private eventsService: EventsService
              ) {

   }

  ngOnInit(): void {
    return;
  }

  async sendNPS() {
    this.commentTouched = true;
    if (!this.modelData.comment || this.modelData.comment.trim().length === 0) {
      return;
    }

    this.modelData.eventId = this.event.id;
    this.modelData.userId = this.idUser;
    try {
      await firstValueFrom(this.eventsService.submitReview(this.modelData)).then(e => {

      });

      this.triggerfeedbackMessage.emit({
        message: 'Avaliação salva com sucesso',
        type: 'success'
      });

      this.closeModal(true);

    } catch (error) {
      this.triggerfeedbackMessage.emit({
        message: 'Erro ao salvar avaliação',
        type: 'danger'
      });
    }

  }
  closeModal(status: boolean) {
    this.activeModal.close(status);
  }
}
