<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>
<div class="modal-container">
  <header class="modal-header">
    <h4 class="modal-title" translate>Selecionar Imagem</h4>
    <button type="button" class="close" aria-label="Fechar" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </header>

  <section class="modal-body">
    <label class="image-upload-label" for="image-upload" *ngIf="!cropMode">
      <input
        id="image-upload"
        class="image-upload"
        accept="image/jpeg, image/png, image/jpg"
        type="file"
        (change)="fileChangeEvent($event, cropImageInfos.minWidth || 1, cropImageInfos.minHeight || 1)" />

      <i class="bi bi-card-image"></i>

      <span>Clique aqui para selecionar e enviar a sua foto</span>
    </label>

    <ng-container>
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="cropImageInfos.aspectRatio"
        format="jpeg"
        (imageCropped)="handleImageCropped($event)"
        [cropperMaxWidth]="cropImageInfos.maxWidth"
        [cropperMaxHeight]="cropImageInfos.maxHeight"
        [roundCropper]="!!cropImageInfos.rounded"></image-cropper>
    </ng-container>
  </section>

  <footer class="modal-footer">
    <button type="button" class="btn btn-secondary" *ngIf="cropMode" (click)="handleRemoveSelectedImage()" translate>Remover</button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="confirmImageCrop(croppedImage, imageChangedEvent.target.files[0].name)"
      translate>
      Confirmar
    </button>
  </footer>
</div>
