import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.css']
})
export class ProfilePictureComponent {
  @Input() profileImage: string = '';
  @Output() imageChanged = new EventEmitter<{ newImage: string, file?: File }>();

  showChangeOptions: boolean = false;
  profileImageFile: File | undefined;

  toggleChangeProfile() {
    this.showChangeOptions = !this.showChangeOptions;
  }

  onChangeProfilePicture() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (event: any) => {
      const file = event.target.files[0];
      if (file) {
        this.profileImageFile = file; // Armazena o arquivo selecionado
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.imageChanged.emit({
            newImage: e.target.result,
            file: this.profileImageFile // Inclui o arquivo na emissão do evento
          });
          this.showChangeOptions = false;
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  }
}
