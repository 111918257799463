export interface RedeSocial {
  name: string;
  value: string;
  placeHolder: string;
  redeSocialLink: string;
  icon: string;
}

export const LISTA_REDES: RedeSocial[] = [
  {
    name: 'Facebook',
    value: 'facebook',
    redeSocialLink: 'https://www.facebook.com/',
    placeHolder: 'meu_perfil',
    icon: 'bi-facebook',
  },
  {
    name: 'Instagram',
    value: 'instagram',
    redeSocialLink: 'https://www.instagram.com/',
    placeHolder: 'meu_perfil',
    icon: 'bi-instagram',
  },
  {
    name: 'Linkedin',
    value: 'linkedin',
    redeSocialLink: 'https://www.linkedin.com/in/',
    placeHolder: 'meu_perfil',
    icon: 'bi-linkedin',
  },
  {
    name: 'Pinterest',
    value: 'pinterest',
    redeSocialLink: 'https://br.pinterest.com/',
    placeHolder: 'meu_perfil',
    icon: 'bi-pinterest',
  },
  {
    name: 'Telegram',
    value: 'telegram',
    redeSocialLink: 'https://t.me/meu_nome_usuario',
    placeHolder: 'meu_nome_usuario',
    icon: 'bi-telegram',
  },
  {
    name: 'Tik Tok',
    value: 'tik tok',
    redeSocialLink: 'https://www.tiktok.com/',
    placeHolder: '@meu_perfil',
    icon: 'bi-tiktok',
  },
  {
    name: 'Twitch',
    value: 'twitch',
    redeSocialLink: 'https://www.twitch.tv/',
    placeHolder: 'meu_canal',
    icon: 'bi-twitch',
  },
  {
    name: 'Twitter',
    value: 'twitter',
    redeSocialLink: 'https://twitter.com/',
    placeHolder: 'meu_perfil',
    icon: 'bi-twitter-x',
  },
  {
    name: 'WhatsApp',
    value: 'whatsapp',
    redeSocialLink: 'https://wa.me/',
    placeHolder: '+55031999999999',
    icon: 'bi-whatsapp',
  },
  {
    name: 'YouTube',
    value: 'youtube',
    redeSocialLink: 'https://www.youtube.com/',
    placeHolder: '@meu_canal',
    icon: 'bi-youtube',
  },
  {
    name: 'Url',
    value: 'url',
    placeHolder: 'www.seusite.com.br',
    redeSocialLink: '',
    icon: 'bi-url',
  },
];
