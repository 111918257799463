import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

export interface IUserTemplatesOutput {
  id: string;
  adminId: string;
  css: string;
  createdDate: Date;
  isDefault?: boolean;
  name: string;
  updatedDate: Date;
}

export interface ICorpUserOutput {
  id: string;
  name: string | null;
  email: string;
  uri: string | null;
  linkedTemplate?: {
    id: string;
    name: string;
    css: string;
    adminId: string;
    createDate: Date;
    updateDate: Date;
  };
}

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  baseUrl = environment.profileUrl;
  baseAdmUrl = environment.administradorUrl;

  constructor(protected http: HttpClient, protected jwtHelper: JwtHelperService, protected router: Router) { }

  getUserTemplates(): Observable<HttpResponse<IUserTemplatesOutput[]>> {
    return this.http.get<IUserTemplatesOutput[]>(`${this.baseAdmUrl}/corp-css-templates`, {
      observe: 'response',
    });
  }

  saveTemplate(template: { css: string; name: string; }): Observable<HttpResponse<any>> {
    return this.http.post(`${this.baseAdmUrl}/corp-css-templates`, template, {
      observe: 'response',
    });
  }

  deleteTemplate(id: string): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.baseAdmUrl}/corp-css-templates/${id}`, {
      observe: 'response',
    });
  }

  setDefaultTemplate(id: string): Observable<HttpResponse<any>> {
    return this.http.put(`${this.baseAdmUrl}/set-default-template/${id}`, null, {
      observe: 'response',
    });
  }

  getCorpUsers(): Observable<HttpResponse<ICorpUserOutput[]>> {
    return this.http.get<ICorpUserOutput[]>(`${this.baseAdmUrl}/corp-users`, {
      observe: 'response',
    });
  }

  uploadFile(file: File): Observable<string> {
    const formData = new FormData();
    formData.append('img', file);

    return this.http.put<string>(`${this.baseUrl}/image`, formData, {
      responseType: 'text' as 'json'
    });
  }

  uploadFileTemplate(file: File): Observable<string> {
    const formData = new FormData();
    formData.append('img', file);

    return this.http.put<string>(`${this.baseUrl}/templateImage`, formData, {
      responseType: 'text' as 'json'
    });
  }

  applyTemplate(templateId: string, mode: 'SYNC' | 'COPY',  userIds: string[], nfcAccessOnly: boolean, publicSearch: boolean): Observable<HttpResponse<any>> {
    return this.http.post(`${this.baseAdmUrl}/user-profiles/apply-template`, {
      templateId,
      mode,
      userIds,
      nfcAccessOnly,
      publicSearch
    }, {
      observe: 'response',
    });
  }

  unlinkTemplate(userId: string): Observable<HttpResponse<any>> {
    return this.http.put(`${this.baseAdmUrl}/user-profiles/unlink-template/${userId}`, null, {
      observe: 'response',
    });
  }
}
