import { Component } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';

@Component({
  selector: 'app-sluper-background',
  templateUrl: './sluper-background.component.html',
  styleUrls: ['./sluper-background.component.css']
})
export class SluperBackgroundComponent {

  urlBackgroundImageLogin = '';

  constructor(private loginService: LoginService){
    this.getBackgroundLoginImage();
  }

  getBackgroundLoginImage() {
    this.loginService.getBackgroundLoginImage(window.location.hostname).subscribe({
      next: (response: string) => {
        this.urlBackgroundImageLogin = response;
      },
      error: (error) => {
        this.urlBackgroundImageLogin = '../../../../assets/img-sluper/simbolo-brand.png';
        if (error.status === 404) {
          console.warn('Imagem de cabeçalho não existe, exibindo cabeçalho padrão (404).');
        } else {
          console.error('Erro ao buscar a imagem de login:', error);
        }
      },
    });
  }
}
