<div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
    <div class="mb-3">
        <!-- Input invisível para o arquivo -->
        <input type="file" id="backgroundImage" class="d-none" (change)="onChangeBackgroundImage($event)">

        <!-- Botão estilizado para escolher imagem -->
        <button type="button" class="btn button-transparente-roxo-form" (click)="triggerFileInput()">
            <span class="text-normal-16-700 text-purple">
                <i class="bi bi-image"></i> Adicionar Imagem
            </span>
        </button>
    </div>

    <!-- Input de cor -->
    <div class="row">
        <div class="col-md-6 col-12">
            <img src="../../../../../assets/images/imagem_fundo.png" class="img-fluid image-with-margin"
                (click)="selectBackgroundImage($event)">
        </div>
        <div class="col-md-6 col-12">
            <img src="../../../../../assets/images/imagem-fundo-azul-escuro.png" class="img-fluid image-with-margin"
                (click)="selectBackgroundImage($event)">
        </div>
        <div class="col-md-6 col-12">
            <img src="../../../../../assets/images/imagem-fundo-azul-claro.png" class="img-fluid image-with-margin"
                (click)="selectBackgroundImage($event)">
        </div>
        <div class="col-md-6 col-12">
            <img src="../../../../../assets/images/imagem-fundo-cinza.png" class="img-fluid image-with-margin"
                (click)="selectBackgroundImage($event)">
        </div>
        <div class="col-md-6 col-12">
            <img src="../../../../../assets/images/imagem-fundo-amarelo.png" class="img-fluid image-with-margin"
                (click)="selectBackgroundImage($event)">
        </div>
        <div class="col-md-6 col-12">
            <img src="../../../../../assets/images/imagem-fundo-vermelho.png" class="img-fluid image-with-margin"
                (click)="selectBackgroundImage($event)">
        </div>
    </div>
    <span class="text-normal-16-700 text-grey d-flex justify-content-center">ou selecionar cor</span>

</div>
<!-- <input type="color" class="form-control form-control-color selecionar-cor" id="backgroundColor"
            [(ngModel)]="backgroundColor" (input)="onColorChange()"> -->