import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LISTA_REDES, RedeSocial } from "src/app/model/redes";

@Component({
  selector: 'app-networks-selector',
  templateUrl: './networks-selector.component.html',
  styleUrls: ['./networks-selector.component.css']
})
export class NetworksSelectorComponent implements OnInit {
  @Input() selectedNetwork = '';
  @Output() handleChangeNetwork = new EventEmitter<string>();

  networksList = LISTA_REDES;
  formattedNetworksList: { [k: string]: RedeSocial } = {};
  openedList = false;


  constructor() {
    LISTA_REDES.forEach((network) => {
      this.formattedNetworksList[network.value] = network;
    })
  }

  ngOnInit(): void {
    LISTA_REDES.forEach((network) => {
      this.formattedNetworksList[network.value] = network;
    })
  }

  handleSelectNetwork(network: string): void {
    this.selectedNetwork = network;
    this.handleToggleList();
    this.handleChangeNetwork.emit(network);
  }

  handleToggleList(): void {
    this.openedList = !this.openedList;
  }
}
