import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IUserProfilePlan } from "../model/user-plan.model";
import { IUserProfileWithCSS } from "../model/user-profile-css.model";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
})
export class HomeComponent {
  @Input() userProfile!: IUserProfileWithCSS | null;
  @Input() userProfilePlan!: IUserProfilePlan;
  @Output() menuSelected = new EventEmitter<string>();

  defaultProfileImage = '/assets/img/imagem_perfil.png';

  getPlanStampStyles() {
      const planStampStyles: { [k: string]: string } = {
          'Plano Básico': "background-color: transparent; color: #2F80ED;background: linear-gradient(to right, #56CCF2, #2F80ED); border-radius: 28px;",
          'free plan 2': "background-color: transparent; color: #2F80ED;background: linear-gradient(to right, #56CCF2, #2F80ED); border-radius: 28px;",
      }

      const defaultPlanStyle = "background-color: transparent; color: #9733EE; background: linear-gradient(to right, #DA22FF, #9733EE, #9B51E0); border-radius: 28px;"

      return planStampStyles[this.userProfilePlan.planName] || defaultPlanStyle;
  }

  changeMenu(menu: string) {
      this.menuSelected.emit(menu);
  }

  shareProfile(e: MouseEvent) {
    if (!this.userProfile?.documentUserProfile.listURI || this.userProfile.documentUserProfile.listURI.length < 1 || !this.userProfile?.documentUserProfile?.listURI[0]?.link) return;

    e.stopPropagation();

    const shareData = {
      title: 'Conheça meu perfil no Sluper.bio',
      text: `Conheça meu perfil no Sluper.bio!`,
      url: `https://sluper.bio/${this.userProfile?.documentUserProfile.listURI[0].link}`
    };

    if (!navigator.share) {
      return;
    }

    navigator.share(shareData);
  }
}
