export interface ITipoContato {
  name: string;
  value: string;
}

export const LISTA_TIPOS_CONTATOS = [
  { name: 'Telefone', value: 'telefone' },
  { name: 'E-mail', value: 'email' },
  { name: 'Localização', value: 'localizacao' },
];
