import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-preview',
  templateUrl: './login-preview.component.html',
  styleUrls: ['./login-preview.component.css'],
})
export class LoginPreviewComponent implements OnInit {
  urlImagemLogin = '';
  urlBackgroundImageLogin = '';

  classColorido = '';
  borderRight = 'border-right-branco';

  constructor() {
    this.urlImagemLogin = sessionStorage.getItem('loginImageUrl') || '/assets/img/Marca Sluper.png/';
    this.urlBackgroundImageLogin = sessionStorage.getItem('backgroundLoginImageUrl') || '/assets/img/Simbolo_Brand.png/';
   }

  ngOnInit(): void {

  }
}
