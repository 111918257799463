<div class="info-box">
    <div class="d-flex flex-column align-items-center gap-3">
        <span class="text-normal-16-500 text-grey">Etapa 2</span>
        <div class="etapas-box">
            <div class="etapa-roxo text-white">1</div>
            <div class="linha-roxa"></div>
            <div class="etapa-roxo text-white">2</div>
            <div class="linha-roxa"></div>
            <div class="etapa-cinza text-white">3</div>
        </div>
    </div>
    <div class="d-flex flex-column align-items-center gap-2">
        <span class="text-normal-32-700 text-dark-blue text-center">Informações da empresa</span>
        <span class="text-normal-16-500 text-grey">*Itens obrigatórios</span>
    </div>
    <form class="form" [formGroup]="empresaForm">
        <div class="form-div">
            <label for="nomeEmpresa" class="label-text-form" translate>Nome da Empresa*</label>
            <input type="text" id="nomeEmpresa" class="form-input" formControlName="company" />
        </div>
        <div class="form-div">
            <label for="cnpj" class="label-text-form" translate>CNPJ*</label>
            <input type="text" id="cnpj" class="form-input" formControlName="cnpj" mask="00.000.000/0000-00" />
        </div>
        <div class="form-div">
            <label for="emailCorporativo" class="label-text-form" translate>Email Corporativo</label>
            <input type="email" id="emailCorporativo" class="form-input" formControlName="email" />
        </div>
        <!-- <div class="form-div">
            <label for="endereco" class="label-text-form" translate>País</label>
            <input type="text" id="endereco" class="form-input" formControlName="country" />
        </div> -->
        <div class="form-div">
            <label for="endereco" class="label-text-form" translate>CEP</label>
            <input type="text" id="endereco" class="form-input" formControlName="cep" mask="00000-000" />
        </div>
    </form>
    <div class="button-box">
        <button type="button" class="btn button-transparente-roxo-form" (click)="voltarPagina()">
            <span class="text-normal-16-700 text-purple" translate>
                < Voltar </span>
        </button>
        <button type="button" class="btn button-roxo-form" (click)="proximaPagina()">
            <span class="text-normal-16-700 text-white" translate>
                Próximo >
            </span>
        </button>
    </div>
</div>