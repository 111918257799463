import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cadastro-concluido-modal',
  templateUrl: './cadastro-concluido-modal.component.html',
  styleUrls: ['./cadastro-concluido-modal.component.css']
})
export class CadastroConcluidoModalComponent {
  constructor(public activeModal: NgbActiveModal) { }

  close() {
    this.activeModal.close();
  }
}
