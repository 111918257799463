import { HttpResponse } from '@angular/common/http';
import { Component, HostListener, ViewChild } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime, filter, firstValueFrom, map } from 'rxjs';
import { IDocumentUserProfile } from '../model/document-user-profile.model';
import { DOCUMENT_USER_CSS, DOCUMENT_USER_PROFILE } from '../model/documents';
import { Phone } from '../model/phone.model';
import { IDocumentPreferences } from '../model/preferences.model';
import { IUserProfilePlan } from '../model/user-plan.model';
import { AuthService } from './../auth/auth.service';
import { IDocumentUser } from './../model/document-user.model';
import { Personal } from './../model/personal.model';
import { IUserProfileWithCSS } from './../model/user-profile-css.model';
import { UserAdminPlan, UserProfileService } from './user-profile.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;
  private _alert = new Subject<string>();
  alertMessage = '';
  alertType = '';
  urlImagemHeader = '';

  mobileMenuOpened = false;

  userAdminPlan!: UserAdminPlan;
  active = 1;
  userProfile: IUserProfileWithCSS | null = null;
  response = '';
  homeSelected = false;
  aparenciaSelected = false;
  sentContactsSelected = false;
  menuSelected = false;
  linksSelected = false;
  indicadoresSelected = false;
  opcoesSelected = false;
  dashboardSelected = false;
  salesDashboardSelected = false;
  sluperCoinsSelected = false;
  corpTemplatesSelected = false;
  userProfilePlan: IUserProfilePlan = {
    planName: 'default',
    profilePlanLimits: {
      contactsInclude: 0,
      socialLinksInclude: 0,
      linksInclude: 0,
      leadsViewContacts: 0,
      dashViews: false,
      dashAccessGraph: false,
      dashAccessedLinks: false,
      professionalView: false,
      eventsView: false,
      eventsIncludeQuantity: 0,
      connectContacts: false,
      includeEvents: false,
      connectContactsView: 0,
      participateInEvents: false,
      eventsParticipantsView: false
    }
  };
  eventsSelected = false;
  businessSelected = false;
  preferences!: IDocumentPreferences[];
  userPreferences!: IDocumentPreferences[];
  occupations!: IDocumentPreferences[];

  idUser = '';

  loading = true;
  screenWidth: any;
  screenHeight: any;

  disableMenu = false;

  constructor(
    private userProfileService: UserProfileService,
    protected jwtHelper: JwtHelperService,
    private authService: AuthService
  ) {
    this.getHeaderImage();
    this.getUserById();
    this.getUserProfilePlan();
    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(500000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });

    this.fetchData();
    this.getScreenSize();
    this.getUserAdminPlan();
    this.getPreferences();
  }

  async fetchData() {
    this.loading = true;

    try {
      const userData = await firstValueFrom(this.userProfileService
        .userById(null)
        .pipe(
          filter((mayBeOk: HttpResponse<IUserProfileWithCSS>) => mayBeOk.ok),
          map((response: HttpResponse<IUserProfileWithCSS>) => response.body),
        )
      )

      this.setUserProfile(userData);
      // this.getUserPreferences();

      const userPlan = await firstValueFrom(this.userProfileService
        .getUserPlan()
        .pipe(
          filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
          map((response: HttpResponse<any>) => response.body),
        )
      );

      this.userProfilePlan = userPlan;

      this.loading = false;
    } catch (e) {
      this.loading = false;

      this.alertType = 'danger';
      this._alert.next(
        'Não foi possível buscar as informaçōes do perfil. Contacte o administrador.',
      );

      setTimeout(() => {
        this.logout();
      }, 3000);
    }
  }

  getPlanStampStyles() {
    const planStampStyles: { [k: string]: string } = {
      'Plano Básico': "background-color: transparent; color: #2F80ED;background: linear-gradient(to right, #56CCF2, #2F80ED); border-radius: 28px;",
      'free plan 2': "background-color: transparent; color: #2F80ED;background: linear-gradient(to right, #56CCF2, #2F80ED); border-radius: 28px;",
    }

    const defaultPlanStyle = "background-color: transparent; color: #9733EE; background: linear-gradient(to right, #DA22FF, #9733EE, #9B51E0); border-radius: 28px;"

    return planStampStyles[this.userProfilePlan.planName] || defaultPlanStyle;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  getUserById(): void {
    this.userProfileService
      .userById(null)
      .pipe(
        filter((mayBeOk: HttpResponse<IUserProfileWithCSS>) => mayBeOk.ok),
        map((response: HttpResponse<IUserProfileWithCSS>) => response.body),
      )
      .subscribe({
        next: (res) => this.setUserProfile(res),
        error: (res) => console.log(res),
      });
  }

  getUserProfilePlan(): void {
    this.userProfileService
      .getUserPlan()
      .pipe(
        filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
        map((response: HttpResponse<any>) => response.body),
      )
      .subscribe({
        next: (res) => {
          this.userProfilePlan = res;
        },
        error: (res) => console.log(res),
      });
  }

  setUserProfile(res: IUserProfileWithCSS | null) {
    this.userProfile = res;

    if (this.userProfile) {
      if (this.userProfile.documentUserProfile !== null && this.userProfile.documentUserCSS !== null) {
        this.homeSelected = true;
        this.active = 1;

        return;
      }

      if (this.userProfile.documentUserProfile === null) {
        this.userProfile.documentUserProfile = DOCUMENT_USER_PROFILE;
      }

      if (this.userProfile.documentUserCSS === null) {
        this.userProfile.documentUserCSS = DOCUMENT_USER_CSS;
      }

      this.getIdUser();
      this.userProfile.documentUserProfile.idUser = this.idUser;
      this.userProfile.documentUserCSS.idUser = this.idUser;
      this.opcoesSelected = true;
      this.active = 4;
    }

    const primeiroAcesso = localStorage.getItem('primeiroAcesso');
    if (primeiroAcesso === 'true') {
      // Atualiza dados de usuário primeiro acesso
      const nome = localStorage.getItem('nome');

      if (
        this.userProfile &&
        this.userProfile.documentUserProfile &&
        nome &&
        this.userProfile.documentUserProfile.name == ''
      ) {
        this.userProfile.documentUserProfile.name = nome;
        this.atualizaDocumentUserProfile(this.userProfile.documentUserProfile);
      }

      if (
        this.userProfile &&
        this.userProfile.documentUser &&
        nome &&
        this.userProfile.documentUser.personal === undefined
      ) {
        this.userProfile.documentUser.personal = new Personal(
          '',
          '',
          '',
          new Phone('', ''),
          '',
        );
        this.userProfile.documentUser.personal.name = nome;
        this.atualizaDocumentUser(this.userProfile.documentUser);
      }

      // Remover a validação de primeiro acesso e variaveis
      localStorage.removeItem('primeiroAcesso');
      localStorage.removeItem('nome');
      localStorage.removeItem('email');
    }
  }

  atualizaDocumentUserProfile(documentUserProfile: IDocumentUserProfile): void {
    this.userProfileService
      .saveDocumentUserProfile(documentUserProfile)
      .pipe(
        filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
        map((response: HttpResponse<any>) => response.body),
      )
      .subscribe({
        next: (res) => console.log(res),
        error: (res) => console.log(res),
      });
  }

  atualizaDocumentUser(documentUser: IDocumentUser): void {
    this.userProfileService
      .saveDocumentUser(documentUser)
      .pipe(
        filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
        map((response: HttpResponse<any>) => response.body),
      )
      .subscribe({
        next: (res) => console.log(res),
        error: (res) => console.log(res),
      });
  }

  getIdUser() {
    const token: string | null = localStorage.getItem('authToken');
    if (token) {
      const decodedToken = this.jwtHelper.decodeToken(token);
      const userId = decodedToken.idUser;
      this.idUser = userId;
    }
  }

  mudouMenu(selecionado: string) {
    if (selecionado == 'aparencia') {
      this.active = 2;
      this.homeSelected = false;
      this.sentContactsSelected = false;
      this.aparenciaSelected = true;
      this.menuSelected = false;
      this.linksSelected = false;
      this.indicadoresSelected = false;
      this.opcoesSelected = false;
      this.dashboardSelected = false;
      this.salesDashboardSelected = false;
      this.sluperCoinsSelected = false;
      this.eventsSelected = false;
      this.businessSelected = false;
      this.corpTemplatesSelected = false;
    } else if (selecionado == 'home') {
      this.active = 1;
      this.homeSelected = true;
      this.sentContactsSelected = false;
      this.aparenciaSelected = false;
      this.menuSelected = true;
      this.linksSelected = false;
      this.indicadoresSelected = false;
      this.opcoesSelected = false;
      this.dashboardSelected = false;
      this.salesDashboardSelected = false;
      this.sluperCoinsSelected = false;
      this.eventsSelected = false;
      this.businessSelected = false;
      this.corpTemplatesSelected = false;
    } else if (selecionado == 'links') {
      this.active = 3;
      this.sentContactsSelected = false;
      this.homeSelected = false;
      this.aparenciaSelected = false;
      this.menuSelected = false;
      this.linksSelected = true;
      this.indicadoresSelected = false;
      this.opcoesSelected = false;
      this.dashboardSelected = false;
      this.salesDashboardSelected = false;
      this.sluperCoinsSelected = false;
      this.eventsSelected = false;
      this.businessSelected = false;
      this.corpTemplatesSelected = false;
    } else if (selecionado == 'indicadores') {
      this.active = 5;
      this.sentContactsSelected = false;
      this.homeSelected = false;
      this.aparenciaSelected = false;
      this.menuSelected = false;
      this.linksSelected = false;
      this.indicadoresSelected = true;
      this.opcoesSelected = false;
      this.dashboardSelected = false;
      this.salesDashboardSelected = false;
      this.sluperCoinsSelected = false;
      this.eventsSelected = false;
      this.businessSelected = false;
    } else if (selecionado == 'opcoes') {
      this.active = 4;
      this.sentContactsSelected = false;
      this.homeSelected = false;
      this.aparenciaSelected = false;
      this.menuSelected = false;
      this.linksSelected = false;
      this.indicadoresSelected = false;
      this.opcoesSelected = true;
      this.dashboardSelected = false;
      this.salesDashboardSelected = false;
      this.sluperCoinsSelected = false;
      this.eventsSelected = false;
      this.businessSelected = false;
      this.corpTemplatesSelected = false;
    } else if (selecionado == 'sent-contacts') {
      this.active = 6;
      this.aparenciaSelected = false;
      this.homeSelected = false;
      this.menuSelected = false;
      this.linksSelected = false;
      this.indicadoresSelected = false;
      this.opcoesSelected = false;
      this.dashboardSelected = false;
      this.sentContactsSelected = true;
      this.salesDashboardSelected = false;
      this.sluperCoinsSelected = false;
      this.eventsSelected = false;
      this.businessSelected = false;
      this.corpTemplatesSelected = false;
    } else if (selecionado == 'dashboard') {
      this.active = 7;
      this.aparenciaSelected = false;
      this.homeSelected = false;
      this.menuSelected = false;
      this.linksSelected = false;
      this.indicadoresSelected = false;
      this.opcoesSelected = false;
      this.sentContactsSelected = false;
      this.dashboardSelected = true;
      this.salesDashboardSelected = false;
      this.sluperCoinsSelected = false;
      this.eventsSelected = false;
      this.businessSelected = false;
      this.corpTemplatesSelected = false;
    } else if (selecionado == 'sales-dashboard') {
      this.active = 8;
      this.aparenciaSelected = false;
      this.homeSelected = false;
      this.menuSelected = false;
      this.linksSelected = false;
      this.indicadoresSelected = false;
      this.opcoesSelected = false;
      this.sentContactsSelected = false;
      this.dashboardSelected = false;
      this.salesDashboardSelected = true;
      this.sluperCoinsSelected = false;
      this.eventsSelected = false;
      this.businessSelected = false;
      this.corpTemplatesSelected = false;
    } else if (selecionado == 'sluper-coins') {
      this.active = 9;
      this.aparenciaSelected = false;
      this.homeSelected = false;
      this.menuSelected = false;
      this.linksSelected = false;
      this.indicadoresSelected = false;
      this.opcoesSelected = false;
      this.sentContactsSelected = false;
      this.dashboardSelected = false;
      this.salesDashboardSelected = false;
      this.sluperCoinsSelected = true;
      this.eventsSelected = false;
      this.businessSelected = false;
      this.corpTemplatesSelected = false;
    } else if (selecionado == 'events') {
      this.active = 10;
      this.aparenciaSelected = false;
      this.homeSelected = false;
      this.menuSelected = false;
      this.linksSelected = false;
      this.indicadoresSelected = false;
      this.opcoesSelected = false;
      this.sentContactsSelected = false;
      this.dashboardSelected = false;
      this.salesDashboardSelected = false;
      this.sluperCoinsSelected = false;
      this.eventsSelected = true;
      this.businessSelected = false;
      this.corpTemplatesSelected = false;
    } else if (selecionado == 'business') {
      this.active = 12;
      this.aparenciaSelected = false;
      this.homeSelected = false;
      this.menuSelected = false;
      this.linksSelected = false;
      this.indicadoresSelected = false;
      this.opcoesSelected = false;
      this.sentContactsSelected = false;
      this.dashboardSelected = false;
      this.salesDashboardSelected = false;
      this.sluperCoinsSelected = false;
      this.eventsSelected = false;
      this.businessSelected = true;
      this.corpTemplatesSelected = false;
    } else if (selecionado == 'business-templates') {
      this.active = 13;
      this.aparenciaSelected = false;
      this.homeSelected = false;
      this.menuSelected = false;
      this.linksSelected = false;
      this.indicadoresSelected = false;
      this.opcoesSelected = false;
      this.sentContactsSelected = false;
      this.dashboardSelected = false;
      this.salesDashboardSelected = false;
      this.sluperCoinsSelected = false;
      this.eventsSelected = false;
      this.businessSelected = false;
      this.corpTemplatesSelected = true;
    }

    if (this.mobileMenuOpened) {
      this.toggleMobileMenu();
    }
  }

  linkRequired(value: any) {
    this.disableMenu = value;
  }

  contaSaved() {
    this.mudouMenu('aparencia');
  }

  toggleMobileMenu() {
    this.mobileMenuOpened = !this.mobileMenuOpened;
  }

  logout() {
    this.authService.logout();
  }

  getPreferences() {
    this.userProfileService
      .getPreferences()
      .pipe(
        filter((mayBeOk: HttpResponse<IDocumentPreferences[]>) => mayBeOk.ok),
        map((response: HttpResponse<IDocumentPreferences[]>) => response.body),
      )
      .subscribe({
        next: (res) => {
          if (res !== null) {
            this.preferences = res.filter(p => p.type === 'INTERESSE');
            this.occupations = res.filter(o => o.type === 'AREA_ATUACAO');
            this.occupations.sort((a, b) => a.description.localeCompare(b.description));
          }
        },
        error: (res) => console.log(res),
      });
  }

  handleHomeOutput(event: string) {
    if (event != 'logout') {
      this.mudouMenu(event);
    } else {
      this.logout();
    }
  }

  getHeaderImage() {
    this.userProfileService.getHeaderImage(window.location.hostname).subscribe({
      next: (response: string) => {
        this.urlImagemHeader = response;
      },
      error: (error) => {
        if (error.status === 404) {
          console.warn('Imagem de cabeçalho não existe, exibindo cabeçalho padrão (404).');
        } else {
          console.error('Erro ao buscar a imagem de login:', error);
        }
      },
    });
  }

  getUserAdminPlan(): void {
    this.userProfileService
      .getAdmPlan()
      .pipe(
        filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
        map((response: HttpResponse<any>) => response.body),
      )
      .subscribe({
        next: (res) => {
          this.userAdminPlan = res;
          this.loading = false;
        },
        error: (res) => {
          console.log(res)
          this.loading = false;
        },
      });
  }
}
