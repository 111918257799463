<div class="code-inputs">
    <input type="text" maxlength="1" class="code-input" (input)="onInput($event, codigo2)"
        (keydown)="onKeydown($event, null)" (paste)="onPaste($event, codigo2)" #codigo1
        aria-label="Código de Verificação 1" />
    <input type="text" maxlength="1" class="code-input" (input)="onInput($event, codigo3)"
        (keydown)="onKeydown($event, codigo1)" (paste)="onPaste($event, codigo3)" #codigo2
        aria-label="Código de Verificação 2" />
    <input type="text" maxlength="1" class="code-input" (input)="onInput($event, codigo4)"
        (keydown)="onKeydown($event, codigo2)" (paste)="onPaste($event, codigo4)" #codigo3
        aria-label="Código de Verificação 3" />
    <input type="text" maxlength="1" class="code-input" (input)="onInput($event, null)"
        (keydown)="onKeydown($event, codigo3)" (paste)="onPaste($event, null)" #codigo4
        aria-label="Código de Verificação 4" />
</div>