export interface IDocumentUserCSS {
  idUser: string;
  css: string;
  locked?: boolean;
  createDate?: string;
  updateDate?: string;
}

export class DocumentUserCSS implements IDocumentUserCSS {
  idUser: string;
  css: string;
  locked?: boolean;
  createDate?: string;
  updateDate?: string;
  constructor() {
    this.idUser = "";
    this.css = "";
    this.locked = false;
    this.createDate = "";
    this.updateDate = "";
  }
}
