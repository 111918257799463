<app-sluper-background></app-sluper-background>
<app-header-inicial></app-header-inicial>
<div class="container">
    <div class="row ">
        <!-- Coluna para o formulário de login -->
        <div class="col-12 col-md-5 col-sm-12 d-flex flex-column justify-content-center mb-4 mb-md-0 ">
            <div class="shadow-box">
                <span class="text-normal-32-700 text-white" translate>Login</span>
                <div class="form-div mt-4">
                    <label for="user" class="label-text-inicio" translate>Email</label>
                    <input type="email" name="user" id="user" class="form-input" [formControl]="userEmail" />
                </div>
                <div class="form-div mt-4" *ngIf="foiEnviadoCodVerf">
                    <label for="verification" class="label-text-inicio" translate>Código de Verificação</label>
                    <app-codigo-verificacao></app-codigo-verificacao>
                    <span class="text-reenviar-codigo text-white mt-2 d-inline-block" (click)="reenviarCodigo()"
                        translate>Reenviar Código</span>
                </div>
                <button type="button" class="btn button-form w-100 mt-4" (click)="entrar()">
                    <span class="text-normal-16-700 text-white" translate>{{buttonLabelEntrar}}</span>
                </button>
            </div>
        </div>

        <!-- Coluna para a imagem e mensagem -->
        <div class="col-12 col-md-7 col-sm-12 d-flex flex-column justify-content-center align-items-end">
            <div class="image-box position-relative">
                <div class="image-content-box">
                    <div class="text-image-box">
                        <span class="text-normal-40-700 text-white">Ainda não tem Sluper?</span>
                        <span class="text-line-height text-white">Não deixe de se conectar com o que importa.</span>
                    </div>
                    <button type="button" class="btn button-roxo-form w-100 mt-4" (click)="navegarCadastro()">
                        <span class="text-normal-16-700 text-white" translate>Cadastre-se</span>
                    </button>
                </div>
                <img class="image-cadastro" src="../../../../assets/images/homem-cadastro.png" alt="Anuncio Sluper" />
            </div>
        </div>
    </div>
</div>