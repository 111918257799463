import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CadastroStorageService } from '../../cadastro-storage.service';

@Component({
  selector: 'app-cadastro-termos-uso',
  templateUrl: './cadastro-termos-uso.component.html',
  styleUrls: ['./cadastro-termos-uso.component.css']
})
export class CadastroTermosUsoComponent implements OnInit {
  nomeUsuario: string = ''
  isChecked: boolean = false;
  @Output() nextScreen: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private cadastroStorageService: CadastroStorageService,
    private modalService: NgbModal,
  ) {

  }

  ngOnInit(): void {
    this.nomeUsuario = this.cadastroStorageService.username;
  }

  iniciar() {
    if (this.isChecked) {
      this.nextScreen.emit();
    }
  }

  abrirTermosDeUso() {
    window.open('https://sluper.digital/termos-de-uso-e-condicoes-gerais', '_blank');
  }

  abrirPoliticaPrivacidade() {
    window.open('https://sluper.digital/politica-de-privacidade/', '_blank');
  }
}
