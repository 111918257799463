import { NetworkingPlan } from './networking-plan.model';
import { Personal } from './personal.model';
import { Profissional } from './professional.model';


export class DocumentUser {

  idUser: string;
  email: string;
  emailKey: string;
  role: string;
  promoCode: string;
  isReseller: boolean;
  active: boolean;
  personal: Personal;
  professional: Profissional;
  createDate?: string;
  updateDate?: string;
  networkingPlan: NetworkingPlan;

  constructor(

  ) {
    this.idUser = '';
    this.email = '';
    this.emailKey = '';
    this.role = '';
    this.active = false;
    this.personal = new Personal();
    this.professional = new Profissional();
    this.networkingPlan = new NetworkingPlan();
    this.promoCode = '';
    this.isReseller = false;
    this.idUser = '';
  }
}
