<div class="info-box">
    <div class="d-flex flex-column align-items-center gap-3">
        <span class="text-normal-16-500 text-grey">Etapa 1</span>
        <div class="etapas-box">
            <div class="etapa-roxo text-white">1</div>
            <div class="linha-roxa"></div>
            <div class="etapa-cinza text-white">2</div>
            <div class="linha"></div>
            <div class="etapa-cinza text-white">3</div>
        </div>
    </div>
    <div class="d-flex flex-column align-items-center gap-2">
        <span class="text-normal-32-700 text-dark-blue">Informações de perfil</span>
        <span class="text-normal-16-500 text-grey">*Itens obrigatórios</span>
    </div>
    <form class="form" [formGroup]="pessoaForm">
        <div class="form-div">
            <label for="nomeCompleto" class="label-text-form" translate>Nome Completo*</label>
            <input type="text" id="nomeCompleto" class="form-input" formControlName="nomeCompleto" />
        </div>
        <div class="input-container form-div">
            <label for=" telefone" class="label-text-form" translate>Telefone*</label>
            <div class="input-group inputs">
                <select class="form-select input-text" name="paisTelefone" formControlName="paisTelefone"
                    (change)="changePaisTelefoneSelecionado()">
                    <option value=""></option>
                    <option [ngValue]="paisTelefone" *ngFor="let paisTelefone of paises">
                        {{ paisTelefone.nomePais }} {{ paisTelefone.codigo }}
                    </option>
                </select>
                <input type="text" class="form-control input-text telefone-input" id="telefone" name="telefone"
                    formControlName="telefone" [mask]="pessoaForm.get('paisTelefone')?.value?.mascara || ''"
                    [disabled]="!pessoaForm.get('paisTelefone')?.value"
                    [placeholder]="pessoaForm.get('paisTelefone')?.value ? pessoaForm.get('paisTelefone')?.value.mascara : ''" />
            </div>
        </div>
        <div class="form-div">
            <label for="birthdate" class="label-text-form" translate>Data de Nascimento*</label>
            <input type="text" id="birthdate" class="form-input" formControlName="birthdate" mask="00/00/0000" />
        </div>
        <!-- <div class="form-div">
            <label for="endereco" class="label-text-form" translate>País*</label>
            <input type="text" id="endereco" class="form-input" formControlName="country" />
        </div> -->
        <div class="form-div">
            <label for="endereco" class="label-text-form" translate>CEP*</label>
            <input type="text" id="endereco" class="form-input" formControlName="cep" mask="00000-000" />
        </div>
    </form>
    <div class="button-box">
        <button type="button" class="btn button-transparente-roxo-form" (click)="voltarPagina()">
            <span class="text-normal-16-700 text-purple" translate>
                < Voltar </span>
        </button>
        <button type="button" class="btn button-roxo-form" (click)="proximaPagina()">
            <span class="text-normal-16-700 text-white" translate>
                Próximo >
            </span>
        </button>
    </div>
</div>