<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<div class="body-div">
  <app-event-details (backToList)="handleBackToList()" [authorizedPage]="true" [userProfile]="userProfile"
    *ngIf="content === 'event-details' && openedEvent" [currentUserConnections]="userConnections"
    [preferencesList]="preferencesList" [occupationsList]="occupationsList" [userProfilePlan]="userProfilePlan"
    [eventId]="openedEvent.id" [documentUser]="documentUser"></app-event-details>

  <section class="tab-selector" *ngIf="!loadingEvents && content === 'event-list'">
    <div [class]="selectedTab === 0 ? 'tab selected-tab' : 'tab'" (click)="changeSelectedTab(0)">
      <i class="bi bi-calendar-event"></i>

      <h2 class="tab-title">Eventos</h2>
    </div>

    <div [class]="selectedTab === 1 ? 'tab selected-tab' : 'tab'" (click)="changeSelectedTab(1)">
      <i class="bi bi-people-fill"></i>

      <h2 class="tab-title">Comunidades</h2>
    </div>
  </section>

  <section class="content-container">
    <app-loading-spinner *ngIf="loadingEvents"></app-loading-spinner>
    <div class="events-list-container" *ngIf="!loadingEvents && content === 'event-list' && selectedTab === 0">
      <header class="title-container">
        <section class="title-section">
          <h1 class="title">Eventos</h1>
          <h2 class="subtitle" translate>events.subtitle</h2>
        </section>

        <button class="new-event-button" (click)="openCreateEventModal()"
          *ngIf="(documentUser && documentUser.role === 'adm') || userProfilePlan.profilePlanLimits.includeEvents">Organizar
          Evento</button>

        <a target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
          class="no-dash-graph-access-button"
          *ngIf="!(documentUser && documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.includeEvents !== undefined && !userProfilePlan.profilePlanLimits.includeEvents">
          <i class="bi bi-lock-fill"></i>

          <div class="no-dash-graph-messages">
            Para criar novos eventos,
            <a target="_blank"
              href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal">faça
              upgrade do seu plano</a>
          </div>
        </a>
      </header>

      <div class="content-container">
        <section class="own-event-items-section">
          <div class="own-event-item-content">
            <div class="own-event-item-title-container">
              <span class="own-event-item-title" translate>events.nextEvents</span>
            </div>

            <div class="own-event-item-list-container">
              <div class="small-event-box" *ngFor="let event of nextEvents" (click)="handleEventClick(event.event)">
                <img class="small-event-image" [src]="event.event.imageLink" alt="my event image" />

                <div class="small-event-box-content">
                  <div class="small-event-box-content-description">
                    <span class="small-event-name">{{ event.event.name }}</span>
                    <span class="small-event-date">{{
                      event.event.startDate | date: "EEEE, d 'de' MMMM 'de' YYYY 'às' HH:mm" : '-0300' : 'pt-BR'
                      }}</span>
                  </div>
                  <span class="small-event-participation-icon">
                    <i *ngIf="event.participationStatus === 'PARTICIPANDO'" matTooltip="Participando"
                      class="bi bi-check-lg small-event-participation-icon-green"></i>
                    <i *ngIf="event.participationStatus === 'PENDENTE_APROVACAO'" matTooltip="Aguardando Aprovação"
                      class="bi bi-hourglass-split small-event-participation-icon-yellow"></i>
                  </span>
                </div>
              </div>

              <div class="no-items-container" *ngIf="nextEvents.length < 1">
                <span class="no-items-label">Nenhuma evento encontrado</span>
              </div>
            </div>
          </div>

          <div class="own-event-item-content">
            <div class="own-event-item-title-container">
              <span class="own-event-item-title" translate>events.myEvents</span>
            </div>

            <div class="own-event-item-list-container">
              <div class="small-event-box" *ngFor="let event of ownerUserEvents" (click)="handleEventClick(event)">
                <img class="small-event-image" [src]="event.imageLink" alt="my event image" />

                <div class="small-event-box-content">
                  <div class="small-event-box-content-description">
                    <span class="small-event-name">{{ event.name }}</span>
                    <span class="small-event-date">{{
                      event.startDate | date: "EEEE, d 'de' MMMM 'de' YYYY 'às' HH:mm" : '-0300' : 'pt-BR'
                      }}</span>
                  </div>
                </div>
              </div>

              <div class="no-items-container" *ngIf="ownerUserEvents.length < 1">
                <span class="no-items-label">Nenhuma evento encontrado</span>
              </div>
            </div>
          </div>

          <div class="own-event-item-content">
            <div class="own-event-item-title-container">
              <span class="own-event-item-title" translate>events.connections</span>

              <span (click)="changeMenu.emit('sent-contacts')" class="see-more" translate>events.seeMore</span>
            </div>

            <div class="own-event-item-list-container"
              [ngClass]="!(documentUser && documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && !(userProfilePlan.profilePlanLimits.connectContactsView > 0) ? 'hidden' : ''">
              <div style="position: relative;" *ngFor="let connection of userConnections; let i = index">
                <div class="connection-box" (click)="openConnectionProfile(connection)"
                  [ngClass]="!(documentUser && documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && (userProfilePlan.profilePlanLimits.connectContactsView > 0 && i >= userProfilePlan.profilePlanLimits.connectContactsView) ? 'hidden' : ''">
                  <img class="connection-image"
                    [src]="connection.connection.uriImageProfile ? connection.connection.uriImageProfile : defaultImageProfile"
                    alt="my event image" />

                  <div class="connection-box-content">
                    <span class="connection-name">{{ connection.connection.name }}</span>
                    <span class="connection-from" *ngIf="connection.event">Conectado pelo <b>{{
                        connection.event.name}}</b></span>
                  </div>
                </div>
                <a class="no-connection-view" matTooltip="Para visualizar mais conexões, faça o upgrade do seu plano"
                  target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
                  matTooltipClass="mat-tooltip"
                  *ngIf="!(documentUser && documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && (userProfilePlan.profilePlanLimits.connectContactsView > 0 && i >= userProfilePlan.profilePlanLimits.connectContactsView)">
                  <i class="bi bi-lock-fill"></i>
                </a>
              </div>

              <div class="no-items-container" *ngIf="userConnections.length < 1">
                <span class="no-items-label">Nenhuma conexāo encontrada</span>
              </div>
            </div>
            <a target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
              class="no-connections-view-button"
              *ngIf="!(documentUser && documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContactsView !== undefined && !(userProfilePlan.profilePlanLimits.connectContactsView > 0)">
              <i class="bi bi-lock-fill"></i>

              <div class="no-dash-graph-messages">
                Para visualizar suas conexões,
                <a target="_blank"
                  href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal">faça
                  upgrade do seu plano</a>
              </div>
            </a>
          </div>
        </section>

        <section class="explore-section">
          <div class="filters-container">
            <div class="input-group inputs event-date-field-container">
              <button class="btn btn-outline-secondary input-prepend date-field-trigger" (click)="d1.toggle()">
                <i class="bi bi-calendar3"></i>
              </button>

              <input [(ngModel)]="filterFields.date" class="form-control date-picker" id="date" name="date"
                placeholder="dd/mm/yyyy" ngbDatepicker (dateSelect)="handleFilterDateChange($event)"
                (input)="formatDate($event)" #d1="ngbDatepicker" />
            </div>

            <div class="input-group event-type-input-container">
              <div class="input-group-prepend event-type-field-prepend">
                <i class="bi bi-border-style"></i>
              </div>

              <select [(ngModel)]="filterFields.eventType" (change)="handleFilterEventTypeChange($event)"
                class="form-select event-type-field">
                <option value="void">Tipo de evento</option>
                <option value="PRESENCIAL">Presencial</option>
                <option value="REMOTO">Online</option>
              </select>
            </div>
          </div>

          <div class="events-list-by-day-container">
            <div class="no-events-container" *ngIf="filteredCommunityEvents.length < 1">
              <span class="no-events-label">Nenhum evento encontrado</span>
            </div>

            <div class="day-container" *ngFor="let eventDay of filteredCommunityEvents">
              <div class="day-indicator-container">
                <span class="day-label">{{ eventDay.date | date: "EEEE, d 'de' MMMM" : '-0300' : 'pt-BR' }}</span>
              </div>

              <div *ngFor="let event of eventDay.events" (click)="handleEventClick(event)" class="event-box">
                <img [src]="event.imageLink" alt="event-image" class="event-image" />

                <div class="event-infos-container">
                  <span class="event-date">{{ event.startDate | date: "EEEE, d 'de' MMMM 'de' YYYY 'às' HH:mm" : '-0300'
                    :
                    'pt-BR' }}</span>
                  <span class="event-name">{{ event.name }}</span>
                  <div class="event-description" [innerHTML]="event.description">{{ event.description }}</div>
                  <span class="event-location" *ngIf="event.address && event.city">{{ event.address + ', ' + event.city
                    }}</span>
                  <div class="bottom-infos">
                    <span class="event-participants-count">{{ event.numberOfParticipants + ' participantes' }}</span>

                    <div class="event-actions-buttons">
                      <button class="action-small-button" (click)="handleShareEvent($event, event)">
                        <i class="bi-share"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="events-list-container" *ngIf="!loadingEvents && content === 'event-list' && selectedTab === 1">
      <header class="title-container">
        <section class="title-section">
          <h1 class="title" translate>communitys.title</h1>
          <h2 class="subtitle" translate>communitys.subtitle</h2>
        </section>

        <button class="new-event-button" (click)="openCreateCommunityModal()"
          *ngIf="(documentUser && documentUser.role === 'adm') || userProfilePlan.profilePlanLimits.includeEvents">Criar
          Comunidade</button>

        <a target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
          class="no-dash-graph-access-button"
          *ngIf="!(documentUser && documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.includeEvents !== undefined && !userProfilePlan.profilePlanLimits.includeEvents">
          <i class="bi bi-lock-fill"></i>

          <div class="no-dash-graph-messages">
            Para criar novas comunidades,
            <a target="_blank"
              href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal">faça
              upgrade do seu plano</a>
          </div>
        </a>
      </header>

      <div class="content-container">
        <section class="own-event-items-section">
          <div class="own-event-item-content">
            <div class="own-event-item-title-container">
              <span class="own-event-item-title" translate>communitys.nextCommunitys</span>
            </div>

            <div class="own-event-item-list-container">
              <div class="small-event-box" *ngFor="let event of nextCommunitys" (click)="handleEventClick(event.event)">
                <img class="small-event-image" [src]="event.event.imageLink" alt="my event image" />
                <div class="small-event-box-content">
                  <div class="small-event-box-content-description">
                    <span class="small-event-name">{{ event.event.name }}</span>
                    <span class="small-event-date">{{
                      event.event.startDate | date: "EEEE, d 'de' MMMM 'de' YYYY 'às' HH:mm" : '-0300' : 'pt-BR'
                      }}</span>
                  </div>
                  <span class="small-event-participation-icon">
                    <i *ngIf="event.participationStatus === 'PARTICIPANDO'" matTooltip="Participando"
                      class="bi bi-check-lg small-event-participation-icon-green"></i>
                    <i *ngIf="event.participationStatus === 'PENDENTE_APROVACAO'" matTooltip="Aguardando Aprovação"
                      class="bi bi-hourglass-split small-event-participation-icon-yellow"></i>
                  </span>
                </div>
              </div>

              <div class="no-items-container" *ngIf="nextCommunitys.length < 1">
                <span class="no-items-label">Nenhuma comunidade encontrada</span>
              </div>
            </div>
          </div>

          <div class="own-event-item-content">
            <div class="own-event-item-title-container">
              <span class="own-event-item-title" translate>communitys.myCommunitys</span>
            </div>

            <div class="own-event-item-list-container">
              <div class="small-event-box" *ngFor="let event of ownerUserCommunitys" (click)="handleEventClick(event)">
                <img class="small-event-image" [src]="event.imageLink" alt="my event image" />

                <div class="small-event-box-content">
                  <div class="small-event-box-content-description">
                    <span class="small-event-name">{{ event.name }}</span>
                    <span class="small-event-date">{{
                      event.startDate | date: "EEEE, d 'de' MMMM 'de' YYYY 'às' HH:mm" : '-0300' : 'pt-BR'
                      }}</span>
                  </div>
                </div>
              </div>

              <div class="no-items-container" *ngIf="ownerUserCommunitys.length < 1">
                <span class="no-items-label">Nenhuma comunidade encontrado</span>
              </div>
            </div>
          </div>

          <div class="own-event-item-content">
            <div class="own-event-item-title-container">
              <span class="own-event-item-title" translate>communitys.connections</span>

              <span (click)="changeMenu.emit('sent-contacts')" class="see-more" translate>communitys.seeMore</span>
            </div>

            <div class="own-event-item-list-container"
              [ngClass]="!(documentUser && documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && !(userProfilePlan.profilePlanLimits.connectContactsView > 0) ? 'hidden' : ''">
              <div style="position: relative;" *ngFor="let connection of userConnections; let i = index">
                <div class="connection-box" (click)="openConnectionProfile(connection)"
                  [ngClass]="!(documentUser && documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && (userProfilePlan.profilePlanLimits.connectContactsView > 0 && i >= userProfilePlan.profilePlanLimits.connectContactsView) ? 'hidden' : ''">
                  <img class="connection-image"
                    [src]="connection.connection.uriImageProfile ? connection.connection.uriImageProfile : defaultImageProfile"
                    alt="my event image" />

                  <div class="connection-box-content">
                    <span class="connection-name">{{ connection.connection.name }}</span>
                    <span class="connection-from" *ngIf="connection.event">Conectado pelo <b>{{
                        connection.event.name}}</b></span>
                  </div>
                </div>
                <a class="no-connection-view" matTooltip="Para visualizar mais conexões, faça o upgrade do seu plano"
                  target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
                  matTooltipClass="mat-tooltip"
                  *ngIf="!(documentUser && documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && (userProfilePlan.profilePlanLimits.connectContactsView > 0 && i >= userProfilePlan.profilePlanLimits.connectContactsView)">
                  <i class="bi bi-lock-fill"></i>
                </a>
              </div>

              <div class="no-items-container" *ngIf="userConnections.length < 1">
                <span class="no-items-label">Nenhuma conexāo encontrada</span>
              </div>
            </div>
            <a target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
              class="no-connections-view-button"
              *ngIf="!(documentUser && documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContactsView !== undefined && !(userProfilePlan.profilePlanLimits.connectContactsView > 0)">
              <i class="bi bi-lock-fill"></i>

              <div class="no-dash-graph-messages">
                Para visualizar suas conexões,
                <a target="_blank"
                  href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal">faça
                  upgrade do seu plano</a>
              </div>
            </a>
          </div>
        </section>

        <section class="explore-community-section">
          <div class="no-events-container" *ngIf="allCommunitys.length < 1">
            <span class="no-events-label">Nenhuma comunidade encontrada</span>
          </div>

          <div class="own-event-item-content" *ngFor="let event of allCommunitys">
            <!-- <div class="day-container" *ngFor="let eventDay of filteredCommunityEvents"></div> -->
            <div (click)="handleEventClick(event)" class="community-box">
              <img [src]="event.imageLink" alt="event-image" class="event-image" />

              <div class="community-infos-container">
                <span class="event-name">{{ event.name }}</span>
                <span class="community-description" [innerHTML]="event.description">{{ event.description }}</span>
                <div class="community-bottom-infos">
                  <div class="event-actions-buttons">
                    <button class="action-small-button" (click)="handleShareEvent($event, event)">
                      <i class="bi-share"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</div>
