import {
  Component,
  Input,
  ViewChild
} from '@angular/core';
import { NgbActiveModal, NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import {
  Subject
} from 'rxjs';
import { ISluperPromotion } from 'src/app/user-profile/user-profile.service';

@Component({
  selector: 'app-coins-history-modal',
  templateUrl: './coins-history-modal.component.html',
  styleUrls: ['./coins-history-modal.component.css'],
})
export class SluperCoinsHistoryModalComponent {
  private _alert = new Subject<string>();
  alertMessage = '';
  alertType = '';
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert:
    | NgbAlert
    | undefined;

  @Input() promo: ISluperPromotion = {} as ISluperPromotion;
  formattedOperationType: any = {
    'ADD_CREDIT': 'Crédito adicionado',
    'REMOVE_CREDIT': 'Crédito removido',
    'ADD_USER_TO_PROMO': 'Usuário adicionado à promoção',
    'REMOVE_USER_FROM_PROMO': 'Usuário removido da promoção',
  }
  formattedOperationTypeColor: any = {
    ADD_CREDIT: '#10b981',
    REMOVE_CREDIT: '#f87171',
    ADD_USER_TO_PROMO: '#1083b9',
    REMOVE_USER_FROM_PROMO: '#b95110',
  }

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  onCloseClick() {
    this.activeModal.dismiss();
  }
}
