import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { firstValueFrom } from "rxjs";
import { EventsService } from "src/app/events/events.service";

@Component({
    selector: 'app-remove-connection',
    templateUrl: './remove-connection.component.html',
    styleUrls: ['./remove-connection.component.css'],
})
export class RemoveConnectionModalComponent {
    @Input() idUser = '';
    @Input() idConnection = '';
    @Input() nameConnection = '';

    execute = false;

    constructor(private activeModal: NgbActiveModal, private service: EventsService) { }

    async removeConnection() {
        if (this.idConnection !== '' && this.idUser !== '') {
            this.execute = true;
            try {
                await firstValueFrom(this.service.removeConnection(this.idUser, this.idConnection));
            } catch (error) {
                console.log(error);
                this.execute = false;
                return;
            }
            this.execute = false;
            this.closeModal(true);
        }
    }

    closeModal(status: boolean) {
        this.activeModal.close(status);
    }
}