import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppNavigateService } from 'src/app/app-navigate.service';
import { AuthService } from 'src/app/auth/auth.service';
import { CodigoVerificacaoComponent } from '../../core/components/codigo-verificacao/codigo-verificacao.component';
import { LoadingService } from '../../core/service/loading.service';
import { ToastService } from '../../core/service/toast.service';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @ViewChild(CodigoVerificacaoComponent) codigoVerificacao!: CodigoVerificacaoComponent;

  userEmail = new FormControl('', [Validators.required, Validators.email]);

  chaveAcesso = '';
  codigoError = '';

  foiEnviadoCodVerf: boolean = false;
  buttonLabelEntrar = 'Enviar código de verificação';
  constructor(
    private appNavigateService: AppNavigateService,
    private loginService: LoginService,
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
  ) {

  }
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const parametro = params['parametro'];
      if (parametro) {
        this.appNavigateService.navegarChaveAtivacao(parametro);
      }
      this.usuariologado();
    });

  }

  entrar() {
    if (this.foiEnviadoCodVerf == false && this.userEmail.valid) {
      this.validarEmail();
    } else {
      this.validarCodigo();
    }
  }

  validarEmail() {
    this.loadingService.show();
    this.loginService.login(String(this.userEmail.value)).subscribe({
      next: (response) => {
        if (response.status === 200 && response.body?.status === 'SUCCESS') {
          this.foiEnviadoCodVerf = true;
          this.buttonLabelEntrar = 'Entrar';
          this.loadingService.hide();
          this.toastService.showInfo('Código de verificação foi enviado para o seu email')
        }
      },
      error: (err) => {
        this.loadingService.hide();
        this.toastService.showError('O Email inserido não é válido')
      }
    });
  }

  validarCodigo() {
    this.loadingService.show();
    this.loginService.validateEmailKey(
      String(this.codigoVerificacao.getCodigoVerificacao()).toUpperCase(),
      this.userEmail.value).subscribe({
        next: (response) => {
          this.loadingService.hide();
          const chaveValida = response.body?.status === 'SUCCESS' ? response.body?.message : '';
          this.authService.login(chaveValida);
          this.router.navigateByUrl('user-profile');
        },
        error: (err) => {
          this.loadingService.hide();
          this.toastService.showError('Código de verificação inválida')

        }
      });
  }

  reenviarCodigo() {
    this.validarEmail();
  }

  navegarCadastro() {
    this.appNavigateService.navegarCadastro();
  }

  usuariologado() {
    if (this.authService.isAuthenticated()) {
      this.router.navigateByUrl('user-profile');
    }
  }
}
