import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-chave-ativacao-input',
  templateUrl: './chave-ativacao-input.component.html',
  styleUrls: ['./chave-ativacao-input.component.css']
})
export class ChaveAtivacaoInputComponent {
  @ViewChild('codigo1') codigo1!: ElementRef<HTMLInputElement>;
  @ViewChild('codigo2') codigo2!: ElementRef<HTMLInputElement>;
  @ViewChild('codigo3') codigo3!: ElementRef<HTMLInputElement>;
  @ViewChild('codigo4') codigo4!: ElementRef<HTMLInputElement>;
  @ViewChild('codigo5') codigo5!: ElementRef<HTMLInputElement>;
  @ViewChild('codigo6') codigo6!: ElementRef<HTMLInputElement>;
  @ViewChild('codigo7') codigo7!: ElementRef<HTMLInputElement>;
  @ViewChild('codigo8') codigo8!: ElementRef<HTMLInputElement>;

  inputs: HTMLInputElement[] = [];

  ngAfterViewInit() {
    this.inputs = [this.codigo1.nativeElement, this.codigo2.nativeElement, this.codigo3.nativeElement, this.codigo4.nativeElement,
    this.codigo5.nativeElement, this.codigo6.nativeElement, this.codigo7.nativeElement, this.codigo8.nativeElement];
  }

  onInput(event: Event, nextInput: HTMLInputElement | null): void {
    const input = event.target as HTMLInputElement;
    if (input.value.length === 1 && nextInput) {
      nextInput.focus();
    }
  }

  onKeydown(event: KeyboardEvent, prevInput: HTMLInputElement | null): void {
    if (event.key === 'Backspace' && !(event.target as HTMLInputElement).value && prevInput) {
      prevInput.focus();
    }
  }

  onPaste(event: ClipboardEvent, nextInput: HTMLInputElement | null): void {
    event.preventDefault();
    const pasteData = (event.clipboardData || (window as any).clipboardData).getData('text');
    pasteData.split('').forEach((char: string, index: number) => {
      if (this.inputs[index]) {
        this.inputs[index].value = char;
      }
    });

    // Focus no último campo preenchido
    const lastInput = this.inputs[Math.min(pasteData.length, this.inputs.length) - 1];
    lastInput.focus();
  }

  getCodigoVerificacao(): string {
    return this.inputs.map(input => input.value).join('');
  }
}
