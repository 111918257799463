<div class="modal-container">
  <div class="title-container">
    <h1 class="title">Avalie o Evento {{event.name}}</h1>
  </div>

  <ngb-rating [(rate)]="modelData.review">
    <ng-template let-fill="fill" let-index="index">
      <i class="bi-star{{ fill === 100 ? '-fill' : '' }}" [class.filled]="fill === 100" [class.low]="index < 3"></i>
    </ng-template>
  </ngb-rating>

  <div class="inputs-div">
    <div class="input-container">
      <label for="comentario" class="biografia-text" translate>Comentário</label>

      <div class="input-group biografia-input">
        <div class="input-group-prepend">
          <span class="input-group-text perfil-input-prepend" id="basic-addon1" style="height: 64px"><i
              class="bi bi-pencil"></i></span>
        </div>

        <textarea class="form-control perfil-input-text biography-textarea" [(ngModel)]="modelData.comment"
          id="comentario" name="comentario" #commentInput="ngModel" maxlength="200" required></textarea>
      </div>
      <div *ngIf="commentTouched && (!modelData.comment || modelData.comment.trim().length === 0)" class="text-danger">
        O comentário é obrigatório.
      </div>
    </div>
  </div>

  <div class="buttons-container">
    <button  class="update-plan-button" (click)="sendNPS()">
      Avaliar
    </button>

    <button (click)="closeModal(false)" class="skip-button">Mais tarde</button>
  </div>
</div>
