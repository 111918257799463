/* eslint-disable no-useless-escape */
import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DndDropEvent } from 'ngx-drag-drop';
import { BOLD_BUTTON, EditorConfig, FONT_SIZE_SELECT, FORE_COLOR, ITALIC_BUTTON, SEPARATOR, UNDO_BUTTON } from 'ngx-simple-text-editor';
import { Subject, debounceTime, filter, map } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';
import dataURItoBlob from '../helpers/dataUriToBlob';
import { IButton } from '../model/button.model';
import { LISTA_ESTILOS } from '../model/estilos';
import { LISTA_PAIS } from '../model/pais';
import { IFormattedProfileData } from '../model/profile.model';
import { LISTA_TIPOS_CONTATOS } from '../model/tipos-contatos';
import { IUserCss, UserCss } from '../model/user-css.model';
import { IUserProfilePlan } from '../model/user-plan.model';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import { PreviewModalComponent } from '../preview-modal/preview-modal.component';
import { UserProfileService } from '../user-profile/user-profile.service';
import { LISTA_REDES } from './../model/redes';
import { CropLinkImageModalComponent } from './crop-link-images-modal/crop-link-images-modal.component';
import { ExceedPlanResourcesModalComponent } from './exceed-plan-resources/exceeded-plan-resources.component';

interface ISelectedProfile {
  indicator: number;
  data: IFormattedProfileData;
}

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css'],
  animations: [
    trigger('slideDownAnimation', [
      state('visible', style({
        opacity: 1,
        transform: 'translateY(0)',
      })),
      state('hidden', style({
        display: 'none',
        opacity: 0,
        transform: 'translateY(-30px)',
      })),
      transition('visible <=> hidden', animate('500ms ease-in-out')),
    ]),
  ],
})
export class LinksComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert:
    | NgbAlert
    | undefined;

  @Input() userProfile!: IUserProfileWithCSS | null;
  @Input() userProfilePlan!: IUserProfilePlan;
  @Output() linkRequired = new EventEmitter<boolean>();

  private _alert = new Subject<string>();
  alertMessage = '';
  alertType = '';

  saveProfileLoading = false;

  redes = LISTA_REDES;
  tiposContatos = LISTA_TIPOS_CONTATOS;
  paises = LISTA_PAIS;

  userCss: IUserCss = new UserCss();
  content = '';
  config: EditorConfig = {
    placeholder: 'Type something...',
    buttons: [UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, FORE_COLOR, FONT_SIZE_SELECT,],
  };
  defaultProfileImage = '/assets/img/imagem_perfil.png';
  defaultCallingCardImage = '/assets/img/imagem_fundo_preview.png';

  externalButtonLink = false;
  selectedButtonLinkToConfigImage = '';

  desktopLimitWidth = 840;
  desktopLimitHeight = 550;

  screenWidth = 0;
  screenHeight = 0;

  previewModalOpen = false;
  userPlanStatus = {
    someAttributeExceeded: false,
    linksExceeded: false,
    contactsExceeded: false,
    socialNetworksExceeded: false,
  };

  haveMoreThanOneProfile = false;
  selectedProfile!: ISelectedProfile;

  constructor(
    private userProfileService: UserProfileService,
    private modalService: NgbModal,
  ) {
    this.getScreenSize();
  }

  changeSelectedProfile(profileIndicator: number) {
    if (!this.userProfile) {
      return;
    }

    const isDefaultProfile = profileIndicator === -1;

    const templateCallingCard = this.userCss.callingCardImageUrl;

    if (isDefaultProfile) {
      let callingCardImage = this.userProfile.documentUserProfile.uriImageBackground;

      if (this.userProfile.documentUserCSS.locked && templateCallingCard) {
        callingCardImage = templateCallingCard;
      }

      if (!callingCardImage) {
        callingCardImage = this.defaultCallingCardImage;
      }

      this.selectedProfile = {
        indicator: profileIndicator,
        data: {
          profileVisible: true,
          bio: this.userProfile.documentUserProfile.bio,
          header: this.userProfile.documentUserProfile.header,
          listButtons: this.userProfile.documentUserProfile.listButtons.map((button, index) => ({
            ...button,
            ...(button.image && ({
              image: button.image.replaceAll(' ', '%20')
            })),
            id: uuidV4(),
            suspended: this.userProfilePlan?.profilePlanLimits.linksInclude !== -1 && this.userProfilePlan?.profilePlanLimits.linksInclude <= index,
          })),
          listContacts: this.userProfile.documentUserProfile.listContacts.map((c, index) => ({
            ...c,
            pais: c.pais !== undefined ? this.paises.find(p => p.sigla === c.pais?.sigla || '') : c.pais,
            suspended: this.userProfilePlan?.profilePlanLimits.contactsInclude !== -1 && this.userProfilePlan?.profilePlanLimits.contactsInclude <= index
          })),
          listSocialNetwork: this.userProfile.documentUserProfile.listSocialNetwork.map((network, index) => ({ ...network, readOnly: true, suspended: this.userProfilePlan.profilePlanLimits.socialLinksInclude !== -1 && this.userProfilePlan.profilePlanLimits.socialLinksInclude <= index })),
          name: this.userProfile.documentUserProfile.name,
          showImageProfile: this.userProfile.documentUserProfile.showImageProfile,
          uriImageBackground: callingCardImage,
          uriImageProfile: this.userProfile.documentUserProfile.uriImageProfile || this.defaultProfileImage,
        }
      }

      return;
    }

    const selectedProfileData = this.userProfile.documentUserProfile.listProfile[profileIndicator]

    if (!selectedProfileData) {
      return
    }

    let callingCardImage = selectedProfileData.uriImageBackground;

    if (this.userProfile.documentUserCSS.locked && templateCallingCard) {
      callingCardImage = templateCallingCard;
    }

    if (!callingCardImage) {
      callingCardImage = this.defaultCallingCardImage;
    }

    this.selectedProfile = {
      data: {
        ...selectedProfileData,
        profileVisible: selectedProfileData.visible === undefined ? true : selectedProfileData.visible,
        listButtons: selectedProfileData.listButtons.map((button, index) => ({
          ...button,
          ...(button.image && ({
            image: button.image.replaceAll(' ', '%20')
          })),
          id: uuidV4(),
          suspended: this.userProfilePlan?.profilePlanLimits.contactsInclude !== -1 && this.userProfilePlan?.profilePlanLimits.contactsInclude <= index,
        })),
        listContacts: selectedProfileData.listContacts.map((c, index) => ({
          ...c,
          pais: c.pais !== undefined ? this.paises.find(p => p.sigla === c.pais?.sigla || '') : c.pais,
          suspended: this.userProfilePlan?.profilePlanLimits.contactsInclude !== -1 && this.userProfilePlan?.profilePlanLimits.contactsInclude <= index
        })),
        listSocialNetwork: selectedProfileData.listSocialNetwork.map((network, index) => ({ ...network, readOnly: true, suspended: this.userProfilePlan.profilePlanLimits.linksInclude !== -1 && this.userProfilePlan.profilePlanLimits.linksInclude <= index })),
        uriImageBackground: callingCardImage,
        uriImageProfile: selectedProfileData.uriImageProfile || this.defaultProfileImage,
      },
      indicator: profileIndicator
    }
  }

  updateProfilePreviewWidth() {
    const parent = document.querySelector('.visual-div');
    const child = document.querySelector('.profile-preview') as HTMLElement;

    if (parent && child) {
      const rect = parent.getBoundingClientRect();

      child.style.width = `${rect.width}px`;
    }
  }

  fillCss(css: string) {
    if (css) this.userCss = JSON.parse(css);
    else this.getStyle(0);
  }

  fillUserPlanStatus() {
    const isDefaultProfileWithExceededLinks = this.userProfilePlan.profilePlanLimits.linksInclude < (this.userProfile?.documentUserProfile.listButtons.length || 0);
    let isSomeProfileWithExceededLinks = false;

    if (this.userProfile?.documentUserProfile?.listProfile && this.userProfile?.documentUserProfile?.listProfile.length > 0) {
      isSomeProfileWithExceededLinks = this.userProfile?.documentUserProfile?.listProfile.some(profile => this.userProfilePlan.profilePlanLimits.linksInclude < profile.listButtons.length)
    }

    const isDefaultProfileWithExceededContacts = this.userProfilePlan.profilePlanLimits.contactsInclude < (this.userProfile?.documentUserProfile.listContacts.length || 0);
    let isSomeProfileWithExceededContacts = false;

    if (this.userProfile?.documentUserProfile?.listProfile && this.userProfile?.documentUserProfile?.listProfile.length > 0) {
      isSomeProfileWithExceededContacts = this.userProfile?.documentUserProfile?.listProfile.some(profile => this.userProfilePlan.profilePlanLimits.contactsInclude < profile.listContacts.length)
    }

    const isDefaultProfileWithExceededSocialLinks = this.userProfilePlan.profilePlanLimits.socialLinksInclude < (this.userProfile?.documentUserProfile.listSocialNetwork.length || 0);
    let isSomeProfileWithExceededSocialLinks = false;

    if (this.userProfile?.documentUserProfile?.listProfile && this.userProfile?.documentUserProfile?.listProfile.length > 0) {
      isSomeProfileWithExceededSocialLinks = this.userProfile?.documentUserProfile?.listProfile.some(profile => this.userProfilePlan.profilePlanLimits.socialLinksInclude < profile.listSocialNetwork.length)
    }

    if (this.userProfilePlan.profilePlanLimits.linksInclude !== -1 && (isDefaultProfileWithExceededLinks || isSomeProfileWithExceededLinks)) {
      this.userPlanStatus.linksExceeded = true;
      this.userPlanStatus.someAttributeExceeded = true;
    }

    if (this.userProfilePlan.profilePlanLimits.contactsInclude !== -1 && (isDefaultProfileWithExceededContacts || isSomeProfileWithExceededContacts)) {
      this.userPlanStatus.contactsExceeded = true;
      this.userPlanStatus.someAttributeExceeded = true;
    }

    if (this.userProfilePlan.profilePlanLimits.socialLinksInclude !== -1 && (isDefaultProfileWithExceededSocialLinks || isSomeProfileWithExceededSocialLinks)) {
      this.userPlanStatus.socialNetworksExceeded = true;
      this.userPlanStatus.someAttributeExceeded = true;
    }
  }

  ngOnInit(): void {
    this.changeSelectedProfile(-1)
    setTimeout(() => {
      this.updateProfilePreviewWidth();
    }, 300)

    if (this.userProfile) {
      this.fillCss(this.userProfile.documentUserCSS.css);
      this.fillUserPlanStatus();

      this.haveMoreThanOneProfile = this.userProfile.documentUserProfile?.listProfile?.length > 0;

      if (this.userPlanStatus.someAttributeExceeded) {
        this.openExceedePlanResources();
      }
    }

    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  handleSelectButtonLayout({ buttonId, selectedLayout }: { buttonId: string; selectedLayout: 'CLASSIC' | 'HIGHLIGHTED' }) {
    const buttonLink = this.selectedProfile.data.listButtons.find((b) => b.id === buttonId);

    if (!buttonLink) {
      return;
    }

    buttonLink.layout = selectedLayout;
  }

  changeNetworkType(networkType: string, networkIndex: number) {
    this.selectedProfile.data.listSocialNetwork[networkIndex].type = networkType;
    this.selectedProfile.data.listSocialNetwork[networkIndex].link = '';
  }

  handleKeyDownSocialNetwork(event: any, networkType: string) {
    const value = (event.target as HTMLInputElement).value;

    if (!value.includes('/') && networkType === 'whatsapp') {
      (event.target as HTMLInputElement).value = `https://wa.me/${value}`;
    }

    if (!value.includes('/') && networkType === 'instagram') {
      (event.target as HTMLInputElement).value = `https://instagram.com/${value}`;
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    this.updateProfilePreviewWidth();
  }

  removeSocialNetwork(i: number) {
    this.selectedProfile.data.listSocialNetwork.splice(i, 1);

    this.selectedProfile.data.listSocialNetwork = this.selectedProfile.data.listSocialNetwork.map((network, index) => ({ ...network, readOnly: true, suspended: this.userProfilePlan.profilePlanLimits.linksInclude !== -1 && this.userProfilePlan.profilePlanLimits.linksInclude <= index }));
  }

  addSocialNetwork() {
    this.selectedProfile.data.listSocialNetwork.unshift({ type: '', link: '', readOnly: false, suspended: false });
  }

  getPlaceholder(rede: string) {
    return rede ? this.redes.find((r) => r.value == rede)?.placeHolder : '';
  }

  changeButtonVisible(i: number) {
    this.selectedProfile.data.listButtons[i].visible = !this.selectedProfile.data.listButtons[i].visible;
  }

  removeLink(i: number) {
    this.selectedProfile.data.listButtons.splice(i, 1);
    this.selectedProfile.data.listButtons = this.selectedProfile.data.listButtons.map((button, index) => ({
      ...button,
      ...(button.image && ({
        image: button.image.replaceAll(' ', '%20')
      })),
      suspended: this.userProfilePlan?.profilePlanLimits.contactsInclude !== -1 && this.userProfilePlan?.profilePlanLimits.contactsInclude <= index,
    }));

    this.linkRequired.emit(false);
  }

  addLink() {
    this.selectedProfile.data.listButtons.push({ title: '', link: '', visible: true, id: uuidV4(), suspended: false });
    this.linkRequired.emit(true);
  }

  addContact() {
    this.selectedProfile.data.listContacts.push({ type: '', value: '', suspended: false });
  }

  removeContact(i: number) {
    this.selectedProfile.data.listContacts.splice(i, 1);

    this.selectedProfile.data.listContacts = this.selectedProfile.data.listContacts.map((c, index) => ({
      ...c,
      pais: c.pais !== undefined ? this.paises.find(p => p.sigla === c.pais?.sigla || '') : c.pais,
      suspended: this.userProfilePlan?.profilePlanLimits.contactsInclude !== -1 && this.userProfilePlan?.profilePlanLimits.contactsInclude <= index
    }));
  }

  changePhoneCountry(i: number) {
    this.selectedProfile.data.listContacts[i].value = '';
  }

  fillLink(link: IButton) {
    if (link.link && link.title) {
      this.linkRequired.emit(false);
    }
  }

  getStyle(i: number) {
    this.userCss.estiloPreDefinido = LISTA_ESTILOS[i];
    this.userCss.corPrimaria = this.userCss.estiloPreDefinido.externo.corPrincipal;
    this.userCss.corSecundaria = this.userCss.estiloPreDefinido.externo.corSecundaria;
    this.userCss.direcaoDegrade = this.userCss.estiloPreDefinido.externo.direcaoDegrade;
    this.userCss.preenchimento = this.userCss.estiloPreDefinido.interno.background;
    this.userCss.contorno = this.userCss.estiloPreDefinido.interno.borderColor;
    this.userCss.curvaBorda = this.userCss.estiloPreDefinido.interno.borderRadius;
    this.userCss.espessura = this.userCss.estiloPreDefinido.interno.borderWidth;
  }

  onDrop(event: DndDropEvent, list?: any[]) {
    const sourceId = event.data.id;
    const { id: targetButtonId } = event.event.target as Element;


    if (targetButtonId && sourceId && list) {
      const sourceIndex = list.findIndex((b) => b.id == sourceId);
      const targetIndex = list.findIndex((b) => b.id == targetButtonId);
      const sourceItem = list.find((b) => b.id == sourceId);
      const targetItem = list.find((b) => b.id == targetButtonId);

      list[sourceIndex] = targetItem;
      list[targetIndex] = sourceItem;
    }
  }

  changeLinkOrder(linkIndex: number, move: 'move_up' | 'move_down') {
    const list = this.selectedProfile.data.listButtons;
    const targetIndex = move === 'move_up' ? linkIndex - 1 : linkIndex + 1;

    if (targetIndex < 0 || targetIndex >= list.length) {
      return;
    }

    const sourceItem = list[linkIndex];
    const targetItem = list[targetIndex];

    list[linkIndex] = targetItem;
    list[targetIndex] = sourceItem;
  }

  handleFillVideoLink(event: any, buttonId: string) {
    const url = event.target.value;

    const youtubeShortsRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/shorts\/)([a-zA-Z0-9_-]{11})/;

    const isYoutubeShortsUrl = youtubeShortsRegex.test(url);

    if (isYoutubeShortsUrl) {
      const [, endPartUrl] = url.split('/shorts/')

      const videoId = endPartUrl.slice(0, 11);

      const formattedLink = `https://www.youtube.com/embed/${videoId}`;

      const buttonLink = this.selectedProfile.data.listButtons.find(b => b.id === buttonId);

      if (buttonLink) {
        buttonLink.link = formattedLink;
      }

      event.target.value = formattedLink;
    }

    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

    const isYoutubeUrl = youtubeRegex.test(url);

    if (!isYoutubeUrl) {
      return;
    }

    const videoId = url.match(youtubeRegex)[4];
    const formattedLink = `https://www.youtube.com/embed/${videoId}`;

    const buttonLink = this.selectedProfile.data.listButtons.find(b => b.id === buttonId);

    if (buttonLink) {
      buttonLink.link = formattedLink;
    }

    event.target.value = formattedLink;
  }

  save() {
    if (this.userProfile) {
      this.saveProfileLoading = true;
      let saveAllowed = true;

      const isDefaultProfile = this.selectedProfile.indicator === -1;

      if (isDefaultProfile) {
        this.userProfile.documentUserProfile.header = this.selectedProfile.data.header;
        this.userProfile.documentUserProfile.listButtons = this.selectedProfile.data.listButtons;
        this.userProfile.documentUserProfile.listContacts = this.selectedProfile.data.listContacts;
        this.userProfile.documentUserProfile.listSocialNetwork = this.selectedProfile.data.listSocialNetwork;
      } else {
        const indexProfileToUpdate = this.selectedProfile.indicator;

        this.userProfile.documentUserProfile.listProfile[indexProfileToUpdate].visible = this.selectedProfile.data.profileVisible;
        this.userProfile.documentUserProfile.listProfile[indexProfileToUpdate].header = this.selectedProfile.data.header;
        this.userProfile.documentUserProfile.listProfile[indexProfileToUpdate].listButtons = this.selectedProfile.data.listButtons;
        this.userProfile.documentUserProfile.listProfile[indexProfileToUpdate].listContacts = this.selectedProfile.data.listContacts;
        this.userProfile.documentUserProfile.listProfile[indexProfileToUpdate].listSocialNetwork = this.selectedProfile.data.listSocialNetwork;
      }

      this.userProfile.documentUserProfile.listContacts.forEach((c) => {
        if (
          c.type == '' ||
          (c.type != '' && c.value == '') ||
          (c.type == 'telefone' &&
            c.value != '' &&
            (c.pais == null || c.pais == undefined))
        ) {
          this.alertType = 'danger';
          this._alert.next('Favor preencher o contato completo.');

          this.saveProfileLoading = false;

          saveAllowed = false;

          return;
        }
      });

      this.userProfile.documentUserProfile.listButtons.forEach((b) => {
        if (
          ((b.title == null ||
            b.title == '') && b.type !== 'VIDEO') || (b.type === 'PRODUCT' && (b.currency === undefined || b.productValue === undefined)) ||
          b.link === null ||
          b.link === ''
        ) {
          this.alertType = 'danger';
          this._alert.next('Há um ou mais links pendentes de preenchimento.');
          saveAllowed = false;
          this.linkRequired.emit(false);
          this.saveProfileLoading = false;

          return;
        }
      });

      if (saveAllowed) {
        this.userProfileService
          .saveDocumentUserProfile(this.userProfile.documentUserProfile)
          .pipe(
            filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
            map((response: HttpResponse<any>) => response.body),
          )
          .subscribe({
            next: () => {
              this.alertType = 'success';
              this.linkRequired.emit(false);
              this._alert.next('Perfil salvo com sucesso.');

              this.saveProfileLoading = false;
            },
            error: () => {
              this.alertType = 'danger';
              this._alert.next(
                'Não foi possível salvar o perfil. Contacte o administrador.',
              );

              this.saveProfileLoading = false;
            },
          });
      }
    }
  }

  openPreviewModal() {
    if (this.screenWidth < this.desktopLimitWidth || this.screenHeight < this.desktopLimitHeight) {
      const modalRef = this.modalService.open(PreviewModalComponent, {
        fullscreen: true,
      });
      modalRef.componentInstance.userCss = this.userCss;
      modalRef.componentInstance.profile = this.selectedProfile.data;
      modalRef.componentInstance.userProfile = this.userProfile;
      modalRef.componentInstance.userProfilePlan = this.userProfilePlan;
      modalRef.componentInstance.botoes = this.selectedProfile.data.listButtons;
      modalRef.componentInstance.showImageProfile = this.selectedProfile.data.showImageProfile;
      this.previewModalOpen = true;

      modalRef.result.then(() => {
        this.previewModalOpen = false;
      });
    }
  }

  openExceedePlanResources() {
    const modalRef = this.modalService.open(ExceedPlanResourcesModalComponent, {
      fullscreen: false,
      centered: true,
    });

    modalRef.componentInstance.descriptionMessage = "Você ultrapassou os limites do seu plano atual para links, redes sociais ou selectedProfile.data.listContacts. Esses itens ficarāo ocultados e marcados com um ícone de alerta ⚠️. até que o plano seja atualizado."
    modalRef.componentInstance.callToAction = "Não fique invisível! Atualize agora e mantenha seu perfil completo."
  }

  handleSelectedButtonLinkToConfigImage(id: string) {
    const linkIndex = this.selectedProfile.data.listButtons.findIndex((b) => b.id === id);

    if (this.selectedButtonLinkToConfigImage === id) {
      this.selectedProfile.data.listButtons[linkIndex].layout = undefined;
      this.selectedButtonLinkToConfigImage = '';

      return;
    }

    if (!this.selectedProfile.data.listButtons[linkIndex].layout) {
      this.selectedProfile.data.listButtons[linkIndex].layout = 'CLASSIC';
    }
    this.selectedButtonLinkToConfigImage = id;
  }

  closeSelectionButtonImage() {
    this.selectedButtonLinkToConfigImage = '';
  }

  toggleExternalButtonLink() {
    this.externalButtonLink = !this.externalButtonLink;
  }

  async handleUploadButtonLink(_event: any, buttonLinkId: string) {
    const modalRef = this.modalService.open(CropLinkImageModalComponent, {
      fullscreen: false,
    });

    modalRef.componentInstance.confirmImageCrop = (croppedImageUrl: string, fileName: string) => this.handleButtonLinkImageChanged(croppedImageUrl, fileName, buttonLinkId);
    modalRef.componentInstance.cropImageInfos = {
      aspectRatio: 121 / 103,
      maxWidth: 100000,
      maxHeight: 100000,
      minWidth: 1,
      minHeight: 1,
    };
  }

  changeExternalUrl(event: any, buttonLinkId: string) {
    const externalUrl = event.target.value;

    if (this.userProfile) {
      const buttonLink = this.selectedProfile.data.listButtons.find(b => b.id === buttonLinkId);
      if (!buttonLink) {
        return;
      }

      buttonLink.image = externalUrl;
    }
  }

  handleChangeLinkType(linkType: string, linkId: string) {
    const buttonLink = this.selectedProfile.data.listButtons.find(b => b.id === linkId);

    if (!buttonLink) {
      return;
    }

    buttonLink.type = linkType;
  }

  handleButtonLinkImageChanged(croppedImageUrl: string, fileName: string, linkId: string) {
    const buttonLink = this.selectedProfile.data.listButtons.find(b => b.id === linkId);

    if (!this.userProfile || !buttonLink) {
      return;
    }

    const blob = dataURItoBlob(croppedImageUrl);

    const file = new File([blob], 'default_file_name', {
      type: 'image/jpeg',
      lastModified: Date.now(),
    });

    this.userProfileService
      .saveProfileImage(
        this.userProfile.documentUserProfile.idUser,
        `button_link_${linkId}`,
        file,
      )
      .subscribe({
        next: (response) => {
          buttonLink.image = response;
          buttonLink.layout = 'HIGHLIGHTED';

          this.modalService.dismissAll();
        },
        error: () => {
          this.alertType = 'danger';
          this._alert.next(
            'Não foi possível salvar o perfil. Upload de imagem de Profile com erro. Contacte o administrador.',
          );

          this.modalService.dismissAll();
        },
      });
  }

  handleOpenSelectLinkImageModal(linkId: string) {
    const modalRef = this.modalService.open(CropLinkImageModalComponent, {
      fullscreen: false,
    });

    modalRef.componentInstance.confirmImageCrop = (croppedImageUrl: string, fileName: string) => this.handleButtonLinkImageChanged(croppedImageUrl, fileName, linkId);
    modalRef.componentInstance.cropImageInfos = {
      aspectRatio: 121 / 103,
      maxWidth: 2000,
      maxHeight: 2000,
      minWidth: 1600,
      minHeight: 1300,
    };
  }

  toggleProfileVisibility(event: any) {
    event.stopPropagation();
    this.selectedProfile.data.profileVisible = !this.selectedProfile.data.profileVisible;
  }
}
