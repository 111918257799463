<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<app-loading-spinner [customBg]="'#fff'" *ngIf="loading"></app-loading-spinner>

<div class="background" [style.overflow-y]="mobileMenuOpened ? 'hidden' : 'auto'" *ngIf="!loading">
  <div class="nav-container">
    <img *ngIf="urlImagemHeader; else defaultLogo" [src]="urlImagemHeader" alt="Marca Personalizada" class="box-logo" (click)="mudouMenu('home')"/>
        <ng-template #defaultLogo>
          <img src="/assets/img/Marca Sluper Colorida.svg" alt="Marca Sluper" class="marca" (click)="mudouMenu('home')" />
        </ng-template>

    <div class="mobile-menu-button-container">
      <!-- <a *ngIf="userProfilePlan.planName"
        href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal" _target="blank"
        class="profile-plan-stamp mobile-stamp" [style]="getPlanStampStyles()">
        <span class="profile-plan-stamp-text">
          <i class="bi bi-collection"></i>
          {{ userProfilePlan.planName.toUpperCase() }}
        </span>
      </a> -->

      <button class="mobile-menu-button" (click)="toggleMobileMenu()" [hidden]="homeSelected">
        <i class="bi bi-list"></i>
      </button>
    </div>

    <div [ngClass]="mobileMenuOpened === true ? 'menu-buttons-opened' : 'menu-buttons'" ngbNav #nav="ngbNav"
      [activeId]="active" [hidden]="homeSelected">
      <ng-container ngbNavItem [ngbNavItem]="1">
        <button class="icons-button" ngbNavLink (click)="mudouMenu('home')" [disabled]="disableMenu">
          <i class="bi bi-house-door menu-icon-size"
            [ngClass]="homeSelected ? 'menu-icon-selected-color' : 'menu-icon-standard-color'"></i>
          <span class="menu-text" *ngIf="!homeSelected" translate>menu.home</span>
          <span class="selected-menu-text" *ngIf="homeSelected" translate>menu.home</span>
        </button>
        <ng-template ngbNavContent>
          <app-home [userProfile]="userProfile" [userProfilePlan]="userProfilePlan"
            (menuSelected)="handleHomeOutput($event)"></app-home>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem [ngbNavItem]="4">
        <button class="icons-button" ngbNavLink (click)="mudouMenu('opcoes')" [disabled]="disableMenu">
          <img src="/assets/img/page_info.svg" alt="Links" class="menu-icon" *ngIf="!opcoesSelected" />
          <img src="/assets/img/page_info_selected.svg" alt="Links" class="menu-icon" *ngIf="opcoesSelected" />
          <span class="menu-text" *ngIf="!opcoesSelected" translate>menu.conta</span>
          <span class="selected-menu-text" *ngIf="opcoesSelected" translate>menu.conta</span>
        </button>
        <ng-template ngbNavContent>
          <app-conta [userProfile]="userProfile" [preferences]="preferences" [occupations]="occupations"
            (linkRequired)="linkRequired($event)" (contaSaved)="contaSaved()"></app-conta>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem [ngbNavItem]="2">
        <button class="icons-button" ngbNavLink (click)="mudouMenu('aparencia')" [disabled]="disableMenu">
          <img src="/assets/img/browse.svg" alt="Aparência" class="menu-icon" *ngIf="!aparenciaSelected" />
          <img src="/assets/img/browse_select.svg" alt="Aparência" class="menu-icon" *ngIf="aparenciaSelected" />
          <span class="menu-text" *ngIf="!aparenciaSelected" translate>menu.meuSluper</span>
          <span class="selected-menu-text" *ngIf="aparenciaSelected" translate>menu.meuSluper</span>
        </button>
        <ng-template ngbNavContent>
          <app-aparencia [userProfilePlan]="userProfilePlan" [userProfile]="userProfile"></app-aparencia>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem [ngbNavItem]="3">
        <button class="icons-button" ngbNavLink (click)="mudouMenu('links')" [disabled]="disableMenu">
          <img src="/assets/img/add_link.svg" alt="Links" class="menu-icon" *ngIf="!linksSelected" />
          <img src="/assets/img/add_link_selected.svg" alt="Links" class="menu-icon" *ngIf="linksSelected" />
          <span class="menu-text" *ngIf="!linksSelected" translate>menu.links</span>
          <span class="selected-menu-text" *ngIf="linksSelected" translate>menu.links</span>
        </button>
        <ng-template ngbNavContent>
          <app-links [userProfilePlan]="userProfilePlan" [userProfile]="userProfile"
            (linkRequired)="linkRequired($event)"></app-links>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem [ngbNavItem]="6">
        <button class="icons-button" ngbNavLink (click)="mudouMenu('sent-contacts')" [disabled]="disableMenu">
          <img src="/assets/img/people_fill.svg" alt="SentContacts" class="menu-icon" *ngIf="!sentContactsSelected" />
          <img src="/assets/img/people_fill_selected.svg" alt="SentContacts" class="menu-icon"
            *ngIf="sentContactsSelected" />
          <span class="menu-text" *ngIf="!sentContactsSelected" translate>menu.sentContacts</span>
          <span class="selected-menu-text" *ngIf="sentContactsSelected" translate>menu.sentContacts</span>
        </button>
        <ng-template ngbNavContent>
          <app-contacts-and-connections [userProfile]="userProfile" [userProfilePlan]="userProfilePlan"
            [preferencesList]="preferences" [occupationsList]="occupations"
            (linkRequired)="linkRequired($event)"></app-contacts-and-connections>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem [ngbNavItem]="7">
        <button class="icons-button" ngbNavLink (click)="mudouMenu('dashboard')" [disabled]="disableMenu">
          <img src="/assets/img/bar-chart-fill.svg" alt="Dashboard" class="menu-icon" *ngIf="!dashboardSelected" />
          <img src="/assets/img/bar-chart-fill_selected.svg" alt="Dashboard" class="menu-icon"
            *ngIf="dashboardSelected" />
          <span class="menu-text" *ngIf="!dashboardSelected" translate>menu.dashboard</span>
          <span class="selected-menu-text" *ngIf="dashboardSelected" translate>menu.dashboard</span>
        </button>
        <ng-template ngbNavContent>
          <app-dashboard [userProfilePlan]="userProfilePlan" [userProfile]="userProfile"
            (linkRequired)="linkRequired($event)"></app-dashboard>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem [ngbNavItem]="8">
        <button class="icons-button" ngbNavLink (click)="mudouMenu('sales-dashboard')" [disabled]="disableMenu">
          <img src="/assets/img/speedometer.svg" alt="Sales Dashboard" class="menu-icon"
            *ngIf="!salesDashboardSelected" />
          <img src="/assets/img/speedometer_selected.svg" alt="Sales Dashboard" class="menu-icon"
            *ngIf="salesDashboardSelected" />
          <span class="menu-text" *ngIf="!salesDashboardSelected" translate>menu.salesDashboard</span>
          <span class="selected-menu-text" *ngIf="salesDashboardSelected" translate>menu.salesDashboard</span>
        </button>
        <ng-template ngbNavContent>
          <app-sales-dashboard [userProfile]="userProfile" (linkRequired)="linkRequired($event)"></app-sales-dashboard>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem [ngbNavItem]="9">
        <button class="icons-button" ngbNavLink (click)="mudouMenu('sluper-coins')" [disabled]="disableMenu">
          <img src="/assets/img/coin.svg" alt="Sluper Coins" class="menu-icon" *ngIf="!sluperCoinsSelected" />
          <img src="/assets/img/coin_selected.svg" alt="Sluper Coins" class="menu-icon" *ngIf="sluperCoinsSelected" />
          <span class="menu-text" *ngIf="!sluperCoinsSelected" translate>menu.sluperCoins</span>
          <span class="selected-menu-text" *ngIf="sluperCoinsSelected" translate>menu.sluperCoins</span>
        </button>
        <ng-template ngbNavContent>
          <app-sluper-coins (linkRequired)="linkRequired($event)"></app-sluper-coins>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem [ngbNavItem]="10">
        <button class="icons-button" ngbNavLink (click)="mudouMenu('events')" [disabled]="disableMenu">
          <img src="/assets/img/calendar-range.svg" alt="Sluper Coins" class="menu-icon" *ngIf="!eventsSelected" />
          <img src="/assets/img/calendar-range_selected.svg" alt="Sluper Coins" class="menu-icon"
            *ngIf="eventsSelected" />
          <span class="menu-text" *ngIf="!eventsSelected" translate>menu.events</span>
          <span class="selected-menu-text" *ngIf="eventsSelected" translate>menu.events</span>
        </button>
        <ng-template ngbNavContent>
          <app-events [userProfile]="userProfile?.documentUserProfile" [documentUser]="userProfile?.documentUser"
            [userProfilePlan]="userProfilePlan" (changeMenu)="mudouMenu($event)" [preferencesList]="preferences"
            [occupationsList]="occupations" (linkRequired)="linkRequired($event)"></app-events>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem [ngbNavItem]="12" *ngIf="userProfile?.documentAdmin">
        <button class="icons-button" ngbNavLink (click)="mudouMenu('business')" [disabled]="disableMenu">
          <i class="bi bi-briefcase menu-icon-size"
            [ngClass]="homeSelected ? 'menu-icon-selected-color' : 'menu-icon-standard-color'"></i>
          <span class="menu-text" *ngIf="!businessSelected" translate>menu.business</span>
          <span class="selected-menu-text" *ngIf="businessSelected" translate>menu.business</span>
        </button>
        <ng-template ngbNavContent>
          <app-business [userProfile]="userProfile"></app-business>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem [ngbNavItem]="13" *ngIf="userProfile?.documentAdmin">
        <button class="icons-button" ngbNavLink (click)="mudouMenu('business-templates')" [disabled]="disableMenu">
          <i class="bi bi-card-heading menu-icon-size"
            [ngClass]="homeSelected ? 'menu-icon-selected-color' : 'menu-icon-standard-color'"></i>
          <span class="menu-text" *ngIf="!corpTemplatesSelected" translate>menu.businessTemplates</span>
          <span class="selected-menu-text" *ngIf="corpTemplatesSelected" translate>menu.businessTemplates</span>
        </button>

        <ng-template ngbNavContent>
          <app-business-templates [adminPlan]="userAdminPlan" [userProfile]="userProfile"></app-business-templates>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem [ngbNavItem]="11">
        <button class="icons-button" ngbNavLink (click)="logout()">
          <img src="/assets/img/box-arrow-right.svg" alt="Sair" class="menu-icon" />
          <span class="menu-text" translate>menu.sair</span>
        </button>
      </ng-container>

      <!-- <a *ngIf="!mobileMenuOpened && userProfilePlan.planName"
        href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal" _target="blank"
        class="profile-plan-stamp" [style]="getPlanStampStyles()">
        <span class="profile-plan-stamp-text">
          <i class="bi bi-collection"></i>
          {{ userProfilePlan.planName.toUpperCase() }}
        </span>
      </a> -->
    </div>

    <div *ngIf="mobileMenuOpened === true" class="menu-overlay" (click)="toggleMobileMenu()"></div>
  </div>

  <div [ngbNavOutlet]="nav"></div>
  <!-- <div class="preview-button-div" *ngIf="screenWidth < 550">
        <button type="button" class="preview-button"><i class="bi bi-binoculars"></i>preview</button>
    </div> -->
</div>
