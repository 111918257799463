<div class="infos-box">
    <span class="info-titulo text-normal-32-700">Olá, {{nomeUsuario}} Seja bem-vindo a Sluper!</span>
    <span class="text-normal-20-500 text-center">Para começar, iremos pedir que você siga
        alguns passos
        para configurar a sua conta.</span>
    <span class="text-normal-16-500 text-center">
        Antes de progredir, pedimos para você ler os nossos
        <span class="termo-privacidade-btn" (click)="abrirTermosDeUso()">Termos de Uso </span>e
        <span class="termo-privacidade-btn" (click)="abrirPoliticaPrivacidade()">Política de Privacidade </span>
        antes de continuar.
    </span>
    <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [(ngModel)]="isChecked">
        <label class="form-check-label text-normal-14-400" for="flexCheckDefault">
            Ao clicar na caixa, você confirma ter lido os nossos Termos de Uso e Política de Privacidade.
        </label>
    </div>
    <button type="button" class="btn button-roxo-form" (click)="iniciar()" [disabled]="!isChecked">
        <span class="text-normal-16-700 text-white" translate>
            Iniciar
        </span>
    </button>
</div>