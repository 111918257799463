import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alterar-cor-fundo-modal',
  templateUrl: './alterar-cor-fundo-modal.component.html',
  styleUrls: ['./alterar-cor-fundo-modal.component.css']
})
export class AlterarCorFundoModalComponent {

  backgroundImage: string = '';
  backgroundImageFile: File | undefined;

  constructor(public activeModal: NgbActiveModal) { }

  // Trigger para abrir o seletor de arquivos
  triggerFileInput() {
    const fileInput = document.getElementById('backgroundImage') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  // Ao mudar a imagem de fundo
  onChangeBackgroundImage(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      this.backgroundImageFile = file;
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        this.backgroundImage = e.target?.result as string;
        this.submitBackground();
      };
      reader.readAsDataURL(file);
    }
  }

  // Submete as alterações do fundo e fecha o modal
  submitBackground() {
    this.activeModal.close({
      image: this.backgroundImage,
      file: this.backgroundImageFile
    });
  }

  selectBackgroundImage(event: Event) {
    const target = event.target as HTMLImageElement;
    if (target.src) {
      // Criar um Blob a partir da URL da imagem
      fetch(target.src)
        .then(response => response.blob())
        .then(blob => {
          // Criar um File a partir do Blob
          const file = new File([blob], 'selected-image.png', { type: blob.type });
          this.backgroundImageFile = file;

          // Usar um FileReader para ler o arquivo como uma URL de dados
          const reader = new FileReader();
          reader.onload = (e: ProgressEvent<FileReader>) => {
            this.backgroundImage = e.target?.result as string;
            this.submitBackground();
          };
          reader.readAsDataURL(file);
        })
        .catch(error => console.error('Erro ao carregar a imagem:', error));
    }
  }


  close() {
    this.activeModal.close();
  }
}
