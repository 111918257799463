<app-sluper-background></app-sluper-background>
<app-header-inicial [layoutColorido]="false"></app-header-inicial>
<div class="container">
    <div class="shadow-box">
        <div class="box-delimitadora text-center">
            <span class="text-normal-32-700 text-white" translate>Insira a chave de ativação</span>
        </div>
        <div class="form-div">
            <label for="verification" class="label-text-inicio" translate>Chave de ativação</label>
            <app-chave-ativacao-input></app-chave-ativacao-input>
        </div>

        <div class="form-div">
            <label for="nome-verificacao" class="label-text-inicio">Nome</label>
            <input type="nome" name="nome-verificacao" id="nome-verificacao" class="form-input"
                [(ngModel)]="this.cadastroStorageService.username" required />
        </div>

        <div class="form-div">
            <label for="email-verificacao" class="label-text-inicio">Email</label>
            <input type="email" name="email-verificacao" id="email-verificacao" class="form-input"
                [(ngModel)]="this.cadastroStorageService.email" required />
        </div>

        <button type="button" class="btn button-form" (click)="ativar()">
            <span class="text-normal-16-700 text-white" translate>
                Ativar
            </span>
        </button>

        <div class="text-box">
            <span class="text-normal-24-600 text-white text-center">Ainda não recebeu a chave?</span>
            <span class="text-normal-16-700 text-white text-reenviar-codigo" (click)="reenviarChave()">Reenviar chave de
                ativação</span>
        </div>
        <span class="text-normal-16-700 text-white">Problemas? <span class="text-roxo-ajuda"
                (click)="pedirAjuda()">Pedir Ajuda</span></span>

    </div>
</div>