import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { format } from 'date-fns';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BOLD_BUTTON, EditorConfig, FONT_SIZE_SELECT, FORE_COLOR, ITALIC_BUTTON, SEPARATOR, UNDO_BUTTON } from 'ngx-simple-text-editor';
import { firstValueFrom } from 'rxjs';
import isEmailValid from 'src/app/helpers/validateEmail';
import { IDocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { EventWithParticipantsQuantity, SluperEvent } from 'src/app/model/event.model';
import { LISTA_PAIS, Pais } from 'src/app/model/pais';
import { EventsService } from '../events.service';



@Component({
  selector: 'app-create-event-modal',
  templateUrl: './create-event-modal.component.html',
  styleUrls: ['./create-event-modal.component.css'],
})
export class CreateEventModalComponent implements OnInit {
  @Input() userProfile!: IDocumentUserProfile | null;
  @Input() event!: SluperEvent | null;
  @Input() guests!: string[] | null;
  @Output() triggerfeedbackMessage = new EventEmitter<{
    message: string;
    type: 'success' | 'danger';
  }>();


  communities: EventWithParticipantsQuantity[] = [];
  exceedsMaxAttendees: boolean = false;
  selectedCommunities: string[] = [];
  updateMode = false;
  imageChanged = false;
  defaultImageTest = '/assets/img/imagem_fundo_preview.png';

  mapsOptions: any = {
    componentRestrictions: { country: 'BR' }
  };
  modalData: {
    image?: Blob | null,
    name: string,
    site: string,
    type: 'PRESENCIAL' | 'REMOTO',
    country: string,
    city: string,
    state: string,
    latitude: number,
    longitude: number,
    address: string,
    startDate: string,
    endDate: string,
    startTime: string,
    endTime: string,
    maxAttendees: number,
    public: boolean,
    description: string,
    about: string,
    requiresConfirmation: boolean,
    participantDetailsAfterCheckin: boolean,
    guests: string[],
    communities: string[]
  } = {
      image: null,
      name: '',
      site: '',
      type: 'PRESENCIAL',
      country: '',
      city: '',
      state: '',
      latitude: 0,
      longitude: 0,
      address: '',
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      maxAttendees: 100,
      public: false,
      description: '',
      about: '',
      requiresConfirmation: false,
      participantDetailsAfterCheckin: false,
      guests: [] as string[],
      communities: [] as string[]
    };
  guestEmailToInvite = "";
  guestsFormErrors = {
    guestEmail: {
      valid: true,
      message: ''
    },
  };
  formErrors = {
    image: {
      valid: true,
      message: ''
    },
    name: {
      valid: true,
      message: ''
    },
    site: {
      valid: true,
      message: ''
    },
    type: {
      valid: true,
      message: ''
    },
    startDate: {
      valid: true,
      message: ''
    },
    endDate: {
      valid: true,
      message: ''
    },
    startTime: {
      valid: true,
      message: ''
    },
    endTime: {
      valid: true,
      message: ''
    },
    maxAttendees: {
      valid: true,
      message: ''
    },
    location: {
      valid: true,
      message: ''
    },
    description: {
      valid: true,
      message: ''
    },
    about: {
      valid: true,
      message: ''
    },
  }
  changedImageEvent: any = null;
  imageToCrop = "";
  cropMode = false;
  minDate = format(new Date(), "yyyy-MM-dd'T'hh:mm");
  loading = false;
  paises: Pais[] = LISTA_PAIS;

  config: EditorConfig = {
    placeholder: '',
    // buttons: [UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, FORE_COLOR, FONT_SIZE_SELECT, SEPARATOR, JUSTIFY_LEFT_BUTTON, JUSTIFY_CENTER_BUTTON, JUSTIFY_RIGHT_BUTTON, JUSTIFY_FULL_BUTTON],
    buttons: [UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, FORE_COLOR, FONT_SIZE_SELECT]
  };

  constructor(
    private activeModal: NgbActiveModal,
    private eventsService: EventsService,
  ) { }

  ngOnInit(): void {

    if(this.userProfile)
      this.getCommunitiesByUser(this.userProfile.idUser);

    if (!this.event) {
      return;

    }

    this.updateMode = true;

    this.modalData.name = this.event.name;
    this.modalData.site = this.event.website;
    this.modalData.type = this.event.type == 'PRESENCIAL' ? 'PRESENCIAL' : 'REMOTO';
    this.modalData.maxAttendees = this.event.numberOfParticipants;
    this.modalData.startDate = this.event.startDate;
    this.modalData.endDate = this.event.endDate;
    this.modalData.address = this.event.address;
    this.modalData.city = this.event.city;
    this.modalData.state = this.event.state;
    this.modalData.country = this.event.country;
    this.modalData.description = this.event.description;
    this.modalData.about = this.event.about;
    this.modalData.public = this.event.isPublic;
    this.modalData.requiresConfirmation = this.event.requiresConfirmation;
    this.modalData.participantDetailsAfterCheckin = !!this.event?.participantDetailsAfterCheckin;

    if (this.guests !== null) {
      this.modalData.guests = this.guests;
    }

    this.eventsService.getImageEvent(this.event.id).subscribe(res => {
      if (res.body) {
        const file = new File([this.eventsService.base64ToBlob(res.body.base64Image, 'image/jpeg')], 'event_image.jpeg', { type: 'image/jpeg' });

        this.handleFileChange({ target: { files: [file] } });

        this.imageChanged = false;
      }
    });

    return;
  }

  closeModal() {
    this.activeModal.close();
  }

  formatDate(event: { target: HTMLInputElement }) {
    const input = event.target;
    let value = input.value.replace(/\D/g, '');
    if (value.length > 8) {
      value = value.slice(0, 8);
    }

    if (value.length >= 2) {
      value = value.substring(0, 2) + '/' + value.substring(2);
    }
    if (value.length >= 5) {
      value = value.substring(0, 5) + '/' + value.substring(5);
    }

    input.value = value;
  }

  private resetGuestsFormErrors() {
    this.guestsFormErrors = {
      guestEmail: {
        valid: true,
        message: ''
      },
    }
  }

  private validateGuestsForm() {
    let valid = true;
    this.resetGuestsFormErrors();

    if (!this.guestEmailToInvite) {
      valid = false
      this.guestsFormErrors.guestEmail = {
        message: "Email obrigatório",
        valid: false
      };
    }

    if (!isEmailValid(this.guestEmailToInvite)) {
      valid = false;
      this.guestsFormErrors.guestEmail = {
        message: "Email inválido",
        valid: false
      };
    }

    return valid;
  }

  handleAddGuest() {
    this.validateGuestsForm();

    if (this.guestsFormErrors.guestEmail.valid === false) return;

    this.modalData.guests.push(this.guestEmailToInvite);
    this.guestEmailToInvite = "";
  }

  handleFileChange(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      this.cropMode = true;
      this.imageToCrop = e.target?.result as string;
      this.changedImageEvent = event;
    }

    reader.readAsDataURL(file);

    this.imageChanged = true;
  }

  removeSelectedImage() {
    this.cropMode = false;
  }

  private resetFormErrors() {
    this.formErrors = {
      image: {
        valid: true,
        message: ''
      },
      name: {
        valid: true,
        message: ''
      },
      site: {
        valid: true,
        message: ''
      },
      type: {
        valid: true,
        message: ''
      },
      startDate: {
        valid: true,
        message: ''
      },
      endDate: {
        valid: true,
        message: ''
      },
      startTime: {
        valid: true,
        message: ''
      },
      endTime: {
        valid: true,
        message: ''
      },
      maxAttendees: {
        valid: true,
        message: ''
      },
      location: {
        valid: true,
        message: ''
      },
      description: {
        valid: true,
        message: ''
      },
      about: {
        valid: true,
        message: ''
      },
    }
  }

  handleImageCropped(event: ImageCroppedEvent) {
    this.modalData.image = event.blob;
  }

  private validateForm() {
    this.resetFormErrors();

    if (!this.modalData.image && !this.updateMode) {
      this.formErrors.image = {
        valid: false,
        message: 'Imagem obrigatória'
      };
    }

    if (!this.modalData.name) {
      this.formErrors.name = {
        valid: false,
        message: 'Nome obrigatório'
      };
    }

    // if (!this.modalData.site) {
    //   this.formErrors.site = {
    //     valid: false,
    //     message: 'Site obrigatório'
    //   };
    // }

    if (!this.modalData.type) {
      this.formErrors.type = {
        valid: false,
        message: 'Tipo obrigatório'
      };
    }

    if (!this.modalData.startDate) {
      this.formErrors.startDate = {
        valid: false,
        message: 'Data de início obrigatória'
      };
    }

    if (!this.modalData.endDate) {
      this.formErrors.endDate = {
        valid: false,
        message: 'Data de término obrigatória'
      };
    }

    if (!this.modalData.maxAttendees) {
      this.formErrors.maxAttendees = {
        valid: false,
        message: 'Número de participantes obrigatório'
      };
    }

    if (this.modalData.type === 'PRESENCIAL' && (!this.modalData.address || !this.modalData.city || !this.modalData.country || !this.modalData.state)) {
      this.formErrors.location = {
        valid: false,
        message: 'Endereço obrigatório'
      };
    }

    if (!this.modalData.description) {
      this.formErrors.description = {
        valid: false,
        message: 'Descrição obrigatória'
      };
    }

    if (!this.modalData.about) {
      this.formErrors.about = {
        valid: false,
        message: 'Sobre obrigatório'
      };
    }
  }

  async handleSaveEvent() {
    if (!this.userProfile) {
      this.triggerfeedbackMessage.emit({
        message: 'Erro ao criar evento, o usuário não foi encontrado.',
        type: 'danger'
      });

      return;
    }

    this.loading = true;

    this.validateForm();

    if (Object.values(this.formErrors).some((error) => error.valid === false)) {
      this.triggerfeedbackMessage.emit({
        message: 'Erro ao criar evento, verifique os campos obrigatórios.',
        type: 'danger'
      });
      this.loading = false;

      return;
    }

    const event = {
      id: '',
      name: this.modalData.name,
      website: this.modalData.site,
      type: this.modalData.type,
      country: this.modalData.country,
      state: this.modalData.state,
      city: this.modalData.city,
      address: this.modalData.address,
      startDate: this.modalData.startDate.length < 19 ? this.modalData.startDate.concat(':00.000Z') : this.modalData.startDate.concat('.000Z'),
      endDate: this.modalData.endDate.length < 19 ? this.modalData.endDate.concat(':00.000Z') : this.modalData.endDate.concat('.000Z'),
      numberOfParticipants: this.modalData.maxAttendees,
      isPublic: this.modalData.public,
      description: this.modalData.description,
      latitude: String(this.modalData.latitude),
      longitude: String(this.modalData.longitude),
      imageLink: '',
      organizer: {
        idUser: this.userProfile.idUser,
        name: this.userProfile.name,
        bio: this.userProfile.bio,
        uriImageProfile: this.userProfile.uriImageProfile,
        uriImageBackground: this.userProfile.uriImageBackground,
        header: this.userProfile.header,
      },
      guests: this.modalData.guests,
      communities: this.selectedCommunities,
      requiresConfirmation: this.modalData.requiresConfirmation,
      participantDetailsAfterCheckin: this.modalData.participantDetailsAfterCheckin,
      active: true,
      about: this.modalData.about
    }

    try {
      if (!this.updateMode) {
        await firstValueFrom(this.eventsService.createEvent(event)).then(e => {
          event.id = e.body.message;
        });
      } else {
        event.id = this.event ? this.event.id : '';
        await firstValueFrom(this.eventsService.updateEvent(event));
      }
      this.loading = false;

      let imageUrl;
      if (!this.updateMode || this.imageChanged) {
        imageUrl = await this.uploadEventImage(event.id);
      }

      if (!imageUrl && this.imageChanged) {
        this.loading = false;
        return;
      } else {
        imageUrl = '';
      }

      this.triggerfeedbackMessage.emit({
        message: 'Evento criado com sucesso',
        type: 'success'
      });

      this.closeModal();
    } catch (error: any) {
      this.triggerfeedbackMessage.emit({
        message: error?.message || 'Erro ao criar evento',
        type: 'danger'
      });

      this.loading = false;
    }
  }

  private createEventImage(blob: Blob) {
    const currentTime = Date.now();
    const fileName = `event_${currentTime}.jpeg`;

    const blobFile = new File([blob], fileName, {
      type: 'image/jpeg',
      lastModified: currentTime,
    });

    return blobFile;
  }

  private async uploadEventImage(eventId: string) {
    if (!this.userProfile) {
      this.triggerfeedbackMessage.emit({
        message: 'Erro ao criar evento, o usuário não foi encontrado.',
        type: 'danger'
      });

      return;
    }

    try {
      const eventFile = this.createEventImage(this.modalData.image as Blob);

      const uploadImageResponse = await firstValueFrom(this.eventsService.saveEventImage(
        eventId,
        `event_${eventId}`,
        eventFile,
      ));

      return uploadImageResponse;
    } catch (error: any) {
      this.triggerfeedbackMessage.emit({
        message: error?.message || 'Erro ao salvar imagem',
        type: 'danger'
      });

      return;
    }
  }

  removeGuest(i: number) {
    this.guests?.splice(i, 1);
  }

  async getCommunitiesByUser(userId: string) {
    const response = await firstValueFrom(this.eventsService.getCommunitiesByUser(userId));
      this.communities = response.body ?? [];
  }

  onSelectionChange(selectedItems: EventWithParticipantsQuantity[]) {
    this.checkMaxAttendees();
  }

  checkMaxAttendees() {
    let totalParticipants = 0;

    this.selectedCommunities.forEach(selectedId => {
      const community = this.communities.find(c => c.event.id === selectedId);
      if (community) {
        totalParticipants += community.participantsQuantity;
      }
    });

    this.exceedsMaxAttendees = totalParticipants > this.modalData.maxAttendees;
  }
}
