import { IPhone } from './phone.model';

export interface IProfissional {
  company: string;
  cnpj: string;
  email: string;
  country: string;
  phone: IPhone;
  cep: string;
  companySize: string;
}

export class Profissional implements IProfissional {
  constructor(
    public company: string,
    public cnpj: string,
    public email: string,
    public country: string,
    public phone: IPhone,
    public cep: string,
    public companySize: string
  ) { }
}
