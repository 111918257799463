<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<div class="modal-header">
  <span class="title">Gestāo do administrador de licença{{ownerEmail ? 's: ' + ownerEmail : ': ' + licenseId}}</span>
  <button type="button" class="btn-close" aria-label="Close" (click)="onCloseClick()"></button>
</div>

<section class="owner-assignment-section" *ngIf="!ownerEmail || ownerEmail.length < 1 ">
  <app-assign-license-owner [licenseId]="licenseId" [onSuccess]="onSuccess"></app-assign-license-owner>
</section>

<section class="owner-settings-section" *ngIf="ownerEmail && ownerEmail.length > 0">
  <div class="tabs-container">
    <button (click)="handleToggleTab()" class="tab-container"
      [ngClass]="selectedTab === 'toggle-user-as-corp' ? 'selected-tab' : ''">
      <span class="tab-title" [ngClass]="selectedTab === 'toggle-user-as-corp' ? 'selected-title' : ''">
        Perfil de acesso
      </span>
    </button>

    <button (click)="handleToggleTab()" class="tab-container"
      [ngClass]="selectedTab === 'replace-licenses-owner' ? 'selected-tab' : ''">
      <span class="tab-title" [ngClass]="selectedTab === 'replace-licenses-owner' ? 'selected-title' : ''">
        Substituir Administrador de Licenças
      </span>
    </button>
  </div>

  <app-user-settings *ngIf="selectedTab === 'toggle-user-as-corp'" [ownerEmail]="ownerEmail" [onSuccess]="onSuccess">
  </app-user-settings>

  <app-replace-licenses-owner *ngIf="selectedTab === 'replace-licenses-owner'" [ownerEmail]="ownerEmail"
    [onSuccess]="onSuccess">
  </app-replace-licenses-owner>
</section>