<div class="modal-container">
  <div class="title-container">
    <h1 class="title">Atenção: Limite Excedido! 🚨</h1>
    <p class="exceed-explanation">
      {{ descriptionMessage }}
    </p>
  </div>

  <img src="/assets/img/undraw_update_re_swkp.svg" alt="ilustration" class="ilustration" />

  <p class="call-to-action">{{ callToAction }}</p>

  <div class="buttons-container">
    <a
      (click)="closeModal()"
      href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
      target="_blank"
      class="update-plan-button">
      Atualizar Plano
    </a>

    <button (click)="closeModal()" class="skip-button">Mais tarde</button>
  </div>
</div>
