import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from './new-sluper/core/service/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'BizConnectUsuario';
  loading$ = this.loadingService.loading$;

  constructor(private translate: TranslateService,
    private loadingService: LoadingService
  ) {
    registerLocaleData(localePt);
  }

  async ngOnInit(): Promise<void> {
    await this.setLanguage();
  }

  async setLanguage(): Promise<void> {
    this.translate.setDefaultLang('pt');
  }
}
