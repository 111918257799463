<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>
<div class="modal-header">
  <span class="title">Gerar Licenças</span>
  <button type="button" class="btn-close" aria-label="Close" (click)="onCloseClick()"></button>
</div>
<div class="modal-body content">
  <div class="input-div">
    <label for="quantidade" class="labels-text">Quantidade de licenças</label>
    <div class="input-group inputs">
      <div class="input-group-prepend">
        <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-qr-code"></i></span>
      </div>
      <input type="number" class="form-control input-text" id="quantidade" name="quantidade" min="0" max="100"
        [(ngModel)]="numberOfLicenses">
    </div>
  </div>
  <div class="input-div">
    <label for="owner" class="labels-text">E-mail Owner (Opcional)</label>
    <div class="input-group inputs">
      <div class="input-group-prepend">
        <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-envelope"></i></span>
      </div>
      <input type="email" class="form-control input-text" id="owner" name="owner" [(ngModel)]="emailOwner"
        [disabled]="emailDestinatarioSemVinculo != '' && emailDestinatarioSemVinculo != null" email>
    </div>
  </div>
  <div class="input-div">
    <label for="owner" class="labels-text">E-mail Usuário (Opcional)</label>
    <div class="input-group inputs">
      <div class="input-group-prepend">
        <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-envelope"></i></span>
      </div>
      <input type="email" class="form-control input-text" id="owner" name="owner" [(ngModel)]="emailUser"
        [disabled]="emailDestinatarioSemVinculo != '' && emailDestinatarioSemVinculo != null" email>
    </div>
  </div>
  <div class="input-div">
    <label for="owner" class="labels-text">Destinatário (sem vincular licença)</label>
    <div class="input-group inputs">
      <div class="input-group-prepend">
        <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-envelope"></i></span>
      </div>
      <input type="email" class="form-control input-text" id="owner" name="owner"
        [(ngModel)]="emailDestinatarioSemVinculo"
        [disabled]="(emailOwner != '' && emailOwner != null) || (emailUser != '' && emailUser != null)" email>
    </div>
  </div>
  <div class="input-div">
    <label for="owner" class="labels-text">TAG</label>
    <div class="input-group inputs">
      <div class="input-group-prepend">
        <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-tag"></i></span>
      </div>
      <input type="text" class="form-control input-text" id="tag" name="tag"
        [(ngModel)]="tag" maxlength="10">
    </div>
</div>
<div class="footer">
  <button type="button" class="gerar-button" (click)="generateLicenses()">Gerar</button>
  <button type="button" class="cancel-button" (click)="onCloseClick()">Cancelar</button>
</div>
