<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<app-loading-spinner *ngIf="loading"></app-loading-spinner>

<div class="event-infos-container" *ngIf="!loading">
  <section class="left-side-section">
    <button class="go-back-button" *ngIf="authorizedPage" (click)="handleBackToList()">
      <i class="bi bi-arrow-left-short"></i>
    </button>

    <div class="event-info-box">
      <div class="event-headline">
        <div class="event-title-container">
          <h1 class="event-title">{{ event.name }}</h1>


          <div class="event-type-stamp">
            <i class="bi-webcam" *ngIf="event.type === 'REMOTO'"></i>
            <i class="bi-geo-alt-fill" *ngIf="event.type !== 'REMOTO' && event.type !== 'COMUNIDADE'"></i>
            <i class="bi-people-fill" *ngIf="event.type === 'COMUNIDADE'"></i>
            <span>{{ event.type === 'REMOTO' ? 'ONLINE' : event.type }}</span>
          </div>
        </div>
      </div>

      <div class="event-actions-container">
        <div class="event-host-container">
          <img [src]="event.organizer.uriImageProfile ? event.organizer.uriImageProfile : defaultImageProfile"
            alt="host-profile-pic" class="profile-pic" />

          <div class="host-name-container">
            <span class="host-name-label">Criado por</span>
            <span class="host-name">{{ event.organizer.name }}</span>
          </div>
        </div>

        <div class="event-actions">
          <span class="available-slots" *ngIf="event.type !== 'COMUNIDADE'">{{ 'Restam ' + missingParticipants + '
            vagas' }}</span>

          <button *ngIf="!isUserJoined()" [disabled]="joiningLoading || missingParticipants < 1 " (click)="joinEvent()"
            class="highlight-button">
            <div class="button-load-spinner" *ngIf="joiningLoading"></div>

            Participar
          </button>

          <div *ngIf="isUserJoined() && !isUserPendentJoined()" class="connected-stamp">
            <i class="bi bi-check"></i>

            Participando
          </div>

          <div *ngIf="isUserJoined() && isUserPendentJoined()" class="connected-stamp">
            <i class="bi bi-check"></i>

            Pendente Aprovação
          </div>

          <button class="action-small-button" (click)="shareEvent()">
            <i class="bi-share"></i>
          </button>
          <button class="action-small-button" *ngIf="userProfile?.idUser === event.organizer.idUser"
            (click)="openCreateEventModal()">
            <i class="bi bi-pencil"></i>
          </button>
          <button class="action-small-button" *ngIf="userProfile?.idUser === event.organizer.idUser"
            (click)="openDeleteEventModal()">
            <i class="bi bi-x-lg"></i>
          </button>

          <!-- <button class="action-small-button">
            <i class="bi-bookmark"></i>
          </button> -->
        </div>
      </div>

      <div class="event-image-datails">
        <img [src]="event.imageLink" alt="event-img" class="event-image" />

        <div class="event-image-description">
          <span class="box-title"> Apresentação </span>
          <div class="event-description" [innerHTML]="event.description">
            {{ event.description }}
          </div>
        </div>

      </div>
    </div>

    <div class="event-info-box">
      <span class="box-title"> Sobre </span>
      {{event.about}}
    </div>


    <div class="event-info-box" *ngIf="checkEventEnded() && event.type !== 'COMUNIDADE'">
      <span class="box-title"> Avaliações e Comentários </span>
      <div *ngIf="this.eventReviews && this.eventReviews">
        <ng-container *ngFor="let review of this.eventReviews">
          <div class="row mb-3">
            <div class="col-12 d-flex align-items-center">
              <h4 class="me-2">{{ review.name }}</h4>
              <span class="me-2">{{ review.review }}</span>
              <i class="bi bi-star-fill text-warning"></i>
            </div>
            <div class="col-12">
              <p class="fst-italic">{{ review.comment }}</p>
            </div>
          </div>
          <hr />
        </ng-container>
      </div>
    </div>

    <div class="event-info-box">
      <span class="box-title"> Participantes </span>

      <div class="filters-inputs" *ngIf="participantsHistory.length > 0">
        <input type="text" id="name" name="name" class="name-input" placeholder="Nome" [(ngModel)]="participantName"
          [ngClass]="!(documentUser !== undefined && documentUser.role === 'adm') && userProfile?.idUser !== organizerId && userProfilePlan?.profilePlanLimits?.eventsParticipantsView !== undefined && !userProfilePlan?.profilePlanLimits?.eventsParticipantsView ? 'hidden' : ''"
          (input)="onSelectChange()">

        <ng-select class="select-preferences" [items]="preferencesList" [multiple]="true" [closeOnSelect]="false"
          [searchable]="false" bindLabel="description" bindValue="id" placeholder="Interesses"
          [(ngModel)]="selectedPreferences" [hideSelected]="true" (change)="onSelectChange()"
          [ngClass]="!(documentUser !== undefined && documentUser.role === 'adm') && userProfile?.idUser !== organizerId && userProfilePlan?.profilePlanLimits?.eventsParticipantsView !== undefined && !userProfilePlan?.profilePlanLimits?.eventsParticipantsView ? 'hidden' : ''"></ng-select>
      </div>

      <div class="filters-inputs" *ngIf="participantsHistory.length > 0">
        <ng-select class="select-preferences" [items]="occupationsList" [multiple]="true" [closeOnSelect]="false"
          [searchable]="false" bindLabel="description" bindValue="id" placeholder="Áreas de Atuação"
          [(ngModel)]="selectedOccupations" [hideSelected]="true" (change)="onSelectChange()"
          [ngClass]="!(documentUser !== undefined && documentUser.role === 'adm') && userProfile?.idUser !== organizerId && userProfilePlan?.profilePlanLimits?.eventsParticipantsView !== undefined && !userProfilePlan?.profilePlanLimits?.eventsParticipantsView ? 'hidden' : ''"></ng-select>

        <div class="filters-button-content">
          <button *ngIf="userProfile?.idUser === organizerId" class="highlight-button"
            (click)="downloadParticiapantsCSV()"
            [ngClass]="!(documentUser !== undefined && documentUser.role === 'adm') && userProfile?.idUser !== organizerId && userProfilePlan?.profilePlanLimits?.eventsParticipantsView !== undefined && !userProfilePlan?.profilePlanLimits?.eventsParticipantsView ? 'hidden' : ''">
            <i class="bi bi-filetype-csv"></i>Exportar para CSV</button>
        </div>
      </div>

      <div class="participants-list"
        [ngClass]="!(documentUser !== undefined && documentUser.role === 'adm') && userProfile?.idUser !== organizerId && userProfilePlan?.profilePlanLimits?.eventsParticipantsView !== undefined && !userProfilePlan?.profilePlanLimits?.eventsParticipantsView ? 'hidden' : ''">
        <ng-container *ngFor="let participant of participants">
          <div class="card">
            <div class="participant-card" (click)="openParticipantProfile(participant)">
              <div class="bg-img" [style]="'background-image: url(' + getBackground(participant) + ');'">
                <div class="profile-img">
                  <img [src]="participant.uriImageProfile ? participant.uriImageProfile : defaultImageProfile"
                    alt="participant-profile-pic" class="profile-pic" />
                </div>
                <div class="participant-checkin" *ngIf="userProfile?.idUser === event.organizer.idUser">
                  <button (click)="checkinParticipant($event, participant)" class="participant-action-button"
                    matTooltip="Confirmar Presença" style="font-size: medium;">
                    <i class="bi bi-person-check" *ngIf="!participant.checkIn"></i>
                    <i class="bi bi-person-fill-check" style="color: #30c17c;" *ngIf="participant.checkIn"></i>
                  </button>
                </div>
                <div class="participant-actions">
                  <button (click)="checkParticipantInterests($event, participant)" class="participant-action-button"
                    matTooltip="Ver interesses">
                    <i class="bi bi-person-lines-fill"></i>
                  </button>
                  <button (click)="checkParticipantPresentation($event, participant)"
                    [disabled]="!participant.presentation" class="participant-action-button"
                    matTooltip="Ver apresentação">
                    <i class="bi bi-person-workspace"></i>
                  </button>
                </div>
              </div>

              <div class="participant-headline">
                <span class="participant-name">{{ participant.name }}</span>
                <span class="participant-bio">{{ participant.bio }}</span>
              </div>

              <div *ngIf="participant.participationStatus !== 'PENDENTE_APROVACAO'">
                <div
                  *ngIf="isParticipantConnected(participant.idUser) && ((documentUser !== undefined && documentUser.role === 'adm') || (userProfile?.idUser !== organizerId && userProfilePlan?.profilePlanLimits?.connectContacts !== undefined && userProfilePlan?.profilePlanLimits?.connectContacts))"
                  class="connected-stamp">
                  <i class="bi bi-check"></i>

                  Conectado
                </div>

                <button
                  *ngIf="!isParticipantConnected(participant.idUser) && ((documentUser !== undefined && documentUser.role === 'adm' && participant.idUser !== userProfile?.idUser) || (userProfile?.idUser !== organizerId && participant.idUser !== userProfile?.idUser && userProfilePlan?.profilePlanLimits?.connectContacts !== undefined && userProfilePlan?.profilePlanLimits?.connectContacts))"
                  class="highlight-button" (click)="handleConnect(participant.idUser, $event)">
                  <i class="bi bi-person-fill-add"></i>

                  Conectar
                </button>
                <a class="highlight-button" matTooltip="Para realizar conexões, faça o upgrade do seu plano"
                  target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
                  matTooltipClass="mat-tooltip" (click)="toggleTooltipProfile($event)"
                  *ngIf="!(documentUser !== undefined && documentUser.role === 'adm') && (userProfile?.idUser !== organizerId && userProfilePlan?.profilePlanLimits?.connectContacts !== undefined && !userProfilePlan?.profilePlanLimits?.connectContacts)">
                  <i class="bi bi-lock-fill"></i>
                </a>
              </div>
              <div style="display: flex; flex-direction: row; gap: 8px;"
                *ngIf="userProfile?.idUser === event.organizer.idUser && participant.participationStatus === 'PENDENTE_APROVACAO'">
                <button *ngIf="participant.idUser !== userProfile?.idUser" class="confirm-participation-button approve"
                  (click)="approveParticipant(participant.idUser, true, $event)">

                  Aprovar
                </button>
                <button *ngIf="participant.idUser !== userProfile?.idUser" class="confirm-participation-button reprove"
                  (click)="approveParticipant(participant.idUser, false, $event)">

                  Reprovar
                </button>
              </div>

            </div>
          </div>
        </ng-container>
      </div>
      <a target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
        class="no-dash-graph-access-button"
        *ngIf="!(documentUser !== undefined && documentUser.role === 'adm') && userProfile?.idUser !== organizerId && userProfilePlan?.profilePlanLimits?.eventsParticipantsView !== undefined && !userProfilePlan?.profilePlanLimits?.eventsParticipantsView && participants.length > 0">
        <i class="bi bi-lock-fill"></i>

        <div class="no-dash-graph-messages">
          Para visualizar os participantes,
          <a target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal">faça
            upgrade do seu plano</a>
        </div>
      </a>
    </div>
  </section>

  <section class="right-side-section">
    <div class="event-info-box especific-infos-box">
      <div class="especific-infos">
        <div class="especific-info-row" *ngIf="event.type !== 'COMUNIDADE'">
          <i class="bi bi-clock"></i>

          <span class="event-info-value" (click)="addToGoogleCalendar()">
            {{ event.startDate | date: "EEEE, d 'de' MMMM 'de' YYYY 'às' HH:mm" : '-0300' : 'pt-BR' }}
          </span>
        </div>

        <div class="especific-info-row" *ngIf="event.type !== 'COMUNIDADE'">
          <i class="bi bi-calendar"></i>

          <span class="event-info-value" (click)="addToGoogleCalendar()">
            <a class="event-info-link"> Adicionar ao calendário </a>
          </span>
        </div>

        <div class="especific-info-row">
          <i class="bi bi-link"></i>

          <a [href]="event.website" target="_blank" class="event-link-value"> {{ event.website }} </a>
        </div>

        <div class="especific-info-row" *ngIf="event.address">
          <i class="bi bi-pin-map"></i>

          <span class="event-info-value"> {{ event.address }} </span>
        </div>

        <div class="especific-info-row" *ngIf="event.address">
          <i class="bi bi-map"></i>
          <a *ngIf="event.address" [href]="'https://www.google.com/maps/?q=' + getAddress()" target="_blank"
            rel="noopener noreferrer">
            Veja no Mapa
          </a>
        </div>

        <div *ngIf="checkUserhasCheckin() && event.type !== 'COMUNIDADE'">
          <button class="highlight-button" style="width: 100%;" [disabled]="!checkEventEnded() || disableReviewButton" (click)="rateEvent(event)">
          <div class="button-load-spinner" *ngIf="!checkEventEnded()"></div>
          <span *ngIf="checkEventEnded() && !disableReviewButton">Avaliar Evento</span>
          <span *ngIf="disableReviewButton">Avaliação realizada</span>
          <span *ngIf="!checkEventEnded()">Avaliação disponível ao final do evento</span>
        </button>
        </div>

      </div>
    </div>

    <div class="event-info-box" *ngIf="authorizedPage">
      <app-dashboard-card class="card-container" [dashboardCardData]="{
          title: 'Conexões Realizadas',
          icon: 'bi-person-add',
          color: '#8c65e7',
          value: dashboardEvent?.connectionsMade
              ? dashboardEvent?.connectionsMade?.toString()
              : '0'
        }">
      </app-dashboard-card>
      <app-dashboard-card class="card-container" [dashboardCardData]="{
                title: 'Total de inscritos',
                icon: 'bi-person-badge',
                color: '#1080c7',
                value: registeredCount.toString()
              }">
      </app-dashboard-card>
      <app-dashboard-card class="card-container" [dashboardCardData]="{
                      title: 'Total de participantes',
                      icon: 'bi-person-check',
                      color: '#81bd86',
                      value: participantsCount.toString()
                    }">
      </app-dashboard-card>
      <app-dashboard-card *ngIf="event.type !== 'COMUNIDADE' && checkEventEnded()" class="card-container" [dashboardCardData]="{
        title: 'Avaliação do evento',
        icon: 'bi-star-fill',
        color: '#e7c465',
        value: averageRating.toString()
      }">
</app-dashboard-card>
    </div>

    <div class="event-info-box">
      <span class="box-title" *ngIf="event.type !== 'COMUNIDADE'"> Participe do evento, lendo o QrCode </span>
      <span class="box-title" *ngIf="event.type === 'COMUNIDADE'"> Participe da comunidade, lendo o QrCode </span>

      <div class="qr-code-container">
        <qrcode [qrdata]="'https://perfil.sluper.bio/public-event/' + event.id" [width]="308"
          [errorCorrectionLevel]="'M'"></qrcode>
      </div>
    </div>
  </section>
</div>
