<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<div class="modal-header">
  <span class="title">Dados do Usuário</span>
  <button type="button" class="btn-close" aria-label="Close" (click)="onCloseClick()"></button>
</div>

<div class="modal-body content">
  <div class="user-data-container">
    <div class="user-plan-controls">
      <div class="input-div">
        <label for="licenseSelect" class="labels-text">Plano</label>
        <div class="input-group inputs">
          <div class="input-group-prepend">
            <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-collection"></i></span>
          </div>

          <select *ngIf="documentUser.plan" name="licenseSelect" id="licenseSelect" class="form-select input-text"
            [(ngModel)]="documentUser.plan.idPlan">
            <option *ngFor="let plan of availablePlans" [value]="plan.idPlan">{{plan.name}}</option>
          </select>
        </div>
      </div>

      <div class="input-div">
        <label for="planExpirationDate" class="labels-text">Data Exp. Plano</label>
        <div class="input-group inputs">
          <button class="btn btn-outline-secondary bi bi-calendar3 input-prepend calendar-button"
            (click)="d3.toggle()"></button>

            <input class="form-control input-text" id="planExpirationDate" name="planExpirationDate"
            placeholder="dd/mm/yyyy" ngbDatepicker #d3="ngbDatepicker" (dateSelect)="changePlanExpirationDate($event)"
            [value]="planExpirationDate.day + '/' + planExpirationDate.month + '/' + planExpirationDate.year">
        </div>
      </div>
    </div>

    <div class="input-div">
      <label for="owner" class="labels-text">E-mail</label>
      <div class="input-group inputs">
        <div class="input-group-prepend">
          <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-envelope"></i></span>
        </div>

        <input type="text" class="form-control input-text" id="owner" name="owner" [(ngModel)]="documentUser.email">
      </div>
    </div>

    <div class="input-div">
      <label for="owner" class="labels-text">Nome</label>
      <div class="input-group inputs">
        <div class="input-group-prepend">
          <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-person"></i></span>
        </div>
        <input type="text" class="form-control input-text" id="owner" name="owner" [value]="documentUser.personal ? documentUser.personal.name : ''"
          disabled readonly>
      </div>
    </div>

    <div class="input-div">
      <label for="owner" class="labels-text">Sobrenome</label>
      <div class="input-group inputs">
        <div class="input-group-prepend">
          <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-person"></i></span>
        </div>
        <input type="text" class="form-control input-text" id="owner" name="owner" disabled
          [value]="documentUser.personal ? documentUser.personal.lastName : ''" readonly>
      </div>
    </div>

    <div class="input-div">
      <label for="owner" class="labels-text">Data de Nascimento</label>
      <div class="input-group inputs">
        <button class="btn btn-outline-secondary bi bi-calendar3 input-prepend calendar-button"
          (click)="d2.toggle()"></button>

        <input class="form-control input-text" id="dataNascimento" name="dataNascimento" placeholder="dd/mm/yyyy"
          ngbDatepicker #d2="ngbDatepicker" [value]="formattedBirthDateDate" [disabled]="true">
      </div>
    </div>

    <div class="input-div">
      <label for="paymentId" class="labels-text">Id do Pagamento</label>
      <div class="input-group inputs">
        <div class="input-group-prepend">
          <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-key"></i></span>
        </div>
        <input type="text" class="form-control input-text" id="paymentId" name="paymentId" disabled
          [value]="documentLicense.paymentIntentId" readonly>
      </div>
    </div>
  </div>

  <div class="resell-config-container" [style.background]="!documentUser.isReseller ? '#f3f3f3' : 'transparent'">
    <div class="resell-config-title">
      <span>Configurações de Afiliado</span>

      <div class="resell-enabled-container">
        <label for="resell" class="labels-text">Afiliado</label>
        <div class="form-check form-switch">
          <input id="resell" type="checkbox" role="switch" class="form-check-input" name="active"
            [(ngModel)]="documentUser.isReseller" (change)="handleToggleIsReseller($event)">
        </div>
      </div>
    </div>

    <div class="resell-config-inputs-container">
      <div class="input-div">
        <label for="owner" class="labels-text">Código Promo</label>

        <div class="input-group inputs">
          <div class="input-group-prepend">
            <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-envelope"></i></span>
          </div>

          <input type="text" class="form-control input-text" id="promo-code" name="promo-code"
            [(ngModel)]="documentUser.promoCode" [disabled]="!documentUser.isReseller">
        </div>
      </div>

      <div class="input-div">
        <label for="owner" class="labels-text">Porcentagem da Comissão</label>

        <div class="input-group inputs">
          <div class="input-group-prepend">
            <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-percent"></i></span>
          </div>

          <input mask="00.0||0.0||00.00" type="text" class="form-control input-text" id="promo-percentage"
            name="promo-percentage" [disabled]="!documentUser.isReseller" [(ngModel)]="formattedPromoPercentage"
            (change)="changePercent($event)">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="footer">
  <div class="buttons-container">
    <button type="button" class="cancel-button" (click)="onCloseClick()">Cancelar</button>
    <button type="button" class="save-button" (click)="onSaveClick()">Salvar</button>
  </div>
</div>
