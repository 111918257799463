import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { format } from 'date-fns';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BOLD_BUTTON, EditorConfig, FONT_SIZE_SELECT, FORE_COLOR, ITALIC_BUTTON, SEPARATOR, UNDO_BUTTON } from 'ngx-simple-text-editor';
import { firstValueFrom } from 'rxjs';
import isEmailValid from 'src/app/helpers/validateEmail';
import { IDocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { SluperEvent } from 'src/app/model/event.model';
import { EventsService } from '../events.service';
import { main } from '@popperjs/core';

@Component({
  selector: 'app-create-community-modal',
  templateUrl: './create-community-modal.component.html',
  styleUrls: ['./create-community-modal.component.css'],
})
export class CreateCommunityModalComponent implements OnInit {
  @Input() userProfile!: IDocumentUserProfile | null;
  @Input() event!: SluperEvent | null;
  @Input() guests!: string[] | null;
  @Output() triggerfeedbackMessage = new EventEmitter<{
    message: string;
    type: 'success' | 'danger';
  }>();

  isUpdate = false;
  imageChanged = false;
  addressChanged = false;
  defaultImageTest = '/assets/img/imagem_fundo_preview.png';

  mapsOptions: any = {
    componentRestrictions: { country: 'BR' }
  };
  modalData: {
    image?: Blob | null,
    name: string,
    site: string,
    type: string,
    startDate: string,
    endDate: string,
    maxAttendees: number,
    public: boolean,
    description: string,
    mainDescription: string,
    requiresConfirmation: boolean,
    guests: string[],
    about: string
  } = {
      image: null,
      name: '',
      site: '',
      type: 'COMUNIDADE',
      startDate: '',
      endDate: '9999-12-31T23:59:59.499Z',
      maxAttendees: 9999,
      public: false,
      description: '',
      mainDescription: '',
      requiresConfirmation: false,
      guests: [] as string[],
      about: '',
    };
  guestEmailToInvite = "";
  guestsFormErrors = {
    guestEmail: {
      valid: true,
      message: ''
    },
  };
  formErrors = {
    image: {
      valid: true,
      message: ''
    },
    name: {
      valid: true,
      message: ''
    },
    site: {
      valid: true,
      message: ''
    },
    description: {
      valid: true,
      message: ''
    },
    about: {
      valid: true,
      message: ''
    },
  }
  changedImageEvent: any = null;
  imageToCrop = "";
  cropMode = false;
  minDate = format(new Date(), "yyyy-MM-dd'T'hh:mm");
  loading = false;

  config: EditorConfig = {
    placeholder: '',
    // buttons: [UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, FORE_COLOR, FONT_SIZE_SELECT, SEPARATOR, JUSTIFY_LEFT_BUTTON, JUSTIFY_CENTER_BUTTON, JUSTIFY_RIGHT_BUTTON, JUSTIFY_FULL_BUTTON],
    buttons: [UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, FORE_COLOR, FONT_SIZE_SELECT]
  };

  constructor(private activeModal: NgbActiveModal,
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    if (this.event != null) {
      this.isUpdate = true;
      this.modalData.name = this.event.name;
      this.modalData.site = this.event.website;
      this.modalData.type = this.event.type;
      this.modalData.description = this.event.description;
      this.modalData.mainDescription = this.event.description;
      this.modalData.startDate = this.event.startDate;
      this.modalData.endDate = this.event.endDate;
      this.modalData.maxAttendees = this.event.numberOfParticipants;
      this.modalData.public = this.event.isPublic;
      this.modalData.requiresConfirmation = this.event.requiresConfirmation;
      if (this.guests != null) {
        this.modalData.guests = this.guests;
      }

      this.eventsService.getImageEvent(this.event.id).subscribe(res => {
        if (res.body) {
          const file = new File([this.eventsService.base64ToBlob(res.body.base64Image, 'image/jpeg')], 'event_image.jpeg', { type: 'image/jpeg' });
          this.handleFileChange({ target: { files: [file] } });
          this.imageChanged = false;
        }
      });
    }

    return;
  }

  closeModal() {
    this.activeModal.close();
  }

  formatDate(event: any) {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, '');
    if (value.length > 8) {
      value = value.slice(0, 8);
    }

    if (value.length >= 2) {
      value = value.substring(0, 2) + '/' + value.substring(2);
    }
    if (value.length >= 5) {
      value = value.substring(0, 5) + '/' + value.substring(5);
    }

    input.value = value;
  }

  private resetGuestsFormErrors() {
    this.guestsFormErrors = {
      guestEmail: {
        valid: true,
        message: ''
      },
    }
  }

  private validateGuestsForm() {
    let valid = true;
    this.resetGuestsFormErrors();

    if (!this.guestEmailToInvite) {
      valid = false
      this.guestsFormErrors.guestEmail = {
        message: "Email obrigatório",
        valid: false
      };
    }

    if (!isEmailValid(this.guestEmailToInvite)) {
      valid = false;
      this.guestsFormErrors.guestEmail = {
        message: "Email inválido",
        valid: false
      };
    }

    return valid;
  }

  handleAddGuest() {
    this.validateGuestsForm();

    if (this.guestsFormErrors.guestEmail.valid === false) return;

    this.modalData.guests.push(this.guestEmailToInvite);
    this.guestEmailToInvite = "";
  }

  handleFileChange(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      this.cropMode = true;
      this.imageToCrop = e.target?.result as string;
      this.changedImageEvent = event;
    }

    reader.readAsDataURL(file);

    this.imageChanged = true;
  }

  removeSelectedImage() {
    this.cropMode = false;
  }

  private resetFormErrors() {
    this.formErrors = {
      image: {
        valid: true,
        message: ''
      },
      name: {
        valid: true,
        message: ''
      },
      site: {
        valid: true,
        message: ''
      },
      description: {
        valid: true,
        message: ''
      },
      about: {
        valid: true,
        message: ''
      },
    }
  }

  handleImageCropped(event: ImageCroppedEvent) {
    this.modalData.image = event.blob;
  }

  private validateForm() {
    this.resetFormErrors();

    if (!this.modalData.image && !this.isUpdate) {
      this.formErrors.image = {
        valid: false,
        message: 'Imagem obrigatória'
      };
    }

    if (!this.modalData.name) {
      this.formErrors.name = {
        valid: false,
        message: 'Nome obrigatório'
      };
    }

    if (!this.modalData.description) {
      this.formErrors.description = {
        valid: false,
        message: 'Descrição obrigatória'
      };
    }

    if (!this.modalData.about) {
      this.formErrors.about = {
        valid: false,
        message: 'Sobre obrigatório'
      };
    }
  }

  async handleSaveEvent() {
    if (!this.userProfile) {
      this.triggerfeedbackMessage.emit({
        message: 'Erro ao criar comunidade, o usuário não foi encontrado.',
        type: 'danger'
      });

      return;
    }

    this.loading = true;

    this.validateForm();

    if (Object.values(this.formErrors).some((error) => error.valid === false)) {
      this.triggerfeedbackMessage.emit({
        message: 'Erro ao criar comunidade, verifique os campos obrigatórios.',
        type: 'danger'
      });
      this.loading = false;

      return;
    }

    const event = {
      id: '',
      name: this.modalData.name,
      website: this.modalData.site,
      type: 'COMUNIDADE',
      startDate: new Date().toISOString(),
      endDate: new Date('9999-12-31T23:59:59.499Z').toISOString(),
      numberOfParticipants: this.modalData.maxAttendees,
      isPublic: this.modalData.public,
      description: this.modalData.description,
      mainDescription: this.modalData.mainDescription,
      imageLink: '',
      organizer: {
        idUser: this.userProfile.idUser,
        name: this.userProfile.name,
        bio: this.userProfile.bio,
        uriImageProfile: this.userProfile.uriImageProfile,
        uriImageBackground: this.userProfile.uriImageBackground,
        header: this.userProfile.header,
      },
      guests: this.modalData.guests,
      requiresConfirmation: this.modalData.requiresConfirmation,
      active: true
    }

    try {
      if (!this.isUpdate) {
        await firstValueFrom(this.eventsService.createCommunity(event)).then(e => {
          event.id = e.body.message;
        });
      } else {
        event.id = this.event ? this.event.id : '';
        await firstValueFrom(this.eventsService.updateCommunity(event));
      }

      this.loading = false;

      let imageUrl;
      if (!this.isUpdate || this.imageChanged) {
        imageUrl = await this.uploadEventImage(event.id);
      }

      if (!imageUrl && this.imageChanged) {
        this.loading = false;
        return;
      } else {
        imageUrl = '';
      }

      this.triggerfeedbackMessage.emit({
        message: 'Comunidade criada com sucesso',
        type: 'success'
      });

      this.closeModal();
    } catch (error: any) {
      this.triggerfeedbackMessage.emit({
        message: error?.message || 'Erro ao criar comunidade',
        type: 'danger'
      });

      this.loading = false;
    }
  }

  private createEventImage(blob: Blob) {
    const currentTime = Date.now();
    const fileName = `event_${currentTime}.jpeg`;

    const blobFile = new File([blob], fileName, {
      type: 'image/jpeg',
      lastModified: currentTime,
    });

    return blobFile;
  }

  private async uploadEventImage(eventId: string) {
    if (!this.userProfile) {
      this.triggerfeedbackMessage.emit({
        message: 'Erro ao criar comunidade, o usuário não foi encontrado.',
        type: 'danger'
      });

      return;
    }

    try {
      const eventFile = this.createEventImage(this.modalData.image as Blob);

      const uploadImageResponse = await firstValueFrom(this.eventsService.saveEventImage(
        eventId,
        `event_${eventId}`,
        eventFile,
      ));

      return uploadImageResponse;
    } catch (error: any) {
      this.triggerfeedbackMessage.emit({
        message: error?.message || 'Erro ao salvar imagem',
        type: 'danger'
      });

      return;
    }
  }

  removeGuest(i: number) {
    this.guests?.splice(i, 1);
  }
}
