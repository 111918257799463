import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { ComponentsModule } from '../../core/components/components.module';
import { CadastroComponent } from './cadastro.component';
import { ChaveAtivacaoComponent } from './chave-ativacao/chave-ativacao.component';
import { CadastroInformacoesPerfilDoisComponent } from './configuracao-cadastro/cadastro-informacoes-perfil-dois/cadastro-informacoes-perfil-dois.component';
import { CadastroInformacoesPerfilTresComponent } from './configuracao-cadastro/cadastro-informacoes-perfil-tres/cadastro-informacoes-perfil-tres.component';
import { CadastroInformacoesPerfilUmComponent } from './configuracao-cadastro/cadastro-informacoes-perfil-um/cadastro-informacoes-perfil-um.component';
import { CadastroTermosUsoComponent } from './configuracao-cadastro/cadastro-termos-uso/cadastro-termos-uso.component';
import { ConfiguracaoCadastroComponent } from './configuracao-cadastro/configuracao-cadastro.component';
import { ConfirmacaoCriacaoContaComponent } from './confirmacao-criacao-conta/confirmacao-criacao-conta.component';



@NgModule({
  declarations: [CadastroComponent, ChaveAtivacaoComponent, ConfiguracaoCadastroComponent, CadastroTermosUsoComponent, CadastroInformacoesPerfilUmComponent, CadastroInformacoesPerfilDoisComponent, CadastroInformacoesPerfilTresComponent, ConfirmacaoCriacaoContaComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule
  ]
})
export class CadastroModule { }
