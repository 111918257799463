import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { AppNavigateService } from 'src/app/app-navigate.service';
import { AlterarCorFundoModalComponent } from '../../core/components/alterar-cor-fundo-modal/alterar-cor-fundo-modal.component';
import { UserProfileWithCSS } from '../../core/model/user-profile-css.model';
import { LoadingService } from '../../core/service/loading.service';
import { ToastService } from '../../core/service/toast.service';
import { UserProfileService } from '../../core/service/user-profile.service';
import { CadastroStorageService } from '../../public/cadastro/cadastro-storage.service';

@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styleUrls: ['./perfil-usuario.component.css']
})
export class PerfilUsuarioComponent implements OnInit {

  profileImage: string = '../../../../assets/images/imagem_perfil.png';
  backgroundImage: string = '../../../../assets/images/imagem_fundo.png';
  backgroundFile!: File | undefined;
  profileImageFile!: File | undefined;

  isToastVisible: boolean = false;

  biografia = new FormControl('', [Validators.required]);
  nomeExibicao = new FormControl('');
  titulo = new FormControl('', [Validators.required]);
  urlProfile = new FormControl('', [Validators.required, Validators.maxLength(255), Validators.minLength(3), Validators.pattern(/^\S*$/), Validators.pattern(/^[a-z0-9_.]+$/)]);

  characterCount: number = 0;

  userProfile: UserProfileWithCSS = new UserProfileWithCSS();

  constructor(
    private appNavigateService: AppNavigateService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private cadastroStorage: CadastroStorageService,
    private modalService: NgbModal,

  ) { }

  ngOnInit(): void {

    this.getUserById();

  }

  getUserById() {
    const userId = this.cadastroStorage.license;

    this.userProfileService.userById(userId).subscribe({
      next: (response) => {
        const userProfile = response.body; // body contém a resposta JSON
        if (userProfile) {
          this.userProfile = new UserProfileWithCSS();
          Object.assign(this.userProfile, userProfile);
        }
      },
      error: (error) => {
        console.error('Erro ao obter perfil do usuário:', error);
      }
    });
  }

  updateCharacterCount(): void {
    this.characterCount = String(this.biografia.value).length;
  }

  onProfileImageChanged(data: { newImage: string, file?: File }) {
    this.profileImage = data.newImage;
    this.profileImageFile = data.file;
  }

  async salvar() {
    if (this.titulo.invalid || this.biografia.invalid || this.urlProfile.invalid) {
      this.toastService.showError("Insira todos os dados necessários.");
      return;
    }

    await this.salvarDados();
  }

  private async salvarDados() {
    this.loadingService.show();

    let newCallingCardBgUrl: string | undefined = undefined;
    let newProfileImage: string | undefined = undefined;

    try {
      if (this.backgroundFile) {
        newCallingCardBgUrl = await this.uploadProfileImage(this.backgroundFile, 'background_-1', 'Não foi possível salvar a imagem de fundo. Contacte o administrador.');
      }

      if (this.profileImageFile) {
        newProfileImage = await this.uploadProfileImage(this.profileImageFile, 'profile_-1', 'Não foi possível salvar a imagem de perfil. Contacte o administrador.');
      }
      const nome: string = String(this.nomeExibicao.value) ? String(this.nomeExibicao.value) : this.userProfile.documentUserProfile.name;
      this.userProfile.documentUserProfile.listURI = [{ link: String(this.urlProfile.value) }];
      this.userProfile.documentUserProfile.bio = String(this.biografia.value);
      this.userProfile.documentUserProfile.header = { text: String(this.titulo.value), visible: true };
      this.userProfile.documentUserProfile.name = nome;


      if (newProfileImage) {
        this.userProfile.documentUserProfile.uriImageProfile = newProfileImage;
      }

      if (newCallingCardBgUrl) {
        this.userProfile.documentUserProfile.uriImageBackground = newCallingCardBgUrl;
      }

      await firstValueFrom(this.userProfileService.saveDocumentUserProfile(this.userProfile.documentUserProfile));
      this.toastService.showSuccess("Perfil atualizado com sucesso.");
      this.appNavigateService.navegarUserProfile();

    } catch (err) {
      this.toastService.showError("Erro ao atualizar perfil do usuário.");
      console.error(err);
    } finally {
      this.loadingService.hide();
    }
  }

  private async uploadProfileImage(image: File, prefix: string, errorMessage?: string): Promise<string | undefined> {
    try {
      const callingCardBgUrl = await firstValueFrom(this.userProfileService.saveProfileImage(
        this.userProfile.documentUserProfile.idUser,
        prefix,
        image,
      ));
      return callingCardBgUrl;
    } catch {
      this.toastService.showError(errorMessage || 'Não foi possível salvar a imagem. Contacte o administrador.');
      return undefined;
    }
  }

  validateLink(event: Event) {
    const { value: linkInput } = event.target as HTMLInputElement;

    if (linkInput.length < 3) {
      return;
    }

    this.isProfileLinkAvailable(linkInput).then((isAvailable) => {
      if (!isAvailable) {
        this.urlProfile.setErrors({ ...this.urlProfile.errors, 'notAvailable': true });
        this.toastService.showError("Essa url não está disponível!");
        return;
      }

      if (this.urlProfile.errors && this.urlProfile.errors['notAvailable']) {
        this.urlProfile.errors['notAvailable'] = undefined;
      }
    });
  }

  private async isProfileLinkAvailable(linkValue: string) {
    try {
      return await firstValueFrom(this.userProfileService.validaLinkDisponivel(linkValue));
    } catch (err) {
      return false;
    }
  }

  openBackgroundModal() {
    const modalRef = this.modalService.open(AlterarCorFundoModalComponent, {
      centered: true,
    });

    modalRef.result.then((result) => {
      if (result) {
        this.backgroundImage = result.image;
        this.backgroundFile = result.file;
      }
    })
  }
}
