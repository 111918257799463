<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<div class="preview-button-div" *ngIf="screenWidth < 550 || screenHeight < 550">
  <button type="button" class="save-float-button" (click)="save()" translate><i
      class="bi bi-check2"></i>botaoAvancar</button>
</div>

<div class="body-div">
  <div class="options-div">
    <div class="containers">
      <div class="title-container">
        <span class="title-text" translate>conta.enderecoPersonalizado</span>
      </div>

      <form class="content-form">
        <label for="nome" class="labels-text" translate>conta.seuIdentificador</label>

        <div class="input-container link-input-container" *ngFor="let link of links; let i = index">
          <div class="input-group inputs link-input">
            <div class="input-group-prepend">
              <span class="input-group-text input-prepend"><i class="bi bi-pencil"></i></span>
            </div>

            <input maxlength="20" type="text" class="form-control input-text" id="link" name="link"
              [(ngModel)]="link.link" (input)="validateLink(link, i, $event)" (blur)="fixLink(link)"
              (paste)="onPaste($event)" />
          </div>
          <button type="button" class="remove-button" (click)="removeLink(i)" *ngIf="links.length > 1">
            <i class="bi bi-x-lg"></i>
          </button>
        </div>

        <div class="user-id-validation-checks-container">
          <div class="validation-item-container">
            <i *ngIf="!userIdentifierValidationChecks.blankSpace" class="bi bi-check2 validated-icon"></i>
            <i *ngIf="userIdentifierValidationChecks.blankSpace" class="bi bi-x non-validated-icon"></i>

            <span *ngIf="!userIdentifierValidationChecks.blankSpace" class="validation-check-label validated-label">Não
              deve conter espaços em branco.</span>
            <span *ngIf="userIdentifierValidationChecks.blankSpace"
              class="validation-check-label non-validated-label">Não deve conter espaços em branco.</span>
          </div>

          <div class="validation-item-container">
            <i *ngIf="!userIdentifierValidationChecks.length" class="bi bi-check2 validated-icon"></i>
            <i *ngIf="userIdentifierValidationChecks.length" class="bi bi-x non-validated-icon"></i>

            <span *ngIf="!userIdentifierValidationChecks.length"
              class="validation-check-label validated-label">Necessário ter uma quantidade de caracteres entre 3 e
              20.</span>
            <span *ngIf="userIdentifierValidationChecks.length"
              class="validation-check-label non-validated-label">Necessário ter uma quantidade de caracteres entre 3 e
              20.</span>
          </div>

          <div class="validation-item-container">
            <i *ngIf="!userIdentifierValidationChecks.specialCharacters" class="bi bi-check2 validated-icon"></i>
            <i *ngIf="userIdentifierValidationChecks.specialCharacters" class="bi bi-x non-validated-icon"></i>

            <span *ngIf="!userIdentifierValidationChecks.specialCharacters"
              class="validation-check-label validated-label">Não é permitido o uso de caracteres especiais, exceto "." e
              "_".</span>
            <span *ngIf="userIdentifierValidationChecks.specialCharacters"
              class="validation-check-label non-validated-label">Não é permitido o uso de caracteres especiais, exceto
              "." e "_".</span>
          </div>

          <div class="validation-item-container" *ngFor="let linkError of linkErrors">
            <i *ngIf="linkError" class="bi bi-x non-validated-icon"></i>

            <span class="error-label non-validated-label" *ngIf="linkError">{{ linkError }}</span>
          </div>
        </div>

        <label class="labels-text">
          {{ links.length > 0 ? 'Seu link Sluper: https://sluper.bio/' + links[0].link : '' }}
        </label>

        <div class="input-container">
          <label class="labels-text" translate>Aprovação automática de solicitações de conexão</label>

          <div class="external-image-toggle-container">
            <div class="form-check form-switch">
              <input [(ngModel)]="autoApprove" id="auto-approve" type="checkbox" role="switch" class="form-check-input"
                name="active" />
            </div>

            <label class="labels-text" *ngIf="autoApprove" translate>conta.pesquisaPublica.ativado</label>
            <label class="labels-text" *ngIf="!autoApprove" translate>conta.pesquisaPublica.desativado</label>
          </div>
          </div>
      </form>
    </div>

    <div class="containers">
      <div class="title-container">
        <span class="title-text" translate>conta.permitirApenasNfc.titulo</span>
      </div>

      <form class="content-form">
        <div class="input-container">
          <label class="labels-text" translate>conta.permitirApenasNfc.ativadoDesativado</label>

          <div class="external-image-toggle-container">
            <div class="form-check form-switch">
              <input [(ngModel)]="allowAccessOnlyByNFC" id="allow-access" type="checkbox" role="switch"
                class="form-check-input" name="active" />
            </div>

            <label class="labels-text" *ngIf="allowAccessOnlyByNFC" translate>conta.pesquisaPublica.ativado</label>

            <label class="labels-text" *ngIf="!allowAccessOnlyByNFC" translate>conta.pesquisaPublica.desativado</label>
          </div>
        </div>

        <div class="profile-updates-locked-container" *ngIf="!profileTemplateConfigsEnabled">
          <div class="lock-container">
            <i class="bi bi-lock lock-icon"></i>

            <span class="lock-label">Edição bloqueada devido a template vinculado. Contate o administrador de sua
              licença para alterações.
            </span>
          </div>
        </div>
      </form>
    </div>

    <div class="containers">
      <div class="title-container">
        <span class="title-text" translate>conta.pesquisaPublica.titulo</span>
      </div>

      <form class="content-form">
        <div class="input-container">
          <label class="labels-text" translate>conta.pesquisaPublica.ativadoDesativado</label>

          <div class="external-image-toggle-container">
            <div class="form-check form-switch">
              <input [(ngModel)]="publicSearch" id="public-event" type="checkbox" role="switch" class="form-check-input"
                name="active" />
            </div>

            <label class="labels-text" *ngIf="publicSearch" translate>conta.pesquisaPublica.ativado</label>
            <label class="labels-text" *ngIf="!publicSearch" translate>conta.pesquisaPublica.desativado</label>
          </div>

          <div>
            <label for="presentation" class="labels-text label-apresentacao"
              translate>conta.pesquisaPublica.minhaApresentacao</label>

            <st-editor name="presentation" style="width: 100%" [config]="config" [(ngModel)]="myPresentation"
              (input)="myPresentationChanged = true"></st-editor>
          </div>
        </div>

        <div class="profile-updates-locked-container" *ngIf="!profileTemplateConfigsEnabled">
          <div class="lock-container">
            <i class="bi bi-lock lock-icon"></i>

            <span class="lock-label">Edição bloqueada devido a template vinculado. Contate o administrador de sua
              licença para alterações.
            </span>
          </div>
        </div>
      </form>
    </div>

    <div class="containers">
      <div class="title-container">
        <span class="title-text" translate>conta.informacoesPessoais.titulo</span>
      </div>

      <form class="content-form">
        <div class="input-container">
          <label for="nome" class="labels-text" translate>conta.informacoesPessoais.nome</label>
          <div class="input-group inputs">
            <div class="input-group-prepend">
              <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-pencil"
                  [style.color]="!documentUser.personal.name ? 'red' : ''"></i></span>
            </div>

            <input type="text" class="form-control input-text" id="nome" name="nome"
              [(ngModel)]="documentUser.personal.name"
              [style.border-color]="!documentUser.personal.name ? 'red' : ''" />
          </div>
        </div>

        <div class="input-container">
          <label for="sobrenome" class="labels-text" translate>conta.informacoesPessoais.sobrenome</label>
          <div class="input-group inputs">
            <div class="input-group-prepend">
              <span class="input-group-text input-prepend" id="basic-addon2"><i class="bi bi-pencil"
                  [style.color]="!documentUser.personal.lastName ? 'red' : ''"></i></span>
            </div>
            <input type="text" class="form-control input-text" id="sobrenome" name="sobrenome"
              [(ngModel)]="documentUser.personal.lastName"
              [style.border-color]="!documentUser.personal.lastName ? 'red' : ''" />
          </div>
        </div>

        <div class="input-container">
          <label for="email" class="labels-text" translate>conta.informacoesPessoais.email</label>
          <div class="input-group inputs">
            <div class="input-group-prepend">
              <span class="input-group-text input-prepend" id="basic-addon3"><i class="bi bi-envelope"
                  [style.color]="!documentUser.email ? 'red' : ''"></i></span>
            </div>
            <input type="email" class="form-control input-text" id="email" name="email" [(ngModel)]="documentUser.email"
              [style.border-color]="!documentUser.email ? 'red' : ''" />
          </div>
        </div>

        <div class="input-container">
          <label for="dataNascimento" class="labels-text" translate>conta.informacoesPessoais.dataNascimento</label>
          <div class="input-group inputs">
            <button class="btn btn-outline-secondary bi bi-calendar3 input-prepend calendar-button"
              (click)="d2.toggle()" [style.color]="!documentUser.personal.birthdate ? 'red' : ''"></button>
            <input class="form-control input-text" id="dataNascimento" name="dataNascimento" placeholder="dd/mm/yyyy"
              ngbDatepicker #d2="ngbDatepicker" [(ngModel)]="documentUser.personal.birthdate"
              (input)="formatDate($event)" [style.border-color]="!documentUser.personal.birthdate ? 'red' : ''" />
          </div>
        </div>

        <div class="input-container">
          <label for="pais" class="labels-text" translate>conta.informacoesPessoais.pais</label>
          <div class="input-group inputs">
            <div class="input-group-prepend">
              <span class="input-group-text input-prepend" id="basic-addon5"><i class="bi bi-pencil"
                  [style.color]="!documentUser.personal.country ? 'red' : ''"></i></span>
            </div>
            <select class="form-select input-list" name="country" [(ngModel)]="documentUser.personal.country"
              [style.border-color]="!documentUser.personal.country ? 'red' : ''">
              <option value=""></option>
              <option [value]="paisSelecionado.sigla" *ngFor="let paisSelecionado of paises">
                {{ paisSelecionado.sigla }} - {{ paisSelecionado.nomePais }}
              </option>
            </select>
          </div>
        </div>

        <div class="input-container">
          <label for="telefone" class="labels-text" translate>conta.informacoesPessoais.telefone</label>
          <div class="input-group inputs">
            <div class="input-group-prepend">
              <span class="input-group-text input-prepend" id="basic-addon4"><i class="bi bi-telephone"></i></span>
            </div>
            <select class="form-select input-text" name="paisTelefone" [(ngModel)]="paisTelefone"
              (change)="changePaisTelefoneSelecionado()">
              <option value=""></option>
              <option [ngValue]="paisTelefone" *ngFor="let paisTelefone of paises">
                {{ paisTelefone.nomePais }} {{ paisTelefone.codigo }}
              </option>
            </select>
            <input type="text" class="form-control input-text telefone-input" id="telefone" name="telefone"
              [(ngModel)]="telefone" [mask]="paisTelefone?.mascara" [disabled]="!paisTelefone"
              [placeholder]="paisTelefone ? paisTelefone.mascara : ''" />
          </div>
        </div>

        <div class="input-container">
          <label for="cep" class="labels-text" translate>conta.informacoesPessoais.cep</label>
          <div class="input-group inputs">
            <div class="input-group-prepend">
              <span class="input-group-text input-prepend" id="basic-addon5"><i class="bi bi-pencil"
                  [style.color]="!documentUser.personal.cep ? 'red' : ''"></i></span>
            </div>
            <input type="text" class="form-control input-text" id="cep" name="cep"
              [(ngModel)]="documentUser.personal.cep" [style.border-color]="!documentUser.personal.cep ? 'red' : ''"
              mask="00000-000" *ngIf="documentUser.personal.country === 'BR'" />
            <input type="text" class="form-control input-text" id="cep" name="cep"
              [(ngModel)]="documentUser.personal.cep" [style.border-color]="!documentUser.personal.cep ? 'red' : ''"
              *ngIf="documentUser.personal.country !== 'BR'" />
          </div>
        </div>
      </form>
    </div>

    <div class="containers">
      <div class="title-container">
        <span class="title-text" translate>conta.informacoesProfissionais.titulo</span>
      </div>

      <form class="content-form">
        <div class="input-container">
          <label for="empresa" class="labels-text" translate>conta.informacoesProfissionais.empresa</label>
          <div class="input-group inputs">
            <div class="input-group-prepend">
              <span class="input-group-text input-prepend" id="basic-addon6"><i class="bi bi-pencil"></i></span>
            </div>
            <input type="text" class="form-control input-text" id="empresa" name="empresa"
              [(ngModel)]="documentUser.professional.company" />
          </div>
        </div>

        <div class="input-container">
          <label for="cnpj" class="labels-text" translate>conta.informacoesProfissionais.cnpj</label>
          <div class="input-group inputs">
            <div class="input-group-prepend">
              <span class="input-group-text input-prepend" id="basic-addon7"><i class="bi bi-pencil"></i></span>
            </div>
            <input type="text" class="form-control input-text" id="cnpj" name="cnpj"
              [(ngModel)]="documentUser.professional.cnpj" />
          </div>
        </div>

        <div class="input-container">
          <label for="emailCorporativo" class="labels-text"
            translate>conta.informacoesProfissionais.emailCorporativo</label>
          <div class="input-group inputs">
            <div class="input-group-prepend">
              <span class="input-group-text input-prepend" id="basic-addon8"><i class="bi bi-envelope"></i></span>
            </div>
            <input type="email" class="form-control input-text" id="emailCorporativo" name="emailCorporativo"
              [(ngModel)]="documentUser.professional.email" />
          </div>
        </div>

        <div class="input-container">
          <label for="paisEmpresa" class="labels-text" translate>conta.informacoesProfissionais.pais</label>
          <div class="input-group inputs">
            <div class="input-group-prepend">
              <span class="input-group-text input-prepend" id="basic-addon3"><i class="bi bi-pencil"></i></span>
            </div>
            <select class="form-select input-list" id="paisEmpresa" name="paisEmpresa"
              [(ngModel)]="documentUser.professional.country">
              <option value=""></option>
              <option [value]="paisSelecionado.sigla" *ngFor="let paisSelecionado of paises">
                {{ paisSelecionado.sigla }} - {{ paisSelecionado.nomePais }}
              </option>
            </select>
          </div>
        </div>

        <div class="input-container">
          <label for="telefoneCorporativo" class="labels-text" translate>conta.informacoesProfissionais.telefone</label>
          <div class="input-group inputs">
            <div class="input-group-prepend">
              <span class="input-group-text input-prepend" id="basic-addon9"><i class="bi bi-telephone"></i></span>
            </div>
            <select class="form-select input-text" name="paisTelefoneCorporativo" [(ngModel)]="paisTelefoneCorporativo"
              (change)="changePaisTelefoneCorporativoSelecionado()">
              <option value=""></option>
              <option [ngValue]="pais" *ngFor="let pais of paises">{{ pais.nomePais }} {{ pais.codigo }}</option>
            </select>
            <input type="text" class="form-control input-text telefone-input" id="telefoneCorporativo"
              name="telefoneCorporativo" [(ngModel)]="telefoneCorporativo" [mask]="paisTelefoneCorporativo?.mascara"
              [disabled]="!paisTelefoneCorporativo"
              [placeholder]="paisTelefoneCorporativo ? paisTelefoneCorporativo.mascara : ''" />
          </div>
        </div>

        <div class="input-container">
          <label for="tamanhoEmpresa" class="labels-text"
            translate>conta.informacoesProfissionais.tamanhoEmpresa</label>
          <div>
            <div class="custom-control custom-radio">
              <input type="radio" id="tamanhoEmpresa1" name="tamanhoEmpresa1" class="custom-control-input"
                [(ngModel)]="documentUser.professional.companySize" value="Uso Pessoal">
              <label class="custom-control-label input-radio" for="tamanhoEmpresa1">Uso Pessoal</label>
            </div>
            <div class="custom-control custom-radio">
              <input type="radio" id="tamanhoEmpresa2" name="tamanhoEmpresa1" class="custom-control-input"
                [(ngModel)]="documentUser.professional.companySize" value="De 1 a 9 funcionarios">
              <label class="custom-control-label input-radio" for="tamanhoEmpresa2">De 1 a 9 funcionários</label>
            </div>
            <div class="custom-control custom-radio">
              <input type="radio" id="tamanhoEmpresa3" name="tamanhoEmpresa3" class="custom-control-input"
                [(ngModel)]="documentUser.professional.companySize" value="De 10 a 49 funcionarios">
              <label class="custom-control-label input-radio" for="tamanhoEmpresa3">De 10 a 49 funcionários</label>
            </div>
            <div class="custom-control custom-radio">
              <input type="radio" id="tamanhoEmpresa4" name="tamanhoEmpresa4" class="custom-control-input"
                [(ngModel)]="documentUser.professional.companySize" value="De 50 a 249 funcionarios">
              <label class="custom-control-label input-radio" for="tamanhoEmpresa4">De 50 a 249 funcionários</label>
            </div>
            <div class="custom-control custom-radio">
              <input type="radio" id="tamanhoEmpresa5" name="tamanhoEmpresa5" class="custom-control-input"
                [(ngModel)]="documentUser.professional.companySize" value="De 250 a 999 funcionarios">
              <label class="custom-control-label input-radio" for="tamanhoEmpresa5">De 250 a 999 funcionários</label>
            </div>
            <div class="custom-control custom-radio">
              <input type="radio" id="tamanhoEmpresa6" name="tamanhoEmpresa6" class="custom-control-input"
                [(ngModel)]="documentUser.professional.companySize" value="1000 ou mais funcionarios">
              <label class="custom-control-label input-radio" for="tamanhoEmpresa6">1.000 ou mais
                funcionários</label>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="containers">
      <div class="title-container">
        <span class="title-text" translate>conta.preferencias</span>
      </div>

      <form class="content-form">
        <div class="input-container">
          <label for="user-preferences" class="labels-text" translate>conta.preferences.label</label>

          <ng-select class="select-preferences" [items]="preferences" [multiple]="true" [closeOnSelect]="false"
            [searchable]="false" bindLabel="description" name="user-preferences" bindValue="id"
            placeholder="Selecione seus interesses" [(ngModel)]="selectedPreferences" [hideSelected]="true"></ng-select>
        </div>
      </form>
    </div>

    <div class="containers limit-container">
      <div class="title-container">
        <span class="title-text" translate>conta.preferences.area.label</span>
      </div>

      <form class="content-form">
        <div class="input-container">
          <label for="user-preferences" class="labels-text" translate>conta.preferences.area.label</label>

          <ng-select class="select-preferences" [items]="occupations" [multiple]="true" [closeOnSelect]="false"
            [searchable]="false" bindLabel="description" name="user-preferences" bindValue="id"
            placeholder="Selecione sua área de atuação" [(ngModel)]="selectedOccupations"
            [hideSelected]="true"></ng-select>
        </div>
      </form>
    </div>

    <div class="div-botao-salvar" *ngIf="screenWidth > 550 && screenHeight > 550">
      <button type="button" id="salvar" name="salvar" class="botao-salvar" (click)="save()" translate>
        <i class="bi bi-arrow-right"></i>botaoAvancar
      </button>
    </div>
  </div>
</div>
