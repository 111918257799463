import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { LicencasService } from '../../licencas.service';
import { Subject, debounceTime, filter, map } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { IResponse } from 'src/app/model/response.model';
import { ILicense } from 'src/app/model/license.model';

@Component({
  selector: 'app-replace-licenses-owner',
  templateUrl: './replace-licenses-owner.component.html',
  styleUrls: ['./replace-licenses-owner.component.css']
})
export class ReplaceLicensesOwnerComponent implements OnInit {
  private _alert = new Subject<string>();
  alertMessage: string = '';
  alertType: string = '';
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  @Input() ownerEmail: string = '';
  @Input() onSuccess: () => void = () => {};

  licenses: ILicense[] = [];
  selectedLicenses: string[] = [];
  selectedAll: boolean = false;
  removeOwnership: boolean = false;
  newOwnerEmail: string | null = null;

  constructor(
    private activeModal: NgbActiveModal,
    private licencasService: LicencasService
  ) { }

  fetchLicensesByOwnerEmail(email: string) {
    this.licencasService.getLicensesByOwnerEmail(email)
    .pipe(
      filter((mayBeOk: HttpResponse<ILicense[]>) => mayBeOk.ok),
      map((response: HttpResponse<ILicense[]>) => response.body)
    ).subscribe({
      next: (res) => {
        this.licenses = res || [];
      },
      error: (res: any) => this.onError(res)
    });
  }

  ngOnInit(): void {
    this.fetchLicensesByOwnerEmail(this.ownerEmail);

    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  handleToggleLicenseSelection(licenseId: string) {
    if (this.selectedLicenses.includes(licenseId)) {
      this.selectedAll = false;
      this.selectedLicenses = this.selectedLicenses.filter((id) => id !== licenseId);

      return;
    }

    this.selectedLicenses.push(licenseId);

    if(this.selectedLicenses.length == this.licenses.length) {
      this.selectedAll = true;
    }
  }

  handleSelectAllLicenses() {
    if (this.selectedAll) {
      this.selectedLicenses = [];
      this.selectedAll = false;

      return;
    }

    this.selectedAll = true;
    this.selectedLicenses = this.licenses.map((license) => license.documentLicense.license);
  }

  handleRemoveLicensesOwnership() {
    this.removeOwnership = !this.removeOwnership;

    if (this.removeOwnership) {
      this.newOwnerEmail = '';
    }
  }

  handleReplaceLicensesOwner() {
    if (this.selectedLicenses.length < 1) {
      this.alertType = 'danger';
      this._alert.next('Selecione ao menos uma licença.');

      return;
    }

    // if (this.newOwnerEmail === null) {
    //   this.alertType = 'danger';
    //   this._alert.next('Informe um e-mail válido.');

    //   return;
    // }

    const replaceLicensesOwnershipRequest = {
      licenses: this.selectedLicenses,
      newOwnerEmail: this.newOwnerEmail || ''
    };

    this.licencasService.replaceLicensesOwnership(replaceLicensesOwnershipRequest).
      pipe(
        filter((mayBeOk: HttpResponse<IResponse>) => mayBeOk.ok),
        map((response: HttpResponse<IResponse>) => response.body))
      .subscribe(res => {
        if (res?.status === 'SUCCESS') {
          this.alertType = 'success';
          this._alert.next(res.message);
          this.activeModal.close();

          this.onSuccess();
          return;
        }

        this.alertType = 'danger';
        this._alert.next(res?.message || 'Erro ao tentar substituir o proprietário das licenças.');
      });
  }

  onCloseClick() {
    this.activeModal.dismiss();
  }

  onError(res: any) {
    if (res.error.status == "ERROR") {
      this.alertType = 'danger';
      this._alert.next(res.error.message);
    }
  }
}
