<div class="preview-button-div">
  <button type="button" class="preview-float-button" (click)="onCloseClick()" translate><i class="bi bi-x-lg"></i>botaoFechar</button>
</div>

<app-profile-preview
  [showImageProfile]="showImageProfile"
  [mobilePreview]="true"
  [profileImageUrl]="profile.uriImageProfile"
  [userCss]="userCss"
  [backgroundImageUrl]="profile.uriImageBackground"
  [bio]="profile.bio"
  [name]="profile.name"
  [header]="profile.header"
  [redesSociais]="profile.listSocialNetwork"
  [documentUser]="this.userProfile.documentUser"
  [linkButtons]="profile.listButtons"></app-profile-preview>
