export class Phone {
  siglaPais?: string;
  ddi?: string;
  phoneNumber?: string;
  constructor() {
    this.siglaPais = "";
    this.ddi = "";
    this.phoneNumber = "";
  }
}
