import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LISTA_PAIS, Pais } from 'src/app/new-sluper/core/model/pais';
import { Personal } from 'src/app/new-sluper/core/model/personal.model';
import { CadastroStorageService } from '../../cadastro-storage.service';
import { ConfiguracaoCadastroStorageService } from '../configuracao-cadastro-storage.service';

@Component({
  selector: 'app-cadastro-informacoes-perfil-um',
  templateUrl: './cadastro-informacoes-perfil-um.component.html',
  styleUrls: ['./cadastro-informacoes-perfil-um.component.css']
})
export class CadastroInformacoesPerfilUmComponent implements OnInit {
  @Output() nextScreen: EventEmitter<Personal> = new EventEmitter<Personal>();
  @Output() previousScreen: EventEmitter<void> = new EventEmitter<void>();

  pessoaForm: FormGroup;
  personal: Personal = new Personal();
  paises: Pais[] = LISTA_PAIS;
  brasil = this.paises.find(pais => pais.sigla === 'BR');

  constructor(
    private fb: FormBuilder,
    private configCadastroStorage: ConfiguracaoCadastroStorageService,
    private cadastroStorage: CadastroStorageService,
  ) {
    this.pessoaForm = this.fb.group({
      nomeCompleto: ['', Validators.required],
      telefone: ['', Validators.required],
      paisTelefone: [this.brasil, Validators.required],
      birthdate: ['', Validators.required],
      country: ['BR'],
      cep: [this.personal.cep, Validators.required]
    });

    // Preenche o nome do usuário se existir no storage
    this.pessoaForm.get('nomeCompleto')?.setValue(this.cadastroStorage.username);
  }

  ngOnInit(): void {
    // Se existir dados salvos, carrega os dados no formulário
    if (this.configCadastroStorage.getPersonalData()) {
      this.pessoaForm.patchValue(this.configCadastroStorage.getPersonalData());
    }
  }

  voltarPagina() {
    this.previousScreen.emit();
  }

  proximaPagina() {
    if (this.pessoaForm.valid) {
      this.configCadastroStorage.setPersonalData(this.getForm());
      this.nextScreen.emit();
    }
  }

  getForm(): Personal {
    const personal: Personal = { ...this.pessoaForm.value };

    const telefone = this.pessoaForm.value.telefone || '';
    const paisSelecionado = this.pessoaForm.get('paisTelefone')?.value;

    personal.phone = {
      ddi: paisSelecionado?.codigo || '', // Pega o DDI do país selecionado
      phoneNumber: telefone.replace(/\D/g, ''), // Remove caracteres não numéricos
      siglaPais: paisSelecionado?.sigla
    };

    const [name, ...lastNameParts] = (this.pessoaForm.value.nomeCompleto || '').split(' ');
    personal.name = name;
    personal.lastName = lastNameParts.join(' ');

    personal.birthdate = this.parseDate(String(this.pessoaForm.value.birthdate));
    personal.country = paisSelecionado?.sigla || 'BR'; // Define o país selecionado

    return personal;
  }

  parseDate(dateString: string) {
    let cleanDateString = dateString.replace(/\D/g, '').slice(0, 8);
    let day = cleanDateString.substring(0, 2);
    let month = cleanDateString.substring(2, 4);
    let year = cleanDateString.substring(4, 8);

    return `${day}-${month}-${year}`;
  }

  // Quando o país for alterado, zera o campo de telefone e aplica a nova máscara
  changePaisTelefoneSelecionado() {

    const paisSelecionado = this.pessoaForm.get('paisTelefone')?.value;
    if (paisSelecionado) {
      this.pessoaForm.get('telefone')?.reset();  // Limpa o campo de telefone
    }
  }
}
