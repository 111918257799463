import { v4 as uuidV4 } from 'uuid';
import { IDocumentUser } from './document-user.model';

export const DOCUMENT_USER_PROFILE = {
  idUser: '',
  name: '',
  bio: '',
  publish: true,
  parent: '0',
  showImageProfile: true,
  listURI: [],
  uriImageProfile: '',
  uriImageBackground: '',
  listSocialNetwork: [],
  listButtons: [
    {
      title: '',
      link: '',
      visible: true,
      description: '',
      id: uuidV4()
    },
  ],
  listUriImages: [],
  listProfile: [],
  header: {
    text: '',
    visible: true,
  },
  listContacts: [],
  networkingControl: { used: 0, max: 15 },
};

export const DOCUMENT_USER_CSS = {
  idUser: '',
  css: '',
};

export const DOCUMENT_USER = {
  idUser: 'string',
  email: 'string',
  emailKey: 'string',
  role: 'string',
  promoCode: 'string',
  isReseller: false,
  active: true,
  personal: {
    name: 'Matheus',
    lastName: 'Fraga',
    country: '',
    cep: '',
    phone: {
      ddd: '',
      number: '',
      siglaPais: ''
    }
  },
  professional: {

  },
  createDate: 'string',
  updateDate: 'string',
  networkingPlan: {

  },
} as any as IDocumentUser;

export const DOCUMENT_USER_PROFILE_WITH_CSS = {
  documentUserProfile: {...DOCUMENT_USER_PROFILE, listURI: [{link: 'matheus.fraga'}]},
  documentUserCSS: DOCUMENT_USER_CSS,
  documentUser: DOCUMENT_USER
}
