import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { LicencasService } from '../../licencas.service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css']
})
export class UserSettingsComponent implements OnInit {
  private _alert = new Subject<string>();
  alertMessage: string = '';
  alertType: string = '';
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  @Input() ownerEmail: string = '';
  @Input() onSuccess: () => void = () => { };

  userAsCorp = false;
  isSluperCoinEnabled = false;

  constructor(
    private activeModal: NgbActiveModal,
    private licencasService: LicencasService
  ) { }

  fetchUserIsCorp(email: string) {
    this.licencasService.getCorpConfigs(email)
      .subscribe({
        next: (res) => {
          if (!res.body) {
            return;
          }

          this.userAsCorp = res.body.isCorp;
          this.isSluperCoinEnabled = res.body.isSluperCoinEnabled;
        },
        error: (res: any) => this.onError(res)
      });
  }

  ngOnInit(): void {
    this.fetchUserIsCorp(this.ownerEmail);

    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  handleToggleCorp() {
    this.userAsCorp = !this.userAsCorp;
  }

  handleToggleSluperCoin() {
    this.isSluperCoinEnabled = !this.isSluperCoinEnabled;
  }

  handleSave() {
    const params = { userEmail: this.ownerEmail, isCorp: this.userAsCorp, isSluperCoinEnabled: this.isSluperCoinEnabled };

    this.licencasService.saveCorpSettings(params)
      .subscribe({
        next: (res) => {
          this.activeModal.close();
          this.onSuccess();
        },
        error: (res: any) => this.onError(res)
      });
  }

  onCloseClick() {
    this.activeModal.dismiss();
  }

  onError(res: any) {
    if (res.error.status == "ERROR") {
      this.alertType = 'danger';
      this._alert.next(res.error.message);
    }
  }
}
