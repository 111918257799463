<div class="dashboard-card-new">
  <div class="dashboard-card-header-new">
    <i class="bi {{ dashboardCardData.icon }} dashboard-card-icon-new"></i>
    <span class="dashboard-card-title-new">{{ dashboardCardData.title }}</span>
  </div>
  <div class="dashboard-card-value-container-new">
    <span class="dashboard-card-value-new">{{ dashboardCardData.value }}</span>
    <div class="dashboard-card-percentage-new">
      <span class="percentage-value"
            [ngClass]="{ 'positive': dashboardCardData.percentage > 0, 'negative': dashboardCardData.percentage < 0 }">
        <i *ngIf="dashboardCardData.percentage > 0" class="bi bi-arrow-up"></i>
        <i *ngIf="dashboardCardData.percentage < 0" class="bi bi-arrow-down"></i>
        {{ dashboardCardData.percentage | number: '1.1-1' }}%
      </span>
    </div>
  </div>
  <div class="dashboard-card-comparison-new">
    <span class="comparison-value"
          [ngClass]="{ 'positive': dashboardCardData.difference >= 0, 'negative': dashboardCardData.difference < 0 }">
      {{ dashboardCardData.difference >= 0 ? '+' : '' }}{{ dashboardCardData.difference }}
    </span>
    <span>do que no último mês</span>
  </div>
</div>
