import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CadastroStorageService {

  private codigoVerificacaoKey = 'codigoVerificacao';
  private emailKey = 'email';
  private usernameKey = 'username';
  private licenseKey = 'license';

  constructor() { }

  set codigoVerificacao(codigoVerificacao: string) {
    localStorage.setItem(this.codigoVerificacaoKey, codigoVerificacao);
  }

  get codigoVerificacao(): string {
    return localStorage.getItem(this.codigoVerificacaoKey) || '';
  }

  set email(email: string) {
    localStorage.setItem(this.emailKey, email);
  }

  get email(): string {
    return localStorage.getItem(this.emailKey) || '';
  }

  set username(username: string) {
    localStorage.setItem(this.usernameKey, username);
  }

  get username(): string {
    return localStorage.getItem(this.usernameKey) || '';
  }

  set license(license: string) {
    localStorage.setItem(this.licenseKey, license);
  }

  get license(): string {
    return localStorage.getItem(this.licenseKey) || '';
  }

  clear() {
    localStorage.removeItem(this.codigoVerificacaoKey);
    localStorage.removeItem(this.emailKey);
    localStorage.removeItem(this.usernameKey);
    localStorage.removeItem(this.licenseKey);

  }
}