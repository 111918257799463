<div class="page"></div>
<app-header-inicial [layoutColorido]="true"></app-header-inicial>

<div class="page-content">
    <div class="infos-box">
        <!-- Tela 1: Termos de Uso -->
        <div *ngIf="currentScreen === 1">
            <app-cadastro-termos-uso (nextScreen)="nextScreen()"></app-cadastro-termos-uso>
        </div>

        <!-- Tela 2: Informações de Perfil 1 -->
        <div *ngIf="currentScreen === 2">
            <app-cadastro-informacoes-perfil-um (nextScreen)="nextScreen()"
                (previousScreen)="previousScreen()"></app-cadastro-informacoes-perfil-um>
        </div>

        <!-- Tela 3: Informações de Perfil 2 -->
        <div *ngIf="currentScreen === 3">
            <app-cadastro-informacoes-perfil-dois (nextScreen)="nextScreen()"
                (previousScreen)="previousScreen()"></app-cadastro-informacoes-perfil-dois>
        </div>

        <!-- Tela 4: Informações de Perfil 3 -->
        <div *ngIf="currentScreen === 4">
            <app-cadastro-informacoes-perfil-tres (nextScreen)="salvarDados()"
                (previousScreen)="previousScreen()"></app-cadastro-informacoes-perfil-tres>
        </div>
    </div>
</div>