import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Profissional } from 'src/app/new-sluper/core/model/professional.model';
import { ConfiguracaoCadastroStorageService } from '../configuracao-cadastro-storage.service';

@Component({
  selector: 'app-cadastro-informacoes-perfil-dois',
  templateUrl: './cadastro-informacoes-perfil-dois.component.html',
  styleUrls: ['./cadastro-informacoes-perfil-dois.component.css']
})
export class CadastroInformacoesPerfilDoisComponent {
  @Output() nextScreen: EventEmitter<Profissional> = new EventEmitter<Profissional>();
  @Output() previousScreen: EventEmitter<boolean> = new EventEmitter<boolean>();

  empresaForm: FormGroup;
  profissional: Profissional = new Profissional();

  constructor(private fb: FormBuilder,
    private cadastroStorage: ConfiguracaoCadastroStorageService
  ) {
    this.empresaForm = this.fb.group({
      company: [this.profissional.company, Validators.required],
      cnpj: [this.profissional.cnpj, Validators.required],
      email: [this.profissional.email],
      country: [this.profissional.country],
      cep: [this.profissional.cep]
    });
  }

  ngOnInit(): void {
    if (this.cadastroStorage.getProfessionalData()) {
      this.empresaForm.patchValue(this.cadastroStorage.getProfessionalData());
    }
  }

  voltarPagina() {
    this.previousScreen.emit();
  }

  proximaPagina() {
    if (this.empresaForm.valid) {
      this.cadastroStorage.setProfessionalData(this.getForm());
      this.nextScreen.emit();
    }
  }

  getForm(): Profissional {
    this.empresaForm.value.country = 'BR';
    return this.empresaForm.value;
  }
}
