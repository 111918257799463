
import { Button, IButton } from './button.model';
import { Contact, IContact } from './contact.model';
import { Header, IHeader } from './header.model';
import { Link } from './link';
import { NetworkingControl } from './networking-control.model';
import { IDocumentPreferences } from './preferences.model';
import { SocialNetwork } from './social-network.model';
import { IUriImage, UriImage } from './uri-image.model';

export interface IProfile {
  visible: boolean;
  name: string;
  showImageProfile: boolean;
  bio: string;
  uriImageProfile: string;
  uriImageBackground: string;
  listSocialNetwork: SocialNetwork[];
  listButtons: IButton[];
  header: IHeader;
  listContacts: IContact[];
}

export interface IDocumentUserProfile {
  idUser: string;
  name: string;
  bio: string;
  showImageProfile: boolean;
  publish: boolean;
  parent: string;
  listURI: Link[];
  uriImageProfile: string;
  uriImageBackground: string;
  listSocialNetwork: SocialNetwork[];
  listButtons: IButton[];
  listUriImages: IUriImage[];
  header: IHeader;
  listContacts: IContact[];
  createDate?: string;
  updateDate?: string;
  networkingControl: NetworkingControl;
  listProfile: IProfile[];
  publicSearch?: boolean;
  autoApprove?: boolean;
  presentation?: string;
  allowAccessOnlyByNFC?: boolean;
  checkIn?: boolean;
}

export class DocumentUserProfile {
  idUser: string;
  name: string;
  bio: string;
  showImageProfile: boolean;
  publish: boolean;
  parent: string;
  listURI: Link[];
  uriImageProfile: string;
  uriImageBackground: string;
  listSocialNetwork: SocialNetwork[];
  listButtons: Button[];
  listUriImages: UriImage[];
  header: Header;
  listContacts: Contact[];
  createDate?: string | undefined;
  updateDate?: string | undefined;
  networkingControl: NetworkingControl;
  listProfile: IProfile[];
  publicSearch?: boolean;
  autoApprove?: boolean;
  presentation?: string;
  allowAccessOnlyByNFC?: boolean;
  checkIn?: boolean;
  constructor(

  ) {
    this.idUser = '';
    this.name = '';
    this.bio = '';
    this.showImageProfile = true;
    this.publish = true;
    this.parent = '0';
    this.listURI = [];
    this.uriImageProfile = '';
    this.uriImageBackground = '';
    this.listSocialNetwork = [];
    this.listButtons = [];
    this.listUriImages = [];
    this.header = new Header();
    this.listContacts = [];
    this.createDate = '';
    this.updateDate = '';
    this.networkingControl = new NetworkingControl();
    this.listProfile = [];
    this.publicSearch = false;
    this.autoApprove = false;
    this.presentation = '';
    this.allowAccessOnlyByNFC = false;
    this.checkIn = false;
  }

}

export interface IDocumentUserProfileWithPreferences extends IDocumentUserProfile {
  preferences?: IDocumentPreferences[];
}

export class DocumentUserProfileWithPreferences implements IDocumentUserProfileWithPreferences {
  constructor(
    public idUser: string,
    public name: string,
    public bio: string,
    public publish: boolean,
    public showImageProfile: boolean,
    public parent: string,
    public listURI: Link[],
    public uriImageProfile: string,
    public uriImageBackground: string,
    public listSocialNetwork: SocialNetwork[],
    public listButtons: IButton[],
    public listUriImages: IUriImage[],
    public header: IHeader,
    public listProfile: IProfile[],
    public listContacts: IContact[],
    public networkingControl: NetworkingControl,
    public preferences?: IDocumentPreferences[]
  ) { }
}
