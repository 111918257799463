import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { LicencasService } from '../licencas.service';
import { Subject, debounceTime, } from 'rxjs';

@Component({
  selector: 'app-manage-license-owner-modal',
  templateUrl: './manage-license-owner-modal.component.html',
  styleUrls: ['./manage-license-owner-modal.component.css']
})
export class ManageLicensesOwnerModalComponent implements OnInit {
  private _alert = new Subject<string>();
  alertMessage: string = '';
  alertType: string = '';
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  @Input() licenseId: string = '';
  @Input() ownerEmail: string = '';
  @Input() onSuccess: () => void = () => { };

  selectedTab: string = 'toggle-user-as-corp';

  constructor(
    private activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  onCloseClick() {
    this.activeModal.dismiss();
  }

  handleToggleTab() {
    if (this.selectedTab == 'toggle-user-as-corp') {
      this.selectedTab = 'replace-licenses-owner';
    } else {
      this.selectedTab = 'toggle-user-as-corp';
    }
  }

  onError(res: any) {
    if (res.error.status == "ERROR") {
      this.alertType = 'danger';
      this._alert.next(res.error.message);
    }
  }
}
