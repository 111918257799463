<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<div class="body-div">
  <div class="title-container">
    <h1 class="title" translate>menu.sluperCoins</h1>
  </div>

  <div class="promo-coins-container">
    <div class="no-promos-container" *ngIf="sluperPromoCredits.length < 1">
      <span class="no-promos-message"> Você ainda nāo participa de nenhuma promoçāo. </span>
    </div>

    <div class="promo-box" *ngFor="let promo of sluperPromoCredits">
      <div class="promo-heading-container">
        <div
          class="promo-status"
          [style]="promo.activeForUser ? 'background: #34d399; border-color: #c7ffea' : 'background: #ff6b6b; border-color: #ffc7c7'"></div>

        <div class="promo-basic-info-container">
          <span class="promo-name">{{ promo.promoName }}</span>

          <span class="promo-description">{{ promo.promoDescription }}</span>
        </div>

        <div class="coins-counter-container">
          <i class="bi bi-coin promo-coin"></i>
          {{ promo.quantityCredit }}
        </div>
      </div>

      <div class="promo-especific-infos-container">
        <div class="infos-container">
          <div class="info-item">
            <i class="bi bi-clock info-icon"></i>

            <span class="info-value">{{ promo.quantityByFrequence + ' crédito(s) diariamente' }}</span>
          </div>

          <div class="info-item">
            <i class="bi bi-calendar-event info-icon"></i>

            <span class="info-value">
              Válido de {{ promo.startDate | date: 'dd/MM/yyyy' }} até {{ promo.endDate | date: 'dd/MM/yyyy' }}</span
            >
          </div>
        </div>

        <button class="history-button" (click)="openCoinsHistoryModal(promo)">
          <i class="bi bi-clock-history history-icon"></i>
        </button>
      </div>
    </div>
  </div>
</div>
