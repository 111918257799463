<div class="modal-header">
  <span class="title">Histórico na promoçāo {{ promo.promoName }}</span>
  <button type="button" class="btn-close" aria-label="Close" (click)="onCloseClick()"></button>
</div>

<div class="modal-body content">
  <div class="promo-history-box" *ngFor="let promoHistory of promo.creditsHistory">
    <div class="action-type">
      <span class="action-description" [style.color]="formattedOperationTypeColor[promoHistory.operationType]">
        {{ formattedOperationType[promoHistory.operationType] }}</span
      >
    </div>

    <div class="amount">
      <span *ngIf="promoHistory.operationType !== 'ADD_CREDIT' && promoHistory.operationType !== 'REMOVE_CREDIT'">-</span>
      <span
        class="amount-value"
        *ngIf="promoHistory.operationType === 'ADD_CREDIT' || promoHistory.operationType === 'REMOVE_CREDIT'"
        [style]="promoHistory.operationType === 'ADD_CREDIT' ? 'color: #10b981' : 'color: #f87171'"
        >{{ promoHistory.operationType === 'ADD_CREDIT' ? '+ ' : '- ' }} {{ promoHistory.operationAmount }}</span
      >
    </div>

    <div class="date">
      <span class="action-date-time">{{ promoHistory.createDate | date: 'dd/MM/yyyy - HH:mm' }}</span>
    </div>
  </div>
</div>

<div class="footer">
  <div class="buttons-container">
    <!-- <button type="button" class="cancel-button" (click)="onCloseClick()">Cancelar</button>
    <button type="button" class="save-button" (click)="onSaveClick()">Salvar</button> -->
  </div>
</div>
