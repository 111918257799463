import { Pais } from "./pais";

export interface IContact {
  type: string;
  value: string;
  pais?: Pais;
}

export interface IDisplayedContact extends IContact {
  suspended: boolean;
}

export class Contact implements IContact {
  type: string;
  value: string;
  pais?: Pais;
  constructor(
  ) {
    this.type = '';
    this.value = '';
  }
}
