import { CommonModule, JsonPipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NgbAccordionModule,
  NgbAlertModule,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbPopoverModule,
  NgbRatingModule,
  NgbTimepickerModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DndModule } from 'ngx-drag-drop';
import { ImageCropperModule } from 'ngx-image-cropper';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxSimpleTextEditorModule } from 'ngx-simple-text-editor';
import { AparenciaComponent } from '../aparencia/aparencia.component';
import { HttpLoaderFactory } from '../app.module';
import { ContaComponent } from '../conta/conta.component';
import { CheckPreferencesModalComponent } from '../contacts-and-connections/check-preferences/check-preferences.component';
import { ContactsAndConnectionsComponent } from '../contacts-and-connections/contacts-sent.component';
import { IndicateResourcesModalComponent } from '../contacts-and-connections/indicate-resources/indicate-resources.component';
import { RemoveConnectionModalComponent } from '../contacts-and-connections/remove-connection/remove-connection.componente';
import { UserPresentationModalComponent } from '../contacts-and-connections/user-presentation/user-presentation.component';
import { DashboardCardComponent } from '../dashboard-card/dashboard-card.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { CustomAdapter } from '../datepicker/custom-adapter';
import { CustomDateParserFormatter } from '../datepicker/custom-date-parser';
import { CreateCommunityModalComponent } from '../events/create-community-modal/create-community-modal.component';
import { CreateEventModalComponent } from '../events/create-event-modal/create-event-modal.component';
import { DeleteEventModalComponent } from '../events/delete-event-modal/delete-event-modal.component';
import { EventDetailsComponent } from '../events/event-details/event-details.component';
import { EventsComponent } from '../events/events.component';
import { HomeComponent } from '../home/home.component';
import { ExceedPlanResourcesModalComponent } from '../links/exceed-plan-resources/exceeded-plan-resources.component';
import { LinksComponent } from '../links/links.component';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { NetworksSelectorComponent } from '../networks-selector/networks-selector.component';
import { PreviewModalComponent } from '../preview-modal/preview-modal.component';
import { ProfilePreviewComponent } from '../profile-preview/profile-preview.component';
import { PublicEventComponent } from '../public-event/public-event.component';
import { SaleDashboardCardComponent } from '../sale-dashboard-card/sale-dashboard-card.component';
import { JoinResellerProgramComponent } from '../sales-dashboard/join-reseller-program/join-reseller-program.component';
import { SalesDashboardComponent } from '../sales-dashboard/sales-dashboard.component';
import { SluperCoinsHistoryModalComponent } from '../sluper-coins/coins-history-modal/coins-history-modal.component';
import { SLuperCoinsComponent } from '../sluper-coins/sluper-coins.component';
import { UserProfileComponent } from './user-profile.component';
import { EventNpsComponent } from '../events/event-nps/event-nps.component';
import { BusinessComponent } from '../business/business.component';
import { ConfirmaDesvinculoModalComponent } from '../licencas/confirma-desvinculo-modal/confirma-desvinculo-modal.component';
import { DadosModalComponent } from '../licencas/dados-modal/dados-modal.component';
import { ConfirmaDesvinculoBusinessModalComponent } from '../business/confirma-desvinculo-business-modal/confirma-desvinculo-business-modal.component';
import { SluperCoinModalComponent } from '../business/sluper-coin-modal/sluper-coin-modal.component';
import { VinculaLicencaBusinessModalComponent } from '../business/vincula-licenca-business-modal/vincula-licenca-business-modal.component';
import { GerarLicencasModalComponent } from '../licencas/gerar-licencas-modal/gerar-licencas-modal.component';
import { ReplaceLicensesOwnerComponent } from '../licencas/manage-license-owner-modal/replace-licenses-owner/replace-licenses-owner.component';
import { AssignLicenseOwnerComponent } from '../licencas/manage-license-owner-modal/assign-license-owner/assign-license-owner.component';
import { LicencasComponent } from '../licencas/licencas.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ManageLicensesOwnerModalComponent } from '../licencas/manage-license-owner-modal/manage-license-owner-modal.component';
import { UserSettingsComponent } from '../licencas/manage-license-owner-modal/user-settings/user-settings.component';
import { VinculaLicencaModalComponent } from '../licencas/vincula-licenca-modal/vincula-licenca-modal.component';
import { BusinessTemplatesComponent } from '../business-templates/business-templates.component';
import { CreateTemplateComponent } from '../business-templates/create-template/create-template.component';
import { DashboardCardNewComponent } from '../dashboard-card-new/dashboard-card-new.component';
import { LoginPreviewComponent } from '../login-preview/login-preview.component';

const maskConfig: Partial<IConfig> = {
  validation: true, // Isso habilita a validação da máscara
};

@NgModule({
  declarations: [
    UserProfileComponent,
    AparenciaComponent,
    LinksComponent,
    ContaComponent,
    ContactsAndConnectionsComponent,
    EventDetailsComponent,
    EventNpsComponent,
    DashboardComponent,
    DashboardCardComponent,
    SaleDashboardCardComponent,
    SLuperCoinsComponent,
    SalesDashboardComponent,
    ProfilePreviewComponent,
    NetworksSelectorComponent,
    PreviewModalComponent,
    CreateEventModalComponent,
    CreateCommunityModalComponent,
    DeleteEventModalComponent,
    LoadingSpinnerComponent,
    ExceedPlanResourcesModalComponent,
    IndicateResourcesModalComponent,
    CheckPreferencesModalComponent,
    UserPresentationModalComponent,
    RemoveConnectionModalComponent,
    EventsComponent,
    // CropLinkImageModalComponent,
    SluperCoinsHistoryModalComponent,
    JoinResellerProgramComponent,
    PublicEventComponent,
    HomeComponent,
    BusinessComponent,
    DadosModalComponent,
    ConfirmaDesvinculoModalComponent,
    ConfirmaDesvinculoBusinessModalComponent,
    SluperCoinModalComponent,
    VinculaLicencaBusinessModalComponent,
    GerarLicencasModalComponent,
    ReplaceLicensesOwnerComponent,
    AssignLicenseOwnerComponent,
    LicencasComponent,
    ManageLicensesOwnerModalComponent,
    UserSettingsComponent,
    VinculaLicencaModalComponent,
    BusinessTemplatesComponent,
    CreateTemplateComponent,
    DashboardCardNewComponent,
    LoginPreviewComponent
  ],
  imports: [
    CommonModule,
    ImageCropperModule,
    BrowserModule,
    NgxSimpleTextEditorModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgApexchartsModule,
    NgbNavModule,
    NgbAccordionModule,
    NgbTypeaheadModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbAlertModule,
    NgbRatingModule,
    MatTooltipModule,
    JsonPipe,
    DndModule,
    QRCodeModule,
    NgxMaskModule.forRoot(maskConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgSelectModule,
    NgbPopoverModule,
    MatPaginatorModule,
    MatTableModule,
    ClipboardModule,
    NgbDropdownModule
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
  exports: [
    LoadingSpinnerComponent,
    PreviewModalComponent,
    ProfilePreviewComponent,
    NetworksSelectorComponent
  ]
})
export class UserProfileModule { }
