import { HttpResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { BOLD_BUTTON, EditorConfig, FONT_SIZE_SELECT, FORE_COLOR, ITALIC_BUTTON, SEPARATOR, UNDO_BUTTON } from 'ngx-simple-text-editor';
import { Subject, debounceTime, filter, map } from 'rxjs';
import { Link } from '../model/link';
import { LISTA_PAIS, Pais } from '../model/pais';
import { Personal } from '../model/personal.model';
import { Phone } from '../model/phone.model';
import { IDocumentPreferences } from '../model/preferences.model';
import { Profissional } from '../model/professional.model';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import { UserProfileService } from '../user-profile/user-profile.service';
import { DocumentUser, IDocumentUser } from './../model/document-user.model';

@Component({
  selector: 'app-conta',
  templateUrl: './conta.component.html',
  styleUrls: ['./conta.component.css'],
})
export class ContaComponent implements OnInit {
  private _alert = new Subject<string>();
  alertMessage = '';
  alertType = '';
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert:
    | NgbAlert
    | undefined;

  links: Link[] = [];
  linkErrors: string[] = [];
  listURICopy: Link[] = [];

  @Input() userProfile!: IUserProfileWithCSS | null;
  @Output() linkRequired = new EventEmitter<boolean>();
  @Output() contaSaved = new EventEmitter<boolean>();
  @Input() preferences!: IDocumentPreferences[];
  @Input() occupations!: IDocumentPreferences[];
  userPreferences!: IDocumentPreferences[];

  screenWidth: any;
  screenHeight: any;

  previewModalOpen = false;

  paises: Pais[] = LISTA_PAIS;
  documentUser: IDocumentUser = new DocumentUser(
    '',
    '',
    '',
    '',
    false,
    new Personal('', '', '', new Phone('', ''), ''),
    new Profissional('', '', '', '', new Phone('', ''), '', ''),
    { licence: 15 },
    '',
    false
  );

  paisTelefone: Pais | undefined = undefined;
  paisTelefoneCorporativo: Pais | undefined = undefined;
  telefone?: string = '';
  company: string = '';
  telefoneCorporativo?: string = '';
  tamanhoEmpresa = '';

  userIdentifierValidationChecks = {
    length: false,
    blankSpace: false,
    specialCharacters: false,
    duplicated: false,
  }
  startUserId = '';


  selectedInterests: IDocumentPreferences[] = [];
  selectedOccupations: number[] = [];
  selectedPreferences: number[] = [];

  allowAccessOnlyByNFC = false;
  publicSearch = false;
  autoApprove = false;
  myPresentation = '';
  myPresentationChanged = false;
  profileTemplateConfigsEnabled = false;

  config: EditorConfig = {
    placeholder: 'Type something...',
    // buttons: [UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, FORE_COLOR, FONT_SIZE_SELECT, SEPARATOR, JUSTIFY_LEFT_BUTTON, JUSTIFY_CENTER_BUTTON, JUSTIFY_RIGHT_BUTTON, JUSTIFY_FULL_BUTTON]
    buttons: [UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, FORE_COLOR, FONT_SIZE_SELECT]
  };

  constructor(private userProfileService: UserProfileService,
    private formBuilder: FormBuilder
  ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    if (this.userProfile) {
      this.getUserPreferences();

      this.profileTemplateConfigsEnabled = !this.userProfile.documentUserCSS.locked;

      if (this.userProfile.documentUserProfile.listURI.length === 0) {
        this.links.push({ link: '' });
        this.validateLink({ link: '' }, 0);
        this.linkRequired.emit(true);
      }

      if (this.userProfile.documentUserProfile !== null && this.userProfile.documentUserProfile.listURI.length > 0) {
        this.validateLink({ link: this.userProfile.documentUserProfile.listURI[0].link }, 0);

        this.listURICopy = JSON.parse(JSON.stringify(this.userProfile.documentUserProfile.listURI));
        this.links = this.userProfile.documentUserProfile.listURI;
        this.startUserId = this.userProfile.documentUserProfile.listURI[0].link;
        this.linkErrors = new Array(this.links.length).fill('');
      }

      if (this.userProfile.documentUserProfile && this.userProfile.documentUserProfile.allowAccessOnlyByNFC) {
        this.allowAccessOnlyByNFC = this.userProfile.documentUserProfile.allowAccessOnlyByNFC;
      }

      if (this.userProfile.documentUserProfile && this.userProfile.documentUserProfile.publicSearch) {
        this.publicSearch = this.userProfile.documentUserProfile.publicSearch;
      }

      if (this.userProfile.documentUserProfile && this.userProfile.documentUserProfile.autoApprove) {
        this.autoApprove = this.userProfile.documentUserProfile.autoApprove;
      }

      if (this.userProfile.documentUserProfile && this.userProfile.documentUserProfile.presentation) {
        this.myPresentation = this.userProfile.documentUserProfile.presentation;
      }

      if (this.userProfile.documentUser !== null) {
        this.documentUser = this.userProfile.documentUser;
        if (this.documentUser.professional == null) {
          this.documentUser.professional = new Profissional(
            '',
            '',
            '',
            '',
            new Phone('', ''),
            '',
            ''
          );
          this.linkRequired.emit(true);
        }
        if (this.documentUser.personal === null) {
          this.documentUser.personal = new Personal(
            this.userProfile.documentUserProfile.name,
            '',
            '',
            new Phone('', ''),
            '',
          );
          this.linkRequired.emit(true);
        }

        if (this.userProfile.documentUser.personal.phone) {
          this.telefone =
            this.userProfile.documentUser.personal.phone.phoneNumber;
          this.paisTelefone = this.paises.find(
            (p) =>
              p.sigla ==
              this.userProfile?.documentUser.personal.phone.siglaPais,
          );
        } else {
          this.userProfile.documentUser.personal.phone = new Phone('', '');
        }

        if (this.userProfile.documentUser.professional.phone) {
          this.telefoneCorporativo =
            this.userProfile.documentUser.professional.phone.phoneNumber;
          this.paisTelefoneCorporativo = this.paises.find(
            (p) =>
              p.sigla ==
              this.userProfile?.documentUser.professional.phone.siglaPais,
          );
        } else {
          this.userProfile.documentUser.professional.phone = new Phone('', '');
        }
      }
    }

    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  formatDate(event: any) {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    if (value.length > 8) {
      value = value.slice(0, 8); // Limita a entrada a 8 caracteres (dd/mm/yyyy)
    }

    if (value.length >= 2) {
      value = value.substring(0, 2) + '/' + value.substring(2);
    }
    if (value.length >= 5) {
      value = value.substring(0, 5) + '/' + value.substring(5);
    }

    input.value = value;
  }

  addLink() {
    this.links.push({ link: '' });
    this.linkErrors.push('');
  }

  removeLink(i: number) {
    this.links.splice(i, 1);
    this.linkErrors.splice(i, 1);
  }

  changePaisTelefoneSelecionado() {
    this.telefone = '';
  }

  changePaisTelefoneCorporativoSelecionado() {
    this.telefoneCorporativo = '';
  }

  save() {
    this.validateAllLinks();

    const linkValidations = [
      this.userIdentifierValidationChecks.length,
      this.userIdentifierValidationChecks.blankSpace,
      this.userIdentifierValidationChecks.specialCharacters,
      this.userIdentifierValidationChecks.duplicated
    ];

    if (this.linkErrors.some((error) => error !== '') || linkValidations.some((validation) => validation === true)) {
      this.alertType = 'danger';
      this._alert.next('Corrija os erros nos links antes de salvar.');

      return;
    }

    if (this.camposObrigatorios()) {
      this.alertType = 'danger';
      this._alert.next(
        'Peencha todos os campos obrigatório. Nome, Sobrenome, Email, Data Nascimento, País e CEP.',
      );

      return;
    }

    const hasUserLinksChanged = this.areLinkListsIdentical(
      this.links,
      this.listURICopy,
    );

    const isPublicSearchChanged = (this.publicSearch && (this.userProfile?.documentUserProfile.publicSearch === undefined || this.userProfile?.documentUserProfile.publicSearch === null || this.userProfile?.documentUserProfile.publicSearch === false)) ||
      (!this.publicSearch && this.userProfile?.documentUserProfile.publicSearch === true);

    const isAllowAccessByAllURIChanged = (this.allowAccessOnlyByNFC && (this.userProfile?.documentUserProfile.allowAccessOnlyByNFC === undefined || this.userProfile?.documentUserProfile.allowAccessOnlyByNFC === null || this.userProfile?.documentUserProfile.allowAccessOnlyByNFC === false)) ||
      (!this.allowAccessOnlyByNFC && this.userProfile?.documentUserProfile.allowAccessOnlyByNFC === true);


    const isAutoApproveChanged = (this.autoApprove && (this.userProfile?.documentUserProfile.autoApprove === undefined || this.userProfile?.documentUserProfile.autoApprove === null || this.userProfile?.documentUserProfile.autoApprove === false)) ||
            (!this.autoApprove && this.userProfile?.documentUserProfile.autoApprove === true);

    if (this.userProfile && (!hasUserLinksChanged || isPublicSearchChanged || this.myPresentationChanged || isAllowAccessByAllURIChanged || isAutoApproveChanged)) {
      this.saveDocumentUserProfile(!hasUserLinksChanged, isPublicSearchChanged, isAllowAccessByAllURIChanged, isAutoApproveChanged);
    } else {
      this.saveDocumentUser();
    }
  }

  camposObrigatorios(): boolean {
    if (
      this.documentUser.email &&
      this.documentUser.personal.birthdate &&
      this.documentUser.personal.cep &&
      this.documentUser.personal.country &&
      this.documentUser.personal.lastName &&
      this.documentUser.personal.name
    ) {
      return false;
    }
    return true;
  }

  saveDocumentUserProfile(hasUserLinksChanged: boolean, isPublicSearchChanged: boolean, isAllowAccessByAllURIChanged: boolean, isAutoApproveChanged: boolean) {
    if (this.userProfile) {
      if (hasUserLinksChanged) {

        this.userProfile.documentUserProfile.listURI = Object.assign(
          [],
          this.links,
        );
      }

      if (isAllowAccessByAllURIChanged) {
        this.userProfile.documentUserProfile.allowAccessOnlyByNFC = this.allowAccessOnlyByNFC;
      }

      if (isPublicSearchChanged) {
        this.userProfile.documentUserProfile.publicSearch = this.publicSearch;
      }

      if (this.myPresentationChanged) {
        this.userProfile.documentUserProfile.presentation = this.myPresentation;
      }

      if(isAutoApproveChanged) {
        this.userProfile.documentUserProfile.autoApprove = this.autoApprove;
      }

      this.userProfileService
        .saveDocumentUserProfile(this.userProfile.documentUserProfile)
        .pipe(
          filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
          map((response: HttpResponse<any>) => response.body),
        )
        .subscribe({
          next: async () => {
            if (this.userProfile) {
              this.listURICopy = JSON.parse(
                JSON.stringify(this.userProfile.documentUserProfile.listURI),
              );
            }
            await this.saveUserPublicSearch();
            this.saveDocumentUser();
          },
          error: () => {
            this.alertType = 'danger';
            this._alert.next(
              'Não foi possível salvar a conta. Contacte o administrador.',
            );
          },
        });
    }
  }

  saveDocumentUser() {
    if (this.userProfile) {
      this.saveUserPreferences();
      if (this.paisTelefone && this.telefone) {
        this.userProfile.documentUser.personal.phone.ddi =
          this.paisTelefone?.codigo;
        this.userProfile.documentUser.personal.phone.phoneNumber =
          this.telefone;
        this.userProfile.documentUser.personal.phone.siglaPais =
          this.paisTelefone?.sigla;
      } else {
        if (this.paisTelefone || this.telefone) {
          this.alertType = 'danger';
          this._alert.next('Favor preencher o telefone completo.');

          return;
        } else {
          this.userProfile.documentUser.personal.phone = {};
        }
      }

      if (this.paisTelefoneCorporativo && this.telefoneCorporativo) {
        this.userProfile.documentUser.professional.phone.ddi = this.paisTelefoneCorporativo?.codigo;
        this.userProfile.documentUser.professional.phone.phoneNumber = this.telefoneCorporativo;
        this.userProfile.documentUser.professional.phone.siglaPais = this.paisTelefoneCorporativo?.sigla;
      } else {
        if (this.paisTelefoneCorporativo || this.telefoneCorporativo) {
          this.alertType = 'danger';
          this._alert.next('Favor preencher o telefone corporativo completo.');

          return;
        } else {
          this.userProfile.documentUser.professional.phone = {};
        }
      }

      this.userProfileService
        .saveDocumentUser(this.userProfile.documentUser)
        .pipe(
          filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
          map((response: HttpResponse<any>) => response.body),
        )
        .subscribe({
          next: () => {
            this.alertType = 'success';
            this._alert.next('Conta salva com sucesso.');
            this.linkRequired.emit(false);
            this.contaSaved.emit();
          },
          error: () => {
            this.alertType = 'danger';
            this._alert.next(
              'Não foi possível salvar a conta. Contacte o administrador.',
            );
          },
        });
    }
  }

  areLinkListsIdentical(list1: Link[], list2: Link[]): boolean {
    if (list1.length !== list2.length) {
      return false;
    }

    for (let i = 0; i < list1.length; i++) {
      if (list1[i].link !== list2[i].link) {
        return false;
      }
    }

    return true;
  }

  validateLink(link: { link: string }, index: number, e?: any) {
    if (e) {
      e.target.value = e.target.value.toLowerCase();
    }

    if (link.link && this.userProfile?.documentUserProfile.listURI) {
      const linkValue = link.link.toLowerCase();

      if (linkValue.trim().length < 3 || linkValue.trim().length > 20) {
        this.userIdentifierValidationChecks.length = true;

        return false;
      }

      this.userIdentifierValidationChecks.length = false;
      if (/\s/.test(linkValue)) {
        this.userIdentifierValidationChecks.blankSpace = true;

        return false;
      }

      this.userIdentifierValidationChecks.blankSpace = false;
      if (!/^[a-z0-9_.]+$/.test(linkValue)) {
        this.userIdentifierValidationChecks.specialCharacters = true;

        return false;
      }

      this.userIdentifierValidationChecks.specialCharacters = false;
      const isLinkDuplicate = this.links.some(
        (item, i) => i !== index && item.link === linkValue,
      );

      if (isLinkDuplicate) {
        this.userIdentifierValidationChecks.duplicated = true;

        return false;
      }

      this.userIdentifierValidationChecks = {
        blankSpace: false,
        duplicated: false,
        length: false,
        specialCharacters: false,
      }
      this.startUserId = linkValue;
      link.link = linkValue;

      return true;
    } else {
      this.userIdentifierValidationChecks.length = true;
      this.userIdentifierValidationChecks.blankSpace = true;
      this.userIdentifierValidationChecks.specialCharacters = true;
      this.userIdentifierValidationChecks.duplicated = true;
      return false;
    }
  }

  async validateAvailableLink(linkValue: string) {
    this.company = this.userProfile?.documentUser.company || '';
    return this.userProfileService.validaLinkDisponivel(linkValue, this.company).subscribe({
      next: (response: boolean) => {
        const formattedLinkErrors = this.linkErrors.filter(le => !le.includes(' não esta disponível.'));

        this.linkErrors = formattedLinkErrors;

        if (response === true) {
          this.startUserId = linkValue;

          return true;
        } else if (this.listURICopy.length < 1 || linkValue !== this.listURICopy[0]?.link) {
          this.linkErrors.push('O link ' + linkValue + ' não esta disponível.');

          if (this.userProfile && this.listURICopy.length > 0) {
            this.userProfile.documentUserProfile.listURI = [{ link: this.listURICopy[0].link }];
          }

          return false;
        } else {
          this.linkErrors = [];
          this.startUserId = linkValue;
          this.startUserId = this.listURICopy[0].link;

          return true;
        }
      },
      error: () => {
        this.linkErrors.push('O link ' + linkValue + ' não esta disponível.');

        if (this.userProfile && this.listURICopy.length > 0) {
          this.userProfile.documentUserProfile.listURI = [{ link: this.listURICopy[0].link }];
        }

        return false;
      },
    });
  }

  validateAllLinks() {
    for (let i = 0; i < this.links.length; i++) {
      this.validateLink(this.links[i], i);
    }
  }

  async fixLink(link: { link: string }) {
    await this.validateAvailableLink(link.link);
    const isValidLink = this.validateLink(link, 0);

    if (!isValidLink && this.userProfile) {
      this.userProfile.documentUserProfile.listURI = [{ link: this.startUserId }];
    }
  }

  onPaste(event: any) {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('text/plain');
    const sanitizedData = clipboardData.replace(/[-?]/g, '');
    document.execCommand('insertText', false, sanitizedData);
  }

  getUserPreferences() {
    if (this.userProfile && !this.userPreferences) {
      this.userProfileService
        .getUserPreferences(this.userProfile.documentUser.idUser)
        .pipe(
          filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
          map((response: HttpResponse<any>) => response.body),
        )
        .subscribe({
          next: (res: IDocumentPreferences[]) => {
            this.selectedOccupations = res.filter(up => up.type === 'AREA_ATUACAO').map(up => up.id);
            this.selectedPreferences = res.filter(up => up.type === 'INTERESSE').map(up => up.id);
          },
          error: (res) => console.log(res),
        });
    }
  }
  saveUserPreferences() {
    if (!this.userProfile) {
      return;
    }

    const selectedInterests: IDocumentPreferences[] = this.preferences.filter(p => this.selectedPreferences.find(sp => sp === p.id));
    const selectedOccupations: IDocumentPreferences[] = this.occupations.filter(p => this.selectedOccupations.find(sp => sp === p.id));

    const selectedPreferences = selectedInterests.concat(selectedOccupations);

    this.userProfileService
      .saveUserPreferences(this.userProfile?.documentUserProfile.idUser, selectedPreferences)
      .pipe(
        filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
        map((response: HttpResponse<any>) => response.body),
      )
      .subscribe({
        next: () => {
          console.log('Preferências salvas com sucesso.');
        },
        error: () => {
          this.alertType = 'danger';
          this.alertMessage = `Erro ao salvar preferencias do usuário.`;
          this._alert.next(`Erro ao salvar preferencias do usuário.`);
        },
      });
  }

  async saveUserPublicSearch() {
    if (this.userProfile) {
      try {
        const response = await this.userProfileService
          .saveUserPublicSearch(this.publicSearch)
          .toPromise();
        console.log("Pesquisa publica salva com sucesso.");
      } catch (error) {
        console.log("Erro ao salvar flag de pesquisa pública.");
      }
    }
  }
}
