import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime } from 'rxjs';

export interface IContact {
  id: string;
  idUser: string;
  contactCompany: string;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  sharingDate: string;
  ip: string;
  hidden: boolean;
}

@Component({
  selector: 'app-public-event',
  templateUrl: './public-event.component.html',
  styleUrls: ['./public-event.component.css'],
})
export class PublicEventComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;
  private _alert = new Subject<string>();
  alertMessage = '';
  alertType = '';

  eventId = '';
  loading = true;

  constructor(private route: ActivatedRoute,) {
    return;
  }

  ngOnInit(): void {
    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });

    this.route.params.subscribe((params) => {
      const eventId = params['eventId'];

      if(!eventId) {
        this._alert.next('Evento não encontrado.');
        this.alertType = 'danger';

        this.loading = false;
        return;
      }

      this.eventId = eventId;
      this.loading = false;
    });
  }
}
