import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppNavigateService } from 'src/app/app-navigate.service';
import { LoginService } from 'src/app/login/login.service';

@Component({
  selector: 'app-header-inicial',
  templateUrl: './header-inicial.component.html',
  styleUrls: ['./header-inicial.component.css']
})
export class HeaderInicialComponent implements OnInit {
  @Input() layoutColorido: boolean = false;
  classColorido = '';
  borderRight = 'border-right-branco';
  urlImagemLogin = '';

  constructor(private appNavigateService: AppNavigateService,
              private router: Router,
              private loginService: LoginService
    ) {
      this.getLoginImage();
  }

  ngOnInit(): void {
    if (this.layoutColorido == true) {
      this.classColorido = 'text-roxo'
      this.borderRight = 'border-right-roxo'
    }
  }

  navegarLogin() {
    this.appNavigateService.navegarLogin();
  }

  navegarCadastro() {
    this.appNavigateService.navegarCadastro();
  }

  navegarSluperCard() {
  }

  getLoginImage() {
    this.loginService.getLoginImage(window.location.hostname).subscribe({
      next: (response: string) => {
        this.urlImagemLogin = response;
      },
      error: (error) => {
        if(this.layoutColorido == true){
         this.urlImagemLogin = '../../../../assets/img-sluper/logo-sluper-colorido.png';
        }else {
          this.urlImagemLogin = '../../../../assets/img-sluper/logo-sluper-digital.png';
        }
        if (error.status === 404) {
          console.warn('Imagem de cabeçalho não existe, exibindo cabeçalho padrão (404).');
        } else {
          console.error('Erro ao buscar a imagem de login:', error);
        }
      },
    });
  }
}
