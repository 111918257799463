<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<div class="modal-body content">
  <div class="select-all-container">
    <input class="select-all-input" type="checkbox" [checked]="selectedAll" id="select-all-checkbox"
      (change)="handleSelectAllLicenses()">
    <label class="labels-text" for="select-all-checkbox">
      Selecionar todas
    </label>
  </div>

  <div class="licenses-list-container">
    <button class="license-card" *ngFor="let license of licenses"
      (click)="handleToggleLicenseSelection(license.documentLicense.license)"
      [ngClass]="selectedLicenses.includes(license.documentLicense.license) ? 'selected-license' : 'license-card'">
      <div class="select-license-column">
        <input class="select-all-input" type="checkbox" value="" id="select-license-checkbox"
          [checked]="selectedLicenses.includes(license.documentLicense.license)"
          (change)="handleToggleLicenseSelection(license.documentLicense.license)">
      </div>

      <div class="license-id-column">
        <span class="license-id license-card-value">{{license.documentLicense.license}}</span>
      </div>

      <div class="license-date-column">
        <span class="license-date license-card-value">{{license.documentLicense.createDate | date: 'dd/MM/yyyy'}}</span>
      </div>

      <div class="license-email-column">
        <span class="license-id license-card-value">{{license?.documentUser?.email || ''}}</span>
      </div>
    </button>
  </div>
</div>

<div class="footer">
  <div class="ownership-container">
    <div class="remove-ownership-container">
      <input class="remove-ownership-input" type="checkbox" [checked]="removeOwnership" id="remove-ownership"
        (change)="handleRemoveLicensesOwnership()">

      <label class="labels-text" for="remove-ownership">
        Remover administraçāo de licenças selecionadas
      </label>
    </div>

    <div class="new-owner-input-container">
      <label for="owner" class="labels-text">Transferir licenças selecionadas para o usuario:</label>
      <div class="input-group inputs">
        <div class="input-group-prepend">
          <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-envelope"></i></span>
        </div>

        <input [disabled]="removeOwnership" type="text" class="form-control input-text" id="owner" name="owner"
          [(ngModel)]="newOwnerEmail">
      </div>
    </div>
  </div>

  <div class="buttons-container">
    <button type="button" class="cancel-button" (click)="onCloseClick()">Cancelar</button>
    <button type="button" class="save-button" (click)="handleReplaceLicensesOwner()">Salvar</button>
  </div>
</div>
