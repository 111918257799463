
export interface ILinkLicenseRequest {
    license: string;
    email: string;
}

export class LinkLicenseRequest implements ILinkLicenseRequest {
    constructor(
        public license: string,
        public email: string
    ) { }
}
