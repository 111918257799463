import { IPhone } from './phone.model';

export interface IPersonal {
  name: string;
  lastName: string;
  birthdate?: Date;
  country: string;
  phone: IPhone;
  cep: string;
}

export class Personal implements IPersonal {
  constructor(
    public name: string,
    public lastName: string,
    public country: string,
    public phone: IPhone,
    public cep: string,
  ) {}
}
