import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { addDays, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexStroke,
  ApexTooltip,
  ApexXAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { EventsService } from '../events/events.service';
import { IDashboardUser } from '../model/dashboard.model';
import { IUserProfilePlan } from '../model/user-plan.model';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import {
  UserProfileService
} from '../user-profile/user-profile.service';

interface IFormattedDashboardData {
  acessedLinksCount: number;
  profileAccessCount: number;
  socialNetworkAcessedCount: number;
  profileAccessByWeeks: number[];
  linksClickedByWeeks: number[];
  socialNetworkByWeeks: number[];
  topAccessedSocialNetworks: {
    url: string;
    count: number;
  }[];
  topAccessedLinks: {
    url: string;
    count: number;
  }[];
  updateDate?: Date;
}

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnChanges {
  @ViewChild('chart') chart?: ChartComponent;
  public chartOptions: ChartOptions = {
    series: [],
    chart: {
      height: 350,
      type: 'area',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: [],
    },
    tooltip: {},
  };
  @Input() userProfile!: IUserProfileWithCSS | null;
  @Input() userProfilePlan!: IUserProfilePlan;
  @Output() linkRequired = new EventEmitter<boolean>();

  dashboardData: IFormattedDashboardData | null = null;
  dashboardUser: IDashboardUser | null = null;

  screenWidth: any;
  screenHeight: any;

  constructor(
    private userProfileService: UserProfileService,
    private eventsService: EventsService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
  ) {
    this.getScreenSize();
    this.initializeChartOptions();
    this.buscaDashboardEvents();
  }

  ngAfterViewInit() {
    this.loadChartData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userProfilePlan']) {
      this.loadChartData();
    }
  }

  initializeChartOptions() {
    const lastForWeeksPeriods: string[] = [];
    const currentDate = new Date();

    for (let i = 0; i < 4; i++) {
      const currentWeekStartDate = addDays(currentDate, -7 * (i + 1));
      const currentWeekEndDate = addDays(currentWeekStartDate, i === 0 ? 7 : 6);

      lastForWeeksPeriods.push(
        `${format(currentWeekStartDate, "dd'/'MM'/'yyyy", {
          locale: ptBR,
        })} a ${format(currentWeekEndDate, "dd'/'MM'/'yyyy", {
          locale: ptBR,
        })}`,
      );
    }

    this.chartOptions.xaxis.categories = lastForWeeksPeriods.reverse();
  }

  loadChartData() {
    if (this.userProfilePlan && this.userProfilePlan.profilePlanLimits.dashViews) {
      const lastForWeeksPeriods: string[] = [];
      const currentDate = new Date();

      for (let i = 0; i < 4; i++) {
        const currentWeekStartDate = addDays(currentDate, -7 * (i + 1));
        const currentWeekEndDate = addDays(currentWeekStartDate, i === 0 ? 7 : 6);

        lastForWeeksPeriods.push(
          `${format(currentWeekStartDate, "dd'/'MM'/'yyyy", {
            locale: ptBR,
          })} a ${format(currentWeekEndDate, "dd'/'MM'/'yyyy", {
            locale: ptBR,
          })}`
        );
      }

      this.userProfileService.getDashboardData().subscribe(({ body }) => {
        if (body) {
          this.dashboardData = {
            acessedLinksCount: body.acessedLinksCount,
            profileAccessByWeeks: body.profileAccessByWeeks,
            linksClickedByWeeks: body.linksClickedByWeeks,
            socialNetworkByWeeks: body.socialNetworkByWeeks,
            profileAccessCount: body.profileAccessCount,
            socialNetworkAcessedCount: body.socialNetworkAcessedCount,
            topAccessedLinks: body.topAccessedLinks,
            topAccessedSocialNetworks: body.topAccessedSocialNetworks,
            updateDate: body.updateDate,
          };

          this.chartOptions.series = [
            {
              name: 'Perfil',
              data: this.dashboardData.profileAccessByWeeks.reverse(),
            },
            {
              name: 'Redes sociais',
              data: this.dashboardData.socialNetworkByWeeks.reverse(),
            },
            {
              name: 'Links',
              data: this.dashboardData.linksClickedByWeeks.reverse(),
            },
          ];

          this.chartOptions.xaxis.categories = lastForWeeksPeriods.reverse();
          this.cdr.detectChanges();
        }
      });
    }
  }

  getDashboardInfo({ page, size }: { page: number; size: number }): void {
    this.userProfileService.getDashboardData().subscribe(({ body }) => {
      if (body) {
        const socialNetworkMetrics = body.socialNetworkMetrics.filter(
          (metric) => metric.infoGroup === 'SocialNetwork',
        );

        this.dashboardData = {
          acessedLinksCount: body.acessedLinksCount,
          profileAccessCount: body.profileAccessCount,
          profileAccessByWeeks: body.profileAccessByWeeks,
          linksClickedByWeeks: body.linksClickedByWeeks,
          topAccessedLinks: body.topAccessedLinks,
          topAccessedSocialNetworks: body.topAccessedSocialNetworks,
          socialNetworkByWeeks: body.socialNetworkByWeeks,
          socialNetworkAcessedCount: body.socialNetworkAcessedCount,
          updateDate: body.updateDate,
        };
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  buscaDashboardEvents() {
    this.eventsService.getEventDashboadUser().subscribe(({ body }) => {
      if (body) {
        this.dashboardUser = body;
      }
    });
  }

  transformEventsListToLinks(): string[] {
    if (this.dashboardUser?.listEventsOrganized) {
      return this.dashboardUser.listEventsOrganized.map(event => event.name);
    }
    return [];
  }

  transformCommunityListToLinks(): string[] {
    if (this.dashboardUser?.listCommunityOrganized) {
      return this.dashboardUser.listCommunityOrganized.map(event => event.name);
    }
    return [];
  }
}
