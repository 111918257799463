<div class="dashboard-card">
  <div class="dashboard-card-title-container">
    <span class="dashboard-card-title">{{ dashboardCardData.title }}</span>

    <button *ngIf="variant === 'list-links' || variant === 'list-social-networks'" class="toggle-list-button" (click)="toggleLinkList()">
      <i *ngIf="!linkListOpened" class="bi bi-chevron-down show-more-icon"></i>
      <i *ngIf="linkListOpened" class="bi bi-chevron-up show-more-icon"></i>
    </button>
  </div>

  <div class="dashboard-card-value-container">
    <!-- <i class="bi {{ dashboardCardData.icon }} dashboard-card-icon" [style.color]="dashboardCardData.color"></i> -->
    <span class="dashboard-card-value">{{ dashboardCardData.value }}</span>
  </div>

  <div class="list-links-container" *ngIf="linkListOpened && (variant === 'list-links' || variant === 'list-social-networks')">
    <div [style.border-bottom]="'1px solid #d6d6d6'" class="link-item" *ngFor="let linkItem of dashboardCardData.links">
      <div class="link-url-container">
        <i *ngIf="variant === 'list-social-networks'" class="bi {{ getSocialNetworkIconByUrl(linkItem.url) }}"></i>
        <a class="social-link" href="linkItem.url">{{ linkItem.url }}</a>
      </div>

      <div class="access-counter-container">
        <span>
          {{ linkItem.count }}
        </span>
      </div>
    </div>
  </div>
</div>
