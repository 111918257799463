import { INetworkingPlan } from './networking-plan.model';
import { IPersonal } from './personal.model';
import { IProfissional } from './professional.model';

export interface IDocumentUser {
  idUser: string;
  email: string;
  emailKey: string;
  role: string;
  promoCode: string;
  isReseller: boolean;
  active: boolean;
  personal: IPersonal;
  professional: IProfissional;
  createDate?: string;
  updateDate?: string;
  networkingPlan: INetworkingPlan;
  company?: string;
}

export class DocumentUser implements IDocumentUser {
  constructor(
    public idUser: string,
    public email: string,
    public emailKey: string,
    public role: string,
    public active: boolean,
    public personal: IPersonal,
    public professional: IProfissional,
    public networkingPlan: INetworkingPlan,
    public promoCode: string,
    public isReseller: boolean,
  ) {}
}
