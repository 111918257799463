import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppNavigateService } from 'src/app/app-navigate.service';
import { ChaveAtivacaoInputComponent } from 'src/app/new-sluper/core/components/chave-ativacao-input/chave-ativacao-input.component';
import { LoginService } from '../../login/login.service';
import { CadastroStorageService } from '../cadastro-storage.service';

@Component({
  selector: 'app-confirmacao-criacao-conta',
  templateUrl: './confirmacao-criacao-conta.component.html',
  styleUrls: ['./confirmacao-criacao-conta.component.css']
})
export class ConfirmacaoCriacaoContaComponent implements OnInit {
  @ViewChild(ChaveAtivacaoInputComponent) codigoVerificacao!: ChaveAtivacaoInputComponent;
  license: string | null = null;
  constructor(
    private appNavigateService: AppNavigateService,
    private loginService: LoginService,
    private cadastroStorageService: CadastroStorageService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  pedirAjuda() {

  }
}
