import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-exceed-plan-resources',
  templateUrl: './exceeded-plan-resources.component.html',
  styleUrls: ['./exceeded-plan-resources.component.css'],
})
export class ExceedPlanResourcesModalComponent implements OnInit {
  @Input() descriptionMessage = '';
  @Input() callToAction = '';
  screenHeight: any;
  screenWidth: any;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    return;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  closeModal() {
    this.activeModal.close();
  }
}
