import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsModule } from '../../core/components/components.module';
import { PerfilUsuarioComponent } from './perfil-usuario.component';



@NgModule({
  declarations: [PerfilUsuarioComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPopoverModule,

  ]
})
export class PerfilUsuarioModule { }
