import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-user-presentation',
    templateUrl: './user-presentation.component.html',
    styleUrls: ['./user-presentation.component.css'],
})
export class UserPresentationModalComponent {

    @Input() userPresentation!: string;

    constructor(private activeModal: NgbActiveModal) { }

    closeModal(status: boolean) {
        this.activeModal.close(status);
    }
}