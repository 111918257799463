<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<div class="preview-button-div">
  <button type="button" class="save-float-button" (click)="save()" translate><i
      class="bi bi-check2"></i>botaoSalvarFlutuante</button>
  <button type="button" class="preview-float-button" (click)="openPreviewModal()" translate
    *ngIf="(screenWidth < desktopLimitWidth || screenHeight < desktopLimitHeight) && !previewModalOpen">
    <i class="bi bi-binoculars"></i>botaoPreview
  </button>
</div>

<div class="body-div">
  <app-loading-spinner *ngIf="saveProfileLoading"></app-loading-spinner>

  <section *ngIf="haveMoreThanOneProfile && userProfilePlan.profilePlanLimits.professionalView"
    class="profile-selector">
    <div [class]="selectedProfile.indicator === -1 ? 'profile-tab selected-tab' : 'profile-tab'"
      (click)="changeSelectedProfile(-1)">
      <i class="bi bi-person-fill"></i>
      <h2 class="profile-title">Perfil Pessoal</h2>
    </div>

    <div [class]="selectedProfile.indicator === 0 ? 'profile-tab selected-tab' : 'profile-tab'"
      (click)="changeSelectedProfile(0)">
      <i class="bi bi-briefcase-fill"></i>

      <h2 class="profile-title">Perfil Corporativo</h2>
    </div>
  </section>

  <section class="content-section"
    [style]="!haveMoreThanOneProfile || !userProfilePlan.profilePlanLimits.professionalView ? 'padding-top: 20px' : ''">
    <div class="options-div">
      <div ngbAccordion *ngIf="this.selectedProfile.indicator !== -1">
        <div ngbAccordionItem [collapsed]="false" class="collapse-container">
          <h2 ngbAccordionHeader class="collapse-container-titulo">
            <button ngbAccordionButton class="collapse-container-button" translate>links.visibility</button>
          </h2>

          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="contacts-container">
                  <div (click)="toggleProfileVisibility($event)" [matTooltip]="
                      selectedProfile.data.profileVisible ? 'Clique para tornar o perfil invisível' : 'Clique para tornar o perfil visível'
                    " class="profile-visibility-container">
                    <button class="profile-visibility-button" type="button">
                      <i class="bi bi-eye icon-large" *ngIf="this.selectedProfile.data.profileVisible"
                        style="font-size: 12px"></i>
                      <i class="bi bi-eye-slash icon-large" *ngIf="!this.selectedProfile.data.profileVisible"
                        style="font-size: 12px"></i>
                    </button>

                    <span>{{ selectedProfile.data.profileVisible ? 'Visível' : 'Invisível' }}</span>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div ngbAccordion>
        <div ngbAccordionItem [collapsed]="false" class="collapse-container">
          <h2 ngbAccordionHeader class="collapse-container-titulo">
            <button ngbAccordionButton class="collapse-container-button" translate>links.contatos</button>
          </h2>

          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="contacts-container">
                  <div class="contacts-row" *ngFor="let contato of selectedProfile.data.listContacts; let i = index">
                    <a href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
                      target="_blank" class="suspended-item-indicator"
                      matTooltip="Para reativar este contato clique no ícone ⚠️ e faça o upgrade do seu plano."
                      *ngIf="contato.suspended">
                      <i style="color: #dc691b" class="bi bi-exclamation-triangle"></i>
                    </a>

                    <div class="select-column">
                      <select class="form-select tipo-contato" [(ngModel)]="contato.type"
                        [disabled]="contato.suspended">
                        <option value=""></option>
                        <option [value]="tipoContato.value" *ngFor="let tipoContato of tiposContatos">{{
                          tipoContato.name }}</option>
                      </select>
                    </div>

                    <div class="contato-column">
                      <input [disabled]="contato.suspended" type="text" class="contato" [(ngModel)]="contato.value"
                        *ngIf="contato.type === 'localizacao'" />
                      <input [disabled]="contato.suspended" type="email" class="contato" [(ngModel)]="contato.value"
                        *ngIf="contato.type === 'email'" />
                      <div style="display: flex; flex-direction: row" *ngIf="contato.type === 'telefone'">
                        <select name="paisTelefone" class="contato pais-select" [(ngModel)]="contato.pais"
                          (change)="changePhoneCountry(i)" [disabled]="contato.suspended">
                          <option value=""></option>
                          <option [ngValue]="pais" *ngFor="let pais of paises">{{ pais.nomePais }} {{ pais.codigo }}
                          </option>
                        </select>
                        <input type="text" class="contato" [(ngModel)]="contato.value" [disabled]="contato.suspended"
                          [mask]="contato.pais?.mascara"
                          [placeholder]="contato.pais?.mascara ? contato.pais?.mascara : ''"
                          [disabled]="contato.pais ? false : true" />
                      </div>
                    </div>

                    <div class="acao-column">
                      <button type="button" class="action-button" (click)="removeContact(i)"><i
                          class="bi bi-trash"></i></button>
                    </div>
                  </div>

                  <div class="add-contact-container">
                    <button type="button" class="action-button" (click)="addContact()" [disabled]="
                        userProfilePlan.profilePlanLimits.contactsInclude <= selectedProfile.data.listContacts.length &&
                        userProfilePlan.profilePlanLimits.contactsInclude !== -1
                      ">
                      <i class="bi bi-plus-circle"></i>
                    </button>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div ngbAccordion>
        <div ngbAccordionItem [collapsed]="false" class="collapse-container">
          <h2 ngbAccordionHeader class="collapse-container-titulo">
            <button ngbAccordionButton class="collapse-container-button" translate>meuSluper.linksSociais</button>
          </h2>

          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="social-link-row"
                  *ngFor="let socialNetwork of selectedProfile.data.listSocialNetwork; let i = index">
                  <a *ngIf="socialNetwork.suspended"
                    href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
                    target="_blank" class="suspended-item-indicator"
                    matTooltip="Para reativar esta rede social clique no ícone ⚠️ e faça o upgrade do seu plano.">
                    <i style="color: #dc691b" class="bi bi-exclamation-triangle"></i>
                  </a>

                  <div class="select-column" style="width: auto;">
                    <app-networks-selector (handleChangeNetwork)="changeNetworkType($event, i)"
                      [selectedNetwork]="socialNetwork.type"></app-networks-selector>
                  </div>

                  <div class="link-column">
                    <input type="text" class="link" [(ngModel)]="socialNetwork.link"
                      [readOnly]="socialNetwork.suspended" [placeholder]="getPlaceholder(socialNetwork.type)"
                      (keyup)="handleKeyDownSocialNetwork($event, socialNetwork.type)" />
                  </div>

                  <div class="acao-column">
                    <button type="button" class="action-button" (click)="removeSocialNetwork(i)">
                      <i class="bi bi-trash"></i>
                    </button>
                  </div>
                </div>

                <div class="add-social-network-container">
                  <button [disabled]="
                      userProfilePlan.profilePlanLimits.socialLinksInclude <= selectedProfile.data.listSocialNetwork.length &&
                      userProfilePlan.profilePlanLimits.socialLinksInclude !== -1
                    " type="button" class="action-button" (click)="addSocialNetwork()">
                    <i class="bi bi-plus-circle"> </i>
                  </button>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div ngbAccordion>
        <div ngbAccordionItem [collapsed]="false" class="collapse-container">
          <h2 ngbAccordionHeader class="collapse-container-titulo">
            <button ngbAccordionButton class="collapse-container-button" translate>links.links</button>
          </h2>

          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="link-items-container" dndDropzone
                  (dndDrop)="onDrop($event, selectedProfile.data.listButtons)" dndEffectAllowed="copyMove">
                  <div class="link-item" *ngFor="let link of selectedProfile.data.listButtons; let i = index"
                    [dndDraggable]="link" dndEffectAllowed="move" [id]="link.id"
                    [style]="link.suspended ? 'background-color: #f4f4f4' : ''">
                    <a *ngIf="link.suspended"
                      href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
                      target="_blank" class="suspended-item-indicator" style="top: -6px; left: -6px"
                      matTooltip="Para reativar este link clique no ícone ⚠️ e faça o upgrade do seu plano.">
                      <i style="color: #dc691b" class="bi bi-exclamation-triangle"></i>
                    </a>

                    <div class="link-type-container">
                      <button (click)="handleChangeLinkType('DEFAULT', link.id)" class="link-type-option"
                        [disabled]="link.suspended"
                        [ngClass]="!link.type || link.type === 'DEFAULT' ? 'link-type-selected' : ''" translate>
                        links.linkTypes.default
                      </button>
                      <button (click)="handleChangeLinkType('VIDEO', link.id)" class="link-type-option"
                        [disabled]="link.suspended" [ngClass]="link.type === 'VIDEO' ? 'link-type-selected' : ''"
                        translate>
                        links.linkTypes.video
                      </button>
                      <button (click)="handleChangeLinkType('PRODUCT', link.id)" class="link-type-option"
                        [disabled]="link.suspended" [ngClass]="link.type === 'PRODUCT' ? 'link-type-selected' : ''"
                        translate>
                        links.linkTypes.product
                      </button>
                    </div>

                    <div class="link-basic-config" [id]="link.id">
                      <div class="order-container">
                        <button [disabled]="i === 0" class="order-button" (click)="changeLinkOrder(i, 'move_up')">
                          <i class="bi bi-chevron-up"></i>
                        </button>

                        <div dndHandle class="drag-icon-div" [id]="link.id">
                          <i class="bi bi-grip-vertical" [id]="link.id"></i>
                        </div>

                        <button [disabled]="i === selectedProfile.data.listButtons.length - 1" class="order-button"
                          (click)="changeLinkOrder(i, 'move_down')">
                          <i class="bi bi-chevron-down"></i>
                        </button>
                      </div>


                      <div class="container-column column-width" [id]="link.id">
                        <div class="link-input-container" [id]="link.id"
                          *ngIf="!link.type || link.type === 'DEFAULT' || link.type === 'PRODUCT'">
                          <i class="bi bi-pencil link-input-icon" [id]="link.id"
                            [style.color]="!link.title ? 'red' : ''"></i>
                          <input type="text" class="form-control title-input" [(ngModel)]="link.title" [id]="link.id"
                            [disabled]="link.suspended" [style.border-color]="!link.title ? 'red' : ''"
                            (change)="fillLink(link)" />
                        </div>

                        <div class="link-input-container" [id]="link.id" *ngIf="link.type === 'PRODUCT'">
                          <!-- <i class="bi bi-ticket-detailed link-input-icon" [id]="link.id"></i>
                          <input
                            type="text"
                            class="form-control text-link-value-input"
                            [(ngModel)]="link.description"
                            [id]="link.id"
                            [disabled]="link.suspended" /> -->

                          <st-editor style="width: 100%" [(ngModel)]="link.description" [config]="config"></st-editor>
                        </div>

                        <div class="link-input-container row-margin" [id]="link.id">
                          <i [id]="link.id" class="bi bi-link link-input-icon"
                            [style.color]="(!link.title && link.type !== 'VIDEO') || !link.link ? 'red' : ''"></i>
                          <input type="text" [id]="link.id" class="form-control container-input"
                            [disabled]="link.suspended"
                            [style.border-color]="(!link.title && link.type !== 'VIDEO') || !link.link ? 'red' : ''"
                            [(ngModel)]="link.link" (blur)="handleFillVideoLink($event, link.id)"
                            (change)="fillLink(link)" />
                        </div>

                        <div *ngIf="link.type === 'PRODUCT'" class="product-inputs">
                          <div class="link-input-container row-margin" [id]="link.id">
                            <i [id]="link.id" class="bi bi-currency-exchange"
                              [style.color]="!link.currency && link.type === 'PRODUCT' ? 'red' : ''"></i>

                            <select class="form-select currency-select" [(ngModel)]="link.currency"
                              [disabled]="link.suspended"
                              [style.border-color]="!link.currency && link.type === 'PRODUCT' ? 'red' : ''">
                              <option value="BRL">BRL</option>
                              <option value="EUR">EUR</option>
                              <option value="USD">USD</option>
                            </select>
                          </div>

                          <div class="link-input-container row-margin" [id]="link.id">
                            <i [id]="link.id" class="bi bi-currency-dollar"
                              [style.color]="!link.productValue && link.type === 'PRODUCT' ? 'red' : ''"></i>
                            <input type="text" [id]="link.id" matInput mask="separator.3" [thousandSeparator]="'.'"
                              [decimalMarker]="','" class="form-control container-input" placeholder="Ex.: R$ 8,00"
                              [disabled]="link.suspended"
                              [style.border-color]="!link.productValue && link.type === 'PRODUCT' ? 'red' : ''"
                              [(ngModel)]="link.productValue" />
                          </div>
                        </div>

                        <div *ngIf="link.type === 'PRODUCT'" class="product-image-container"
                          [style.background-image]="link.image ? 'url(' + link.image + ')' : ''"
                          (click)="handleOpenSelectLinkImageModal(link.id)">
                          <i *ngIf="!link.image" class="bi bi-image"></i>
                          <div *ngIf="link.image" class="edit-image-container">
                            <i class="bi bi-pencil edit-image-ico"></i>
                          </div>

                          <!-- <input type="file" class="file-input" (change)="handleUploadButtonLink($event, link.id)" /> -->
                        </div>
                      </div>

                      <div [id]="link.id" class="buttons-column">
                        <button [disabled]="link.suspended" type="button" [id]="link.id" class="icon-button"
                          (click)="changeButtonVisible(i)">
                          <i class="bi bi-eye icon-small" [id]="link.id" *ngIf="link.visible && !link.suspended"></i>
                          <i class="bi bi-eye-slash icon-small" [id]="link.id"
                            *ngIf="!link.visible || link.suspended"></i>
                        </button>

                        <button type="button" class="icon-button" (click)="removeLink(i)" [id]="link.id">
                          <i class="bi bi-trash icon-small" [id]="link.id"></i>
                        </button>

                        <button [disabled]="link.suspended" class="icon-button"
                          [ngClass]="selectedButtonLinkToConfigImage === link.id ? 'selected-button-link-to-config-image-button' : ''"
                          [id]="link.id" (click)="handleSelectedButtonLinkToConfigImage(link.id)"
                          *ngIf="!link.type || link.type === 'DEFAULT'">
                          <i class="bi bi-image icon-small" [id]="link.id"
                            [ngClass]="selectedButtonLinkToConfigImage === link.id ? 'selected-button-link-to-config-image-icon' : ''"></i>
                        </button>
                      </div>
                    </div>

                    <div class="link-image-config" [id]="link.id" *ngIf="!link.type || link.type === 'DEFAULT'"
                      [@slideDownAnimation]="selectedButtonLinkToConfigImage === link.id ? 'visible' : 'hidden'">
                      <div class="title-container" [id]="link.id">
                        <span class="config-image-title">Configurar imagem do link

                          <i (click)="closeSelectionButtonImage()" class="bi bi-chevron-up"></i></span>

                        <div class="external-image-toggle-container">
                          <label class="external-image-label" for="external-image-input">Imagem externa</label>

                          <div class="form-check form-switch">
                            <input id="external-image-input" type="checkbox" role="switch" class="form-check-input"
                              name="active" [checked]="externalButtonLink" (change)="toggleExternalButtonLink()" />
                          </div>
                        </div>
                      </div>

                      <div class="image-selection-container" [id]="link.id">
                        <div class="image-url-input-container" *ngIf="externalButtonLink">
                          <div class="input-group inputs">
                            <div class="input-group-prepend">
                              <span class="input-group-text input-prepend" id="basic-addon5">
                                <i class="bi bi-link"></i>
                              </span>
                            </div>

                            <input type="text" placeholder="Url da imagem" class="form-control input-text"
                              id="button-image-link" (change)="changeExternalUrl($event, link.id)" />
                          </div>
                        </div>

                        <button class="image-input-container" *ngIf="!externalButtonLink"
                          (click)="handleUploadButtonLink($event, link.id)">
                          <i class="bi bi-upload"></i>

                          <span>Anexar Arquivo</span>

                          <!-- <input type="file" class="file-input" (change)="handleUploadButtonLink($event, link.id)" /> -->
                        </button>
                      </div>

                      <div class="button-layout-container" [id]="link.id">
                        <span class="select-button-layout-title">Selecione o tipo da aparencia do botāo</span>

                        <button class="layout-container" (click)="
                            handleSelectButtonLayout({
                              buttonId: link.id,
                              selectedLayout: 'CLASSIC'
                            })
                          " [ngClass]="link.layout === 'CLASSIC' ? 'selected-layout-container' : ''">
                          <div class="layout-selector-container">
                            <button class="button-layout-selector"
                              [ngClass]="link.layout === 'CLASSIC' ? 'layout-selected' : ''">
                              <div class="selected-indicator"></div>
                            </button>
                          </div>

                          <div class="button-info-container">
                            <span class="layout-title"> Clássico </span>
                            <span class="layout-description"> Eficiente, direto e compacto </span>
                          </div>

                          <div class="classic-button-layout-preview" [style.background-color]="userCss.preenchimento">
                            <img *ngIf="link.image" [src]="link.image" alt="preview image"
                              class="classic-preview-image" />

                            <div class="link-content-text">
                              <span class="classic-preview-text">{{ link.title }}</span>
                            </div>

                            <div class="higlighted-preview-options">
                              <div class="classic-preview-options">
                                <i class="bi bi-three-dots"></i>
                              </div>
                            </div>
                          </div>
                        </button>

                        <button class="layout-container" (click)="
                            handleSelectButtonLayout({
                              buttonId: link.id,
                              selectedLayout: 'HIGHLIGHTED'
                            })
                          " [ngClass]="link.layout === 'HIGHLIGHTED' ? 'selected-layout-container' : ''">
                          <div class="layout-selector-container">
                            <button class="button-layout-selector"
                              [ngClass]="link.layout === 'HIGHLIGHTED' ? 'layout-selected' : ''">
                              <div class="selected-indicator"></div>
                            </button>
                          </div>

                          <div class="link-button-container">
                            <div class="button-info-container">
                              <span class="layout-title">Destacado</span>
                              <span class="layout-description">Deixe o seu link com um visual mais atrativo e
                                destacado</span>
                            </div>

                            <div class="highlighted-button-container" [style.border-radius]="userCss.curvaBorda + 'px'">
                              <div class="higlighted-button-layout-preview"
                                [style.border-radius]="userCss.curvaBorda + 'px ' + userCss.curvaBorda + 'px 0px 0px'"
                                [style.background-image]="link.image ? 'url(' + link.image + ')' : ''"></div>

                              <div class="link-content-container"
                                [style.border-radius]="'0px 0px ' + userCss.curvaBorda + 'px ' + userCss.curvaBorda + 'px'"
                                [style.background-color]="userCss.preenchimento">
                                <span class="higlighted-preview-text">{{ link.title }}</span>
                                <div class="higlighted-preview-options">
                                  <i class="bi bi-three-dots"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="add-div">
                  <button type="button" class="action-button" (click)="addLink()" [disabled]="
                      userProfilePlan.profilePlanLimits.linksInclude <= selectedProfile.data.listButtons.length &&
                      userProfilePlan.profilePlanLimits.linksInclude !== -1
                    ">
                    <i class="bi bi-plus-circle"> </i>
                  </button>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="visual-div">
      <app-profile-preview *ngIf="screenWidth > desktopLimitWidth && screenHeight > desktopLimitHeight"
        [showImageProfile]="selectedProfile.data.showImageProfile" [userCss]="userCss"
        [documentUser]="userProfile?.documentUser" [profileImageUrl]="selectedProfile.data.uriImageProfile"
        [backgroundImageUrl]="selectedProfile.data.uriImageBackground" [bio]="selectedProfile.data.bio"
        [name]="selectedProfile.data.name" [header]="selectedProfile.data.header"
        [redesSociais]="selectedProfile.data.listSocialNetwork" [linkButtons]="selectedProfile.data.listButtons"
        class="profile-preview" style="flex: 0"></app-profile-preview>

      <!-- <div class="save-button-container" *ngIf="screenWidth > desktopLimitWidth && screenHeight > desktopLimitHeight">
        <button type="button" id="salvar" name="salvar" class="botao-salvar" (click)="save()" translate>
          <i class="bi bi-save"></i>botaoSalvar
        </button>
      </div> -->
    </div>
  </section>
</div>