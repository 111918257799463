import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  override parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  override format(date: NgbDateStruct | null): string {
    return date
      ? this.getDayMonth(date.day) +
          this.DELIMITER +
          this.getDayMonth(date.month) +
          this.DELIMITER +
          date.year
      : '';
  }

  getDayMonth(month: number) {
    if (month.toString().length < 2) {
      return '0' + month;
    } else {
      return month;
    }
  }
}
