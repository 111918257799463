import { Component, EventEmitter, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { LISTA_REDES, RedeSocial } from 'src/app/new-sluper/core/model/redes';
import { SocialNetwork } from 'src/app/new-sluper/core/model/social-network.model';
import { ConfiguracaoCadastroStorageService } from '../configuracao-cadastro-storage.service';

@Component({
  selector: 'app-cadastro-informacoes-perfil-tres',
  templateUrl: './cadastro-informacoes-perfil-tres.component.html',
  styleUrls: ['./cadastro-informacoes-perfil-tres.component.css']
})
export class CadastroInformacoesPerfilTresComponent {
  @Output() nextScreen: EventEmitter<SocialNetwork[]> = new EventEmitter<SocialNetwork[]>();
  @Output() previousScreen: EventEmitter<boolean> = new EventEmitter<boolean>();

  linksForm: FormGroup;
  redesVisiveis: RedeSocial[] = [];
  itemsPerPage = 15;
  currentIndex = 0;

  constructor(
    private fb: FormBuilder,
    private cadastroStorage: ConfiguracaoCadastroStorageService
  ) {
    // Define o número máximo de itens a serem exibidos
    const maxItems = 3;

    // Cria o FormArray com base nos primeiros itens da LISTA_REDES
    this.linksForm = this.fb.group({
      links: this.fb.array(
        LISTA_REDES.slice(0, maxItems).map(rede =>
          this.createLinkFormGroup({
            type: rede.value,
            link: '',
            placeholder: rede.placeHolder,
            readOnly: false
          })
        )
      )
    });

    this.loadMoreItems(); // Carrega os primeiros itens, se necessário
  }

  ngOnInit(): void {
    if (this.cadastroStorage.getSocialNetworkData()) {
      this.linksForm.patchValue(this.cadastroStorage.getSocialNetworkData());
    }
  }

  get links(): FormArray {
    return this.linksForm.get('links') as FormArray;
  }

  createLinkFormGroup(socialNetwork: SocialNetwork): FormGroup {
    return this.fb.group({
      type: [socialNetwork.type],
      link: [socialNetwork.link],
      placeholder: [socialNetwork.placeholder],
      readOnly: [socialNetwork.readOnly]
    });
  }

  addLink(): void {
    this.links.push(this.createLinkFormGroup({ type: 'url', link: '', placeholder: 'www.seusite.com.br', readOnly: false }));
  }

  selectLabel(index: number, rede: RedeSocial, event: Event): void {
    event.preventDefault();
    this.links.at(index).patchValue({
      type: rede.value,
      link: '',
      placeholder: rede.placeHolder
    });
  }

  voltarPagina() {
    this.previousScreen.emit();
  }

  proximaPagina() {
    if (this.linksForm.valid) {
      const formData = this.linksForm.value.links
        .filter((linkData: any) => linkData.link.trim() !== '')
        .map((linkData: any) => {
          const redeSocial = LISTA_REDES.find(rede => rede.value === linkData.type);
          const baseUrl = redeSocial?.redeSocialLink || '';

          return {
            ...linkData,
            link: baseUrl + linkData.link
          };
        });

      this.cadastroStorage.setSocialNetworkData(formData);
      this.nextScreen.emit();
    }
  }

  onScroll(event: any): void {
    const element = event.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      this.loadMoreItems();
    }
  }

  loadMoreItems(): void {
    const nextItems = LISTA_REDES.slice(this.currentIndex, this.currentIndex + this.itemsPerPage);
    this.redesVisiveis = this.redesVisiveis.concat(nextItems);
    this.currentIndex += this.itemsPerPage;
  }

  handleKeyDownSocialNetwork(event: any, index: number) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value.trim();

    // Identifica a rede social com base no link inserido
    const redeSocial = this.identifySocialNetwork(value);
    if (this.isPossibleURL(value) == true) {
      if (redeSocial) {
        // Se a rede social foi identificada, atualiza o formulário com o tipo da rede social e o identificador extraído
        const extractedValue = this.extractIdentifier(value, redeSocial.redeSocialLink);
        this.links.at(index).patchValue({
          type: redeSocial.value,
          link: extractedValue,
          placeholder: redeSocial.placeHolder
        });
      }
    }
  }

  identifySocialNetwork(url: string): RedeSocial | undefined {
    // Encontra a rede social cujo link base corresponde ao início da URL fornecida
    return LISTA_REDES.find(rede => url.startsWith(rede.redeSocialLink));
  }

  extractIdentifier(url: string, baseUrl: string): string {
    // Remove o link base da URL para obter o identificador
    if (url.startsWith(baseUrl)) {
      return url.replace(baseUrl, '');
    }
    return url; // Retorna o valor original se o link não começar com o baseUrl
  }

  isPossibleURL(value: string): boolean {
    // Verifica se o valor parece ser uma URL
    const urlPattern = /^(https?:\/\/[^\s$.?#].[^\s]*)$/i;
    return urlPattern.test(value);
  }
}
