<div class="code-inputs">
    <input type="text" maxlength="1" class="code-input" (input)="onInput($event, codigo2)"
        (keydown)="onKeydown($event, null)" (paste)="onPaste($event, codigo2)" #codigo1
        aria-label="Código de Verificação 1" />
    <input type="text" maxlength="1" class="code-input" (input)="onInput($event, codigo3)"
        (keydown)="onKeydown($event, codigo1)" (paste)="onPaste($event, codigo3)" #codigo2
        aria-label="Código de Verificação 2" />
    <input type="text" maxlength="1" class="code-input" (input)="onInput($event, codigo4)"
        (keydown)="onKeydown($event, codigo2)" (paste)="onPaste($event, codigo4)" #codigo3
        aria-label="Código de Verificação 3" />
    <input type="text" maxlength="1" class="code-input" (input)="onInput($event, codigo5)"
        (keydown)="onKeydown($event, codigo3)" (paste)="onPaste($event, codigo5)" #codigo4
        aria-label="Código de Verificação 4" />
    <input type="text" maxlength="1" class="code-input" (input)="onInput($event, codigo6)"
        (keydown)="onKeydown($event, codigo4)" (paste)="onPaste($event, codigo6)" #codigo5
        aria-label="Código de Verificação 5" />
    <input type="text" maxlength="1" class="code-input" (input)="onInput($event, codigo7)"
        (keydown)="onKeydown($event, codigo5)" (paste)="onPaste($event, codigo7)" #codigo6
        aria-label="Código de Verificação 6" />
    <input type="text" maxlength="1" class="code-input" (input)="onInput($event, codigo8)"
        (keydown)="onKeydown($event, codigo6)" (paste)="onPaste($event, codigo8)" #codigo7
        aria-label="Código de Verificação 7" />
    <input type="text" maxlength="1" class="code-input" (input)="onInput($event, null)"
        (keydown)="onKeydown($event, codigo7)" (paste)="onPaste($event, null)" #codigo8
        aria-label="Código de Verificação 8" />
</div>