<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<div class="modal-body content">
  <div class="new-owner-input-container">
    <label for="owner" class="labels-text">Email Administrador da Licença:</label>
    <div class="input-group inputs">
      <div class="input-group-prepend">
        <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-envelope"></i></span>
      </div>

      <input type="text" class="form-control input-text" id="owner" name="owner" [(ngModel)]="newOwnerEmail">
    </div>
  </div>
</div>

<div class="footer">
  <div class="buttons-container">
    <button type="button" class="cancel-button" (click)="onCloseClick()">Cancelar</button>
    <button type="button" class="save-button" (click)="handleAssignLicenseOwner()">Salvar</button>
  </div>
</div>