<div class="modal-container">
  <header class="modal-header">
    <h4 class="modal-title" *ngIf="!isUpdate" translate>communitys.createCommunity.title</h4>
    <h4 class="modal-title" *ngIf="isUpdate" translate>communitys.updateCommunity.title</h4>
    <button type="button" class="close" aria-label="Fechar" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </header>

  <section class="modal-body">
    <section class="event-fields-section">
      <div class="file-input-container">
        <label for="image-upload" class="input-label"> Imagem do comunidade </label>
        <image-cropper *ngIf="cropMode" [imageChangedEvent]="changedImageEvent" [maintainAspectRatio]="true"
          [aspectRatio]="16 / 9" format="jpeg" (imageCropped)="handleImageCropped($event)" style="max-height: 400px"
          [cropperMaxWidth]="1200" [cropperMinWidth]="1200" [cropperMaxHeight]="720"
          [cropperMinHeight]="720"></image-cropper>

        <label class="image-upload-label" for="image-upload" *ngIf="!cropMode"
          [style]="!formErrors.image.valid && formErrors.image.message ? 'border-color: red' : ''">
          <input id="image-upload" class="image-upload" accept="image/jpeg, image/png, image/jpg" type="file"
            (change)="handleFileChange($event)" />

          <i class="bi bi-card-image"></i>

          <span>Clique aqui para selecionar e enviar a sua foto</span>
          <span *ngIf="isUpdate">*ou deixe em branco caso não queira alterar</span>
        </label>
        <div style="display: flex; flex-direction: row; justify-content: center; margin-top: 1%;">
          <button class="close" (click)="removeSelectedImage()" *ngIf="cropMode"><i
              class="bi bi-x-circle-fill"></i></button>
        </div>

        <span class="error-message" *ngIf="!formErrors.image.valid && formErrors.image.message">
          {{ formErrors.image.message }}
        </span>
      </div>

      <div class="event-fields-container">
        <div class="input-container">
          <label for="event-name" class="input-label"> Nome do comunidade </label>

          <div class="input-group event-input-container">
            <div [style]="!formErrors.name.valid && formErrors.name.message ? 'border-color: red' : ''"
              class="input-group-prepend event-input-prepend">
              <i class="bi bi-type-h1"></i>
            </div>

            <input [style]="!formErrors.name.valid && formErrors.name.message ? 'border-color: red' : ''"
              [(ngModel)]="modalData.name" id="event-name" placeholder="Nome do comunidade" type="text"
              class="form-control event-input" />
          </div>

          <span class="error-message" *ngIf="!formErrors.name.valid && formErrors.name.message">
            {{ formErrors.name.message }}
          </span>
        </div>

        <div class="input-container">
          <label for="event-site" class="input-label"> Site da comunidade </label>

          <div class="input-group event-input-container">
            <div class="input-group-prepend event-input-prepend">
              <i class="bi bi-link"></i>
            </div>

            <input [(ngModel)]="modalData.site" id="event-site" placeholder="Site da comunidade" type="text"
              class="form-control event-input" />
          </div>
        </div>

        <div class="toggles">
          <div class="external-image-toggle-container">
            <label class="input-label" for="public-event">Comunidade Pública</label>

            <div class="form-check form-switch">
              <input [(ngModel)]="modalData.public" id="public-event" type="checkbox" role="switch"
                class="form-check-input" name="active" />
            </div>
          </div>
          <div class="external-image-toggle-container">
            <label class="input-label" for="public-event">Requer Aprovação</label>

            <div class="form-check form-switch">
              <input [(ngModel)]="modalData.requiresConfirmation" id="public-event" type="checkbox" role="switch"
                class="form-check-input" name="active" />
            </div>
          </div>
        </div>
      </div>

      <div class="description-textarea-container">
        <label for="event-description" class="input-label"> Sobre a comunidade </label>

        <!-- <div class="input-group event-input-container">
          <div class="input-group-prepend event-input-prepend"
            [style]="!formErrors.description.valid && formErrors.description.message ? 'border-color: red' : ''">
            <i class="bi bi-blockquote-left"></i>
          </div>

          <textarea [(ngModel)]="modalData.description" id="event-description"
            [style]="!formErrors.description.valid && formErrors.description.message ? 'border-color: red' : ''"
            placeholder="Descriçāo do comunidade" type="text" class="form-control event-input"></textarea>
        </div> -->
        <st-editor style="width: 100%" [(ngModel)]="modalData.about" [config]="config"></st-editor>

        <span class="error-message" *ngIf="!formErrors.about.valid && formErrors.about.message">
          {{ formErrors.about.message }}
        </span>
      </div>

      <div class="description-textarea-container">
        <label for="event-description" class="input-label"> Descriçāo da comunidade </label>

        <!-- <div class="input-group event-input-container">
          <div class="input-group-prepend event-input-prepend"
            [style]="!formErrors.description.valid && formErrors.description.message ? 'border-color: red' : ''">
            <i class="bi bi-blockquote-left"></i>
          </div>

          <textarea [(ngModel)]="modalData.description" id="event-description"
            [style]="!formErrors.description.valid && formErrors.description.message ? 'border-color: red' : ''"
            placeholder="Descriçāo do comunidade" type="text" class="form-control event-input"></textarea>
        </div> -->
        <st-editor style="width: 100%" [(ngModel)]="modalData.description" [config]="config"></st-editor>

        <span class="error-message" *ngIf="!formErrors.description.valid && formErrors.description.message">
          {{ formErrors.description.message }}
        </span>
      </div>

      <div style="display: flex; width: 100%"></div>
    </section>

    <section class="guests-section">
      <div class="add-guest-container">
        <div class="input-container">
          <label for="guest-email" class="input-label"> Convide pessoas a sua comunidade </label>

          <div class="guest-input-container">
            <div class="input-group event-input-container">
              <div class="input-group-prepend event-input-prepend"
                [style]="!guestsFormErrors.guestEmail.valid && guestsFormErrors.guestEmail.message ? 'border-color: red; color: red;' : ''">
                <i class="bi bi-envelope"></i>
              </div>

              <input [(ngModel)]="guestEmailToInvite" id="guest-email" placeholder="E-mail do convidado " type="text"
                class="form-control event-input"
                [style]="!guestsFormErrors.guestEmail.valid && guestsFormErrors.guestEmail.message ? 'border-color: red' : ''" />
            </div>

            <button class="add-guest-button" (click)="handleAddGuest()">
              <i class="bi bi-plus"></i>
            </button>
          </div>

          <span class="error-message" *ngIf="!guestsFormErrors.guestEmail.valid && guestsFormErrors.guestEmail.message">
            {{ guestsFormErrors.guestEmail.message }}
          </span>
        </div>
      </div>

      <div class="guests-list">
        <div class="guest-box" *ngFor="let guest of modalData.guests; let i = index">
          {{ guest }}

          <button class="remove-guests-button" (click)="removeGuest(i)"><i class="bi bi-x"></i></button>
        </div>
      </div>
    </section>
  </section>

  <footer class="modal-footer">
    <button type="button" class="btn btn-secondary" translate
      (click)="closeModal()">communitys.createCommunity.cancel</button>

    <button type="button" class="btn btn-primary save-event-button" (click)="handleSaveEvent()" translate>
      <div class="button-load-spinner" *ngIf="loading"></div>
      communitys.createCommunity.save
    </button>
  </footer>
</div>
