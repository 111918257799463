import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { darken, lighten, readableColor } from 'polished';
import { IDisplayedLink } from '../model/button.model';
import { IDocumentUser } from '../model/document-user.model';
import { IHeader } from '../model/header.model';
import { IDisplayedSocialNetwork } from '../model/social-network.model';
import { IUserCss } from '../model/user-css.model';

@Component({
  selector: 'app-profile-preview',
  templateUrl: './profile-preview.component.html',
  styleUrls: ['./profile-preview.component.css'],
})
export class ProfilePreviewComponent {
  @Input() showImageProfile = true;
  @Input() mobilePreview = false;
  @Input() profileImageUrl = '';
  @Input() userCss: IUserCss = {};
  @Input() backgroundImageUrl = '';
  @Input() bio = '';
  @Input() name = '';
  @Input() header: IHeader = {
    text: '',
    visible: false,
  };
  @Input() redesSociais: IDisplayedSocialNetwork[] = [];
  @Input() linkButtons: IDisplayedLink[] = [];
  @Input() documentUser?: IDocumentUser = undefined;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  transformUrl(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getUserSaleLink() {
    const formattedId = this.documentUser?.idUser?.replaceAll('-', '_') || '';

    return `https://sluper.digital/?partner=${formattedId}-${this.documentUser?.promoCode}`;
  }

  containsSocialNetwork(socialNetwork: string): boolean | undefined {
    if (this.redesSociais) {
      const isPresent = this.redesSociais.find((e) => e.type == socialNetwork && !e.suspended);
      if (isPresent) {
        return true;
      } else {
        return false;
      }
    }
    return undefined;
  }

  getColorContrast(c = '#fff', factor = 0.1): string {
      if (!factor) {
        factor = 0.1;
      }

      const readable = readableColor(c);

      if (readable === '#000') {
        return darken(factor, c);
      }

      return lighten(factor, c);
  }

  getBackgroundPreview(): string | undefined {
    if (this.userCss) {
      if (this.userCss.corSecundaria) {
        return (
          'linear-gradient(' +
          this.userCss.direcaoDegrade +
          'deg, ' +
          this.userCss.corPrimaria +
          ' 0%, ' +
          this.userCss.corSecundaria +
          ' 100%)'
        );
      } else {
        return this.userCss.corPrimaria;
      }
    } else {
      return undefined;
    }
  }

  getBackgroundImageUrl(): string {
    return 'url(' + this.backgroundImageUrl + ')';
  }
}
