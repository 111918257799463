<app-sluper-background></app-sluper-background>
<app-header-inicial [layoutColorido]="false"></app-header-inicial>
<div class="container">
    <div class="infos-box">
        <span class="info-titulo text-normal-32-700">
            Faça parte da maior rede de comunidades da América Latina !
        </span>
        <span class="text-normal-24-500 text-white">
            Com a Sluper você poderá:
        </span>
        <ul class="lista-info text-normal-24-500 text-white">
            <li>Criar eventos e comunidades.</li>
            <li>Participar de uma rede de networks.</li>
            <li>Monitorar o desempenho do seu evento e promover uma experiência única para os seus participantes.</li>
        </ul>
    </div>
    <div class="image-box">
        <img class="anuncio-celular" src="../../../../assets/images/mulher-celular.png" alt="Anuncio Sluper" />
    </div>
    <div class="shadow-box" style="height: fit-content;">
        <div class="cadastro-label">
            <span class="text-normal-32-700 text-white" translate>Cadastre-se</span>
            <span class="text-normal-16-600 text-white text-center" translate>Iremos enviar um código para o seu
                email</span>
        </div>

        <div class="form-div">
            <label for="user" class="label-text-inicio" translate>Nome</label>
            <input type="email" name="user" id="user" class="form-input" [formControl]="username" required />
        </div>
        <div class="form-div">
            <label for="user" class="label-text-inicio" translate>Email</label>
            <input type="email" name="user" id="user" class="form-input" [formControl]="email" required />
        </div>
        <button type="button" class="btn button-form" (click)="cadastrar()">
            <span class="text-normal-16-700 text-white" translate>
                Cadastrar
            </span>
        </button>
    </div>
</div>