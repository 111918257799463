import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import {
  ISalesDashboardData,
  UserProfileService
} from '../user-profile/user-profile.service';

interface IFormattedSaleDashboardData extends ISalesDashboardData {
  formattedTotalSalesCommission: string;
  formattedSalesTotalValue: string;
  revenuesByDay: {
    salesCount: number;
    salesTotalValue: number;
    formattedTotalValue: string;
    formattedComissionValue: string;
    valueCommission: number;
    day: string;
  }[];
}
@Component({
  selector: 'app-sales-dashboard',
  templateUrl: './sales-dashboard.component.html',
  styleUrls: ['./sales-dashboard.component.css'],
})
export class SalesDashboardComponent implements OnInit {
  @Input() userProfile!: IUserProfileWithCSS | null;
  @Output() linkRequired = new EventEmitter<boolean>();

  dashboardData: IFormattedSaleDashboardData | null = null;

  monthFilter = `${new Date().getFullYear()}-${new Date().getMonth() + 1}`;

  screenWidth: any;
  screenHeight: any;

  constructor(
    private userProfileService: UserProfileService,
    private modalService: NgbModal,
  ) {

    this.getScreenSize();

    this.fetchSalesDashboardData()

    this.monthFilter = `${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
      ? `0${new Date().getMonth() + 1}`
      : `${ new Date().getMonth() + 1 }`}` ;
  }

  fetchSalesDashboardData(event?: any) {
    if (event) {
      this.monthFilter = event.target.value;
    }

    const brl = Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    this.userProfileService.getSalesDashboardData(this.monthFilter).subscribe(({ body }) => {
      if (body) {
        this.dashboardData = {
          ...body,
          formattedTotalSalesCommission: brl.format(body.totalSalesCommission),
          formattedSalesTotalValue: brl.format(body.salesTotalValue),
          revenuesByDay: body.revenuesByDay.map((revenue) => ({
            ...revenue,
            formattedTotalValue: brl.format(revenue.salesTotalValue),
            formattedComissionValue: brl.format(revenue.valueCommission),
          })),
        };
      }
    });
  }

  ngOnInit(): void {
    return;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }
}
